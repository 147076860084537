import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import _ from "lodash";
import NumberFormat from "react-number-format";

import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

interface TextFieldWholeNumberProps extends FieldComponentProps {
  size?: any;
  decimalScale?: any;
  onBlur?: () => void;
  autoFocus?: boolean;
}

export function GetNumberFormat({
  size,
  fieldName,
  decimalScale,
  disabled,
  formatted = true,
  onBlur,
  autoFocus,
}: {
  size: any;
  fieldName: string;
  decimalScale: any;
  disabled: boolean | undefined;
  formatted?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
}) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const handleBlur = () => {
    setFieldTouched(fieldName, true);
    if (typeof onBlur === "function") onBlur();
  };
  return (
    <NumberFormat
      size={size}
      fullWidth
      id={fieldName}
      thousandSeparator={formatted}
      decimalScale={decimalScale}
      placeholder="0"
      disabled={disabled}
      value={_.get(values, fieldName)}
      onValueChange={(val) =>
        setFieldValue(
          fieldName,
          _.isUndefined(val.floatValue) ? null : val.floatValue
        )
      }
      InputProps={{ onBlur: handleBlur, autoFocus }}
      customInput={TextField}
    />
  );
}

export function TextFieldWholeNumber(props: TextFieldWholeNumberProps) {
  const decimalScale =
    props.decimalScale === undefined ? 0 : props.decimalScale;
  return (
    <CreoneField {...props}>
      <GetNumberFormat
        size={"medium"}
        fieldName={props.fieldName}
        decimalScale={decimalScale}
        disabled={props.disabled}
        onBlur={props.onBlur}
        autoFocus={props.autoFocus}
      />
    </CreoneField>
  );
}

export function TextFieldTwoDecimal(props: TextFieldWholeNumberProps) {
  return <TextFieldWholeNumber {...props} decimalScale={2} />;
}

export function TextFieldTenDecimal(props: TextFieldWholeNumberProps) {
  return <TextFieldWholeNumber {...props} decimalScale={10} />;
}

export function TextFieldWholeNumberUnformatted(
  props: TextFieldWholeNumberProps
) {
  return (
    <CreoneField {...props}>
      <GetNumberFormat
        size={"medium"}
        fieldName={props.fieldName}
        decimalScale={0}
        disabled={props.disabled}
        formatted={false}
        onBlur={props.onBlur}
      />
    </CreoneField>
  );
}
