import { pick, compact } from "lodash";

type RecordWithAddress = Record<string, any>;

export const formatAddressForDisplay = (record: RecordWithAddress): string => {
  const pickedData = pick(record, [
    "address_line_1",
    "address_line_2",
    "address_city",
    "address_state",
    "address_postal_code",
  ]);

  // Destructure for readability
  const {
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_postal_code,
  } = pickedData;

  // Format city/state/zip into one string if they exist
  const cityStateZip = compact([
    address_city?.trim(),
    address_state?.trim(),
    address_postal_code?.trim(),
  ]).join(" ");

  // Build the final address parts array
  const addressParts = compact([
    address_line_1?.trim(),
    address_line_2?.trim(),
    cityStateZip || null, // Don't include empty string
  ]);

  return addressParts.join(", ");
};
