import { Grid } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";

import { TextFieldString } from "components/form/TextFieldString";
import LookupAddress from "components/form_v2/LookupAddress";
import { SelectUSState } from "components/form_v2/SelectUSState";
import { AddressSearchResult } from "types/form/dealForm";

export const FormikAddressSearchField = () => {
  const { setValues } = useFormikContext();

  const onAddressSearchChange = (address: AddressSearchResult) => {
    setValues((values: FormikValues) => ({
      ...values,
      address_line_1: address.line_1,
      address_line_2: address.line_2,
      address_city: address.city,
      address_state: address.state,
      address_postal_code: address.postal_code,
      address_country: address.country,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LookupAddress
          onValueChange={onAddressSearchChange}
          fieldName={"_address_search"}
          displayName={"Address Search"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldString
          fieldName={"address_line_1"}
          displayName={"Street address"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldString
          fieldName={"address_line_2"}
          displayName={"Apt / suite"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldString fieldName={"address_city"} displayName={"City"} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectUSState fieldName={"address_state"} displayName={"State"} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFieldString
          fieldName={"address_postal_code"}
          displayName={"Zip"}
        />
      </Grid>
    </Grid>
  );
};
