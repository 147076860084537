import { useFormikContext } from "formik";

import { SwitchField } from "pages/deal/components/SwitchField";
import { FieldComponentProps } from "types/standardForm";

export const OutsideBrokerField = (props: FieldComponentProps) => {
  const { setFieldValue } = useFormikContext();

  const additionalChangeHandler = async (value: boolean) => {
    if (!value) {
      if (!value) {
        await setFieldValue("counterparty_broker_company", null);
        await setFieldValue("outside_broker", null);
      }
    }
  };
  return (
    <SwitchField {...props} additionalChangeHandler={additionalChangeHandler} />
  );
};
