import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useFormikContext } from "formik";
import * as React from "react";

import { SimpleOption } from "types/api/deal/form";
import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

export function RadioButtonField(props: FieldComponentProps) {
  const { getFieldProps } = useFormikContext();

  if (!props.options) return null;

  return (
    <CreoneField {...props}>
      <FormControl>
        <RadioGroup {...getFieldProps(props.fieldName)}>
          {props.options.map((x: SimpleOption) => (
            <FormControlLabel
              value={x.key}
              control={<Radio />}
              label={x.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </CreoneField>
  );
}
