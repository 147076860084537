// MUI
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Chip, Menu, MenuItem, Stack } from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import { PROSPECT_STATUS_OPTIONS } from "pages/deal/constants/prospect";
import { startEditingRecord } from "store/reducers/record";
import { ProspectStatusEnum } from "types/api/deal/enum";
import { SimpleOptionWithColor } from "types/api/deal/form";
import { FormIdentifier } from "types/record";

type MenuChipProps = {
  value: string;
  options: SimpleOptionWithColor[];
  handleOptionClick: (option: string) => void;
};

export function MenuChip({ value, options, handleOptionClick }: MenuChipProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChipClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const optionsDict = useMemo(() => _.keyBy(options, "key"), [options]);

  return (
    <div>
      <Stack
        justifyContent={"space-between"}
        direction={"row"}
        onClick={handleChipClick}
      >
        <Chip
          id={value}
          color={_.get(optionsDict, [value, "color"], "secondary")}
          size={"small"}
          variant={"light"}
          label={
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {_.get(optionsDict, [value, "label"], "Unknown")}
              {Boolean(anchorEl) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Stack>
          }
          sx={{ cursor: "pointer" }}
        />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {_.map(
          _.filter(options, (x) => x.key !== value),
          (option) => (
            <MenuItem
              key={option.key}
              onClick={() => {
                setAnchorEl(null);
                handleOptionClick(`${option.key}`);
              }}
            >
              <Chip
                id={`${option.key}`}
                color={option.color}
                label={option.label}
                size={"small"}
                variant={"light"}
                sx={{ cursor: "pointer" }}
              />
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
}

export const ProspectStatusChip = ({
  value,
  recordId,
}: {
  recordId: number;
  value: ProspectStatusEnum;
}) => {
  const handleOptionClick = (option: string) => {
    // Update status
    const incomingChanges =
      option === ProspectStatusEnum.converted
        ? { status: ProspectStatusEnum.converted, conversion_date: new Date() }
        : { status: option };

    // Open edit dialog
    startEditingRecord(recordId, FormIdentifier.ContactForm, incomingChanges);
  };

  return (
    <MenuChip
      value={value}
      options={PROSPECT_STATUS_OPTIONS}
      handleOptionClick={handleOptionClick}
    />
  );
};
