import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { DatePicker } from "components/form/DatePicker";
import { FormDialogActions } from "components/form/standard/FormDialogActions";
import {
  deriveYupSchemaFromMetadataV3,
  getInitialValuesV3,
  getOnSubmitV3,
} from "components/form/standard/utils/formMetadata";
import { TextFieldString } from "components/form/TextFieldString";
import { LedgerTransactionMetadata } from "constants/objectMetadata/ledgerTransactionMetadata";
import { usePopForm, usePushForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { CloseButton } from "pages/deal/components/CloseButton";
import { useFormFieldDictQuery } from "queries/useFormField";
import { useFormLayoutQuery } from "queries/useFormLayout";
import {
  getLedgerTransactionQueryKey,
  getLedgerTransactionsQueryKey,
  useLedgerTransactionQuery,
} from "queries/useLedgerTransaction";
import { RecordActionResponse } from "types/api/deal/api";
import {
  CustomFormEntityEnum,
  FormFieldRead,
} from "types/api/deal/dynamic_form/form_field";
import { FormLayoutRead } from "types/api/deal/dynamic_form/form_layout";
import {
  LedgerTransactionCreate,
  LedgerTransactionRead,
} from "types/api/deal/ledger_transaction";
import {
  createLedgerTransactionAsync,
  updateLedgerTransactionAsync,
} from "utils/ledger_transaction";

interface LedgerTransactionFormikValues {
  id?: string;
  name: string;
}

interface LedgerTransactionFormProps {
  record?: LedgerTransactionRead;
  changes?: Partial<LedgerTransactionFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  formFields: Record<string, FormFieldRead>;
}

interface LedgerTransactionFormProps {
  record?: LedgerTransactionRead;
  changes?: Partial<LedgerTransactionFormikValues>;
  submitData: (data: any) => Promise<void>;
}

function LedgerTransactionForm({
  record,
  changes = {},
  submitData,
}: LedgerTransactionFormProps) {
  const queryClient = useQueryClient();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const popForm = usePopForm();

  const initialValues = useMemo(
    () =>
      getInitialValuesV3<
        LedgerTransactionRead,
        Partial<LedgerTransactionFormikValues>
      >(LedgerTransactionMetadata, record, changes, usersDisplay, user),
    [changes, record, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV3(LedgerTransactionMetadata),
    []
  );

  const onSubmit = useMemo(
    () => getOnSubmitV3(LedgerTransactionMetadata, submitData),
    [submitData]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ handleSubmit }) => (
          <YupRequiredFieldsProvider validationSchema={validationSchema}>
            <Form>
              <Dialog open={true} scroll={"paper"} maxWidth={"md"}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "20px",
                  }}
                >
                  <DialogTitle>
                    {record ? "Edit" : "New"} LedgerTransaction{" "}
                  </DialogTitle>
                  <CloseButton onClose={popForm} />
                </div>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DatePicker
                        fieldName={"effective_date"}
                        displayName={"Effective Date"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldString
                        fieldName={"reference_id"}
                        displayName={"Reference ID"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldString
                        fieldName={"description"}
                        displayName={"Description"}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <FormDialogActions canDelete={!!record?.id} />
              </Dialog>
            </Form>
          </YupRequiredFieldsProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
}

interface LedgerTransactionEditFormProps {
  id: string;
  changes?: Partial<LedgerTransactionFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const LedgerTransactionEditForm = ({
  id,
  changes,
  handleClose,
  successCallback,
}: LedgerTransactionEditFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: LedgerTransactionCreate) => {
      const responseObj = await updateLedgerTransactionAsync(
        id,
        record,
        pushForm
      );
      await queryClient.invalidateQueries(getLedgerTransactionQueryKey(id));
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, id, pushForm, queryClient, successCallback]
  );

  const { data } = useLedgerTransactionQuery(id);

  return !!formLayout && !!formFields && !!data ? (
    <LedgerTransactionForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
      record={data}
    />
  ) : null;
};

interface LedgerTransactionCreateFormProps {
  changes?: Partial<LedgerTransactionFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const LedgerTransactionCreateForm = ({
  changes,
  handleClose,
  successCallback,
}: LedgerTransactionCreateFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: LedgerTransactionCreate) => {
      const responseObj = await createLedgerTransactionAsync(record, pushForm);
      await queryClient.invalidateQueries(getLedgerTransactionsQueryKey());
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, pushForm, queryClient, successCallback]
  );

  return !!formLayout && !!formFields ? (
    <LedgerTransactionForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
    />
  ) : null;
};

const LedgerTransactionFormPage = ({
  id,
  handleClose,
  successCallback,
  changes,
}: {
  id?: string;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
  changes?: Partial<LedgerTransactionFormikValues>;
}) => {
  return id ? (
    <LedgerTransactionEditForm
      id={id}
      handleClose={handleClose}
      successCallback={successCallback}
      changes={changes}
    />
  ) : (
    <LedgerTransactionCreateForm
      handleClose={handleClose}
      successCallback={successCallback}
      changes={changes}
    />
  );
};

export default LedgerTransactionFormPage;
