import { floor, isNil } from "lodash";
import * as Yup from "yup";

import {
  dateSubmitHandler,
  parseContactAsSimpleOption,
  parseDealOptionAsString,
  simpleOptionSubmitHandler,
  submitCurrency,
  submitTwoDecimal,
} from "pages/deal/utils/deal_form";
import { VisibilityLevelEnum } from "types/api/deal/dynamic_form/form_field";
import { FormSection } from "types/api/deal/dynamic_form/form_layout";
import { SizeType } from "types/deal";
import { EditableFormObjectMetadata } from "types/formMetadata";

export const PropertyBaseMetadata: EditableFormObjectMetadata = {
  name: {
    fieldName: "name",
    displayName: "Property Name",
    _schema: Yup.string().required("Property Name is required."),
    component: "TextFieldString",
    initialValue: "",
    formCanRemove: false,
    data_type: "text",
  },
  notes_plaintext: {
    fieldName: "notes_plaintext",
    displayName: "Notes",
    initialValue: "",
    _schema: Yup.string()
      .max(25000, "Notes must be less than 25,000 characters.")
      .nullable(),
    component: "TextFieldStringMultiline",
    data_type: "multiline_text",
  },
  _address_search: {
    fieldName: "_address_search",
    displayName: "Address",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: "FormikAddressSearchField",
    virtual: true,
    data_type: "address",
    formCanEditLayout: false,
  },
  address_city: {
    fieldName: "address_city",
    displayName: "City",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_country: {
    fieldName: "address_country",
    displayName: "Country",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_latitude: {
    fieldName: "address_latitude",
    displayName: "Latitude",
    initialValue: null,
    _schema: Yup.number().nullable(),
  },
  address_line_1: {
    fieldName: "address_line_1",
    displayName: "Address Line 1",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_line_2: {
    fieldName: "address_line_2",
    displayName: "Address Line 2",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_longitude: {
    fieldName: "address_longitude",
    displayName: "Longitude",
    initialValue: null,
    _schema: Yup.number().nullable(),
  },
  address_postal_code: {
    fieldName: "address_postal_code",
    displayName: "Zip",
    initialValue: null,
    _schema: Yup.string().max(255).nullable(),
  },
  address_state: {
    fieldName: "address_state",
    displayName: "State",
    initialValue: null,
    _schema: Yup.string().max(2).nullable(),
  },
  last_sold_date: {
    fieldName: "last_sold_date",
    displayName: "Last Sold Date",
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Last Sold Date must be a date.`),
    component: "DatePicker",
    submitHandler: dateSubmitHandler,
    data_type: "date",
  },
  last_sold_price: {
    fieldName: "last_sold_price",
    displayName: "Last Sold Price",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Last Sold Price must be positive.")
      .nullable()
      .typeError(`A value must be entered for Last Sold Price.`),
    component: "TextFieldCurrency",
    submitHandler: submitCurrency,
    data_type: "currency",
  },
  asset_type: {
    fieldName: "asset_type",
    displayName: "Asset Type",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "DealOptionAutocompleteNew",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "asset_type_name",
    data_type: "lookup_item",
  },
  classification: {
    fieldName: "classification",
    displayName: "Classification",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "DealOptionAutocompleteNew",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "classification_name",
    data_type: "lookup_item",
  },
  submarket: {
    fieldName: "submarket",
    displayName: "Submarket",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "DealOptionAutocompleteNew",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "submarket_name",
    data_type: "lookup_item",
  },
  zoning: {
    fieldName: "zoning",
    displayName: "Zoning",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: "DealOptionAutocompleteNew",
    loadHandler: parseDealOptionAsString,
    submitFieldName: "zoning_name",
    data_type: "lookup_item",
  },
  year_built: {
    fieldName: "year_built",
    displayName: "Year Built",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Year Built must be positive.")
      .nullable()
      .typeError(`Year Built must be a number.`),
    component: "TextFieldWholeNumberUnformatted",
    data_type: "whole_number_unformatted",
  },
  property_owner: {
    fieldName: "property_owner",
    displayName: "Property Owner",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: "ContactLookupField",
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    submitFieldName: "property_owner_id",
    data_type: "contact",
  },
  size: {
    fieldName: "size",
    displayName: "Size",
    initialValue: null,
    _schema: Yup.number().nullable().min(0, "Size must be positive."),
    component: "PropertySizeField",
    submitHandler: submitTwoDecimal,
    data_type: "decimal_number",
  },
  size_type: {
    fieldName: "size_type",
    displayName: "Size Type",
    initialValue: SizeType.square_feet,
    _schema: Yup.string().nullable(),
  },
  lot_size: {
    fieldName: "lot_size",
    displayName: "Lot Size",
    initialValue: null,
    _schema: Yup.number().nullable().min(0, "Lot Size must be positive."),
    component: "PropertyLotSizeField",
    submitHandler: (x?: number | null) => (isNil(x) ? null : floor(x, 4)),
    data_type: "decimal_number",
  },
  lot_size_type: {
    fieldName: "lot_size_type",
    displayName: "Lot Size Type",
    initialValue: SizeType.square_feet,
    _schema: Yup.string().nullable(),
  },
};

export const getDefaultPropertyFormLayout: () => {
  name: string;
  layout: FormSection[];
} = () => ({
  name: "Property Form",
  layout: [
    {
      name: "Property Details",
      content: [
        { column_width: 6, field_type: "default", field_name: "name" },
        {
          column_width: 6,
          field_type: "default",
          field_name: "property_owner",
        },
        {
          column_width: 12,
          field_type: "default",
          field_name: "_address_search",
        },
      ],
      visible: VisibilityLevelEnum.always,
      visible_condition: null,
    },
    {
      name: "Additional Details",
      content: [
        { column_width: 6, field_type: "default", field_name: "asset_type" },
        { column_width: 6, field_type: "default", field_name: "submarket" },
        { column_width: 6, field_type: "default", field_name: "size" },
        { column_width: 6, field_type: "default", field_name: "lot_size" },
      ],
      visible: VisibilityLevelEnum.always,
      visible_condition: null,
    },
    {
      name: "Notes",
      content: [
        {
          column_width: 12,
          field_type: "default",
          field_name: "notes_plaintext",
        },
      ],
      visible: VisibilityLevelEnum.always,
      visible_condition: null,
    },
  ],
});

export const STATIC_REQUIRED_PROPERTY_FIELDS = ["name"];
