import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Grid, IconButton, Stack } from "@mui/material";
import { FieldArray, useField, useFormikContext } from "formik";
import _, { get } from "lodash";
import { useMemo } from "react";

import { FieldLabel } from "components/form/basic/creone_field";
import { DatePicker } from "components/form/DatePicker";
import { useEditing } from "contexts/EditingContext";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { emptyKeyDateUpdate } from "types/keyDate";

const KeyDatesSection = () => {
  const { setFieldValue } = useFormikContext();
  const [keyDatesField] = useField("key_dates");
  const { isEditing } = useEditing();

  const handleRemove = (idx: number) => {
    // Mark row for deletion (regardless of whether it has been created or not)
    setFieldValue(`key_dates.${idx}.is_delete`, true);
  };

  const nonDeletedRows = useMemo(
    () => _.filter(keyDatesField.value, (x) => !get(x, "is_delete")),
    [keyDatesField.value]
  );
  const lastNonDeletedRowIndex = useMemo(
    () => _.findLastIndex(keyDatesField.value, (x) => !get(x, "is_delete")),
    [keyDatesField.value]
  );

  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={1}
      alignItems={"center"}
      sx={{ pt: 1 }}
    >
      <Grid item xs={5}>
        <FieldLabel
          required={false}
          disabled={false}
          displayName={"Date Type"}
          fieldName={"Date Type"}
        />
      </Grid>
      <Grid item xs={3}>
        <FieldLabel
          required={false}
          disabled={false}
          displayName={"Date"}
          fieldName={"Date"}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={5}>
        Close Date
      </Grid>
      <Grid item xs={3}>
        <DatePicker fieldName={`close_date`} showLabel={false} />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={5}>
        Lease Expiration
      </Grid>
      <Grid item xs={3}>
        <DatePicker fieldName={`lease_expiration`} showLabel={false} />
      </Grid>
      <Grid item xs={4}></Grid>

      <FieldArray name={"key_dates"}>
        {({ push }) => (
          <>
            {keyDatesField.value.map((row: any, index: any) => (
              <>
                {!row["is_delete"] && (
                  <>
                    <Grid item xs={5}>
                      <DealOptionAutocompleteNew
                        fieldName={`key_dates.${index}.date_type`}
                        showLabel={false}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <DatePicker
                        fieldName={`key_dates.${index}.date`}
                        showLabel={false}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {!!isEditing && (
                        <Stack direction={"row"} sx={{ pt: 0.75 }}>
                          {nonDeletedRows.length > 1 && (
                            <IconButton onClick={() => handleRemove(index)}>
                              <RemoveCircleIcon />
                            </IconButton>
                          )}
                          {index === lastNonDeletedRowIndex && (
                            <IconButton
                              disabled={nonDeletedRows.length >= 10}
                              onClick={() => {
                                push(emptyKeyDateUpdate);
                              }}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          )}
                        </Stack>
                      )}
                    </Grid>
                  </>
                )}
              </>
            ))}
          </>
        )}
      </FieldArray>
    </Grid>
  );
};

export default KeyDatesSection;
