import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from "formik";
import React from "react";

import { SelectOption } from "components/form/SelectOption";
import { TextFieldString } from "components/form/TextFieldString";
import LookupAddress from "components/form_v2/LookupAddress";
import { SelectUSState } from "components/form_v2/SelectUSState";
import {
  CompanyLookupField,
  ContactLookupField,
} from "pages/deal/components/LookupField";
import { parseRecordAsSimpleOption } from "pages/deal/utils/deal_form";
import { Company, CompanyRead } from "types/api/deal/company";
import { ContactRead } from "types/api/deal/contact";
import { LookupItem } from "types/api/deal/form";
import { PartyRoleEnum } from "types/api/deal/transaction_party";
import { AddressSearchResult } from "types/form/dealForm";
import { dealService } from "utils/axios";

interface TransactionPartyFormValues {
  id?: string | null;
  role: PartyRoleEnum;

  contact?: LookupItem | null;
  company?: LookupItem | null;

  first_name?: string | null;
  last_name?: string | null;
  email_address?: string | null;

  address_line_1?: null | string;
  address_line_2?: null | string;
  address_state?: null | string;
  address_city?: null | string;
  address_postal_code?: null | string;
  address_country?: null | string;
  address_latitude?: null | number;
  address_longitude?: null | number;
}

const PARTY_ROLE_LOOKUP_ITEMS = [
  { key: PartyRoleEnum.BILL_TO, label: "Bill To" },
  { key: PartyRoleEnum.OWNER, label: "Owner" },
  { key: PartyRoleEnum.TENANT, label: "Tenant" },
  { key: PartyRoleEnum.BUYER, label: "Buyer" },
  { key: PartyRoleEnum.SELLER, label: "Seller" },
  { key: PartyRoleEnum.OTHER, label: "Other" },
];

const getEmptyTransactionPartyRow = () =>
  ({
    role: PartyRoleEnum.OTHER,
    first_name: "",
    last_name: "",
    email_address: "",
    contact: null,
    company: null,
    address_line_1: "",
    address_line_2: "",
    address_city: "",
    address_state: "",
    address_postal_code: "",
  }) as TransactionPartyFormValues;

export const TransactionPartiesField = () => {
  const { setFieldValue } = useFormikContext();
  const [transactionPartyField] = useField("transaction_parties");

  // Track which party row (if any) is being edited
  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);

  const onContactFieldChange = async (value: any) => {
    // Drop out early if no value
    if (!value || !value.key) return;

    try {
      const contactObj: ContactRead | undefined = await dealService
        .get(`/contact/${value.key}`)
        .then((response) => {
          return response?.data?.data || undefined;
        });

      if (!contactObj) return;

      // Define fields to check and set dynamically
      const contactFieldValues: Record<string, any> = {
        company: parseRecordAsSimpleOption<Company>(contactObj.company, "name"),
        first_name: contactObj.first_name,
        last_name: contactObj.last_name,
        email_address: contactObj.email_address,
        address_line_1: contactObj.company?.line_1,
        address_line_2: contactObj.company?.line_2,
        address_city: contactObj.company?.city,
        address_state: contactObj.company?.state,
        address_postal_code: contactObj.company?.postal_code,
        address_country: contactObj.company?.country,
      };

      // Set only if values[field] is empty
      Object.entries(contactFieldValues).forEach(([formField, fieldValue]) => {
        setFieldValue(
          `transaction_parties.${editingIndex}.${formField}`,
          fieldValue
        );
      });
    } catch (error) {
      console.error("Error fetching contact details:", error);
    }
  };

  const onCompanyFieldChange = async (value: any) => {
    // Drop out early if no value
    if (!value || !value.key) return;

    try {
      const companyObj: CompanyRead | undefined = await dealService
        .get(`/company/${value.key}`)
        .then((response) => {
          return response?.data?.data || undefined;
        });

      if (!companyObj) return;

      // Define fields to check and set dynamically
      const contactFieldValues: Record<string, any> = {
        company: parseRecordAsSimpleOption<CompanyRead>(companyObj, "name"),
        address_line_1: companyObj.line_1,
        address_line_2: companyObj.line_2,
        address_city: companyObj.city,
        address_state: companyObj.state,
        address_postal_code: companyObj.postal_code,
        address_country: companyObj.country,
      };

      // Set only if values[field] is empty
      Object.entries(contactFieldValues).forEach(([formField, fieldValue]) => {
        setFieldValue(
          `transaction_parties.${editingIndex}.${formField}`,
          fieldValue
        );
      });
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const onAddressSearchChange = (address: AddressSearchResult) => {
    const addressFieldValues = {
      address_line_1: address.line_1,
      address_line_2: address.line_2,
      address_city: address.city,
      address_state: address.state,
      address_postal_code: address.postal_code,
      address_country: address.country,
    };

    Object.entries(addressFieldValues).forEach(([formField, fieldValue]) => {
      setFieldValue(
        `transaction_parties.${editingIndex}.${formField}`,
        fieldValue
      );
    });
  };

  return (
    <FieldArray name="transaction_parties">
      {(arrayHelpers: FieldArrayRenderProps) => {
        const parties: TransactionPartyFormValues[] =
          transactionPartyField.value || [];

        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              Transaction Parties
            </Typography>

            {parties.length > 0 ? (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Role</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parties.map((party, index) => {
                      // Role label
                      const roleLabel =
                        PARTY_ROLE_LOOKUP_ITEMS.find(
                          (r) => r.key === party.role
                        )?.label || party.role;

                      // Name display
                      const companyName = party.company?.label || "";
                      const contactName = [party.first_name, party.last_name]
                        .filter(Boolean)
                        .join(" ");
                      const nameDisplay = [companyName, contactName]
                        .filter(Boolean)
                        .join(" - ");

                      // Address display
                      const addressDisplay = [
                        party.address_line_1,
                        party.address_line_2,
                        party.address_city,
                        party.address_state,
                        party.address_postal_code,
                      ]
                        .filter(Boolean)
                        .join(", ");

                      return (
                        <TableRow key={party.id ?? index}>
                          <TableCell>{roleLabel}</TableCell>
                          <TableCell>{nameDisplay || "—"}</TableCell>
                          <TableCell>{addressDisplay || "—"}</TableCell>
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={() => setEditingIndex(index)}
                              aria-label="edit"
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                <Box mt={2}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      arrayHelpers.push(getEmptyTransactionPartyRow())
                    }
                  >
                    Add a Party
                  </Button>
                </Box>

                {/* Edit Dialog */}
                <Dialog
                  open={editingIndex !== null}
                  onClose={() => setEditingIndex(null)}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>
                    <Grid container>
                      <Grid item>Edit Transaction Party</Grid>
                      <Grid item xs={true}></Grid>
                      <Grid item>
                        <SelectOption
                          fieldName={`transaction_parties.${editingIndex}.role`}
                          options={PARTY_ROLE_LOOKUP_ITEMS}
                          showLabel={false}
                        />
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    {editingIndex !== null && (
                      <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                          <ContactLookupField
                            fieldName={`transaction_parties.${editingIndex}.contact`}
                            displayName="Contact"
                            additionalChangeHandler={onContactFieldChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CompanyLookupField
                            fieldName={`transaction_parties.${editingIndex}.company`}
                            displayName="Company"
                            additionalChangeHandler={onCompanyFieldChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldString
                            fieldName={`transaction_parties.${editingIndex}.first_name`}
                            displayName="First Name"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextFieldString
                            fieldName={`transaction_parties.${editingIndex}.last_name`}
                            displayName="Last Name"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <LookupAddress
                            onValueChange={onAddressSearchChange}
                            fieldName={`transaction_parties.${editingIndex}._address_search`}
                            displayName={"Address Search"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextFieldString
                            fieldName={`transaction_parties.${editingIndex}.address_line_1`}
                            displayName={"Street address"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextFieldString
                            fieldName={`transaction_parties.${editingIndex}.address_line_2`}
                            displayName={"Apt / suite"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextFieldString
                            fieldName={`transaction_parties.${editingIndex}.address_city`}
                            displayName={"City"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <SelectUSState
                            fieldName={`transaction_parties.${editingIndex}.address_state`}
                            displayName={"State"}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextFieldString
                            fieldName={`transaction_parties.${editingIndex}.address_postal_code`}
                            displayName={"Zip"}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setEditingIndex(null)}>OK</Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
                <Typography>
                  No transaction parties have been added yet.
                </Typography>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      arrayHelpers.push(getEmptyTransactionPartyRow())
                    }
                  >
                    Add Your First Party
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        );
      }}
    </FieldArray>
  );
};
