import { NavigateFunction } from "react-router-dom";

import { handleDealFormNavigate } from "components/form/standard/utils/navigation";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { DealCreate } from "types/api/deal/deal";
import { Deal } from "types/deal";
import { coreService, dealService } from "utils/axios";
import { handleRecordActionResponseV3 } from "utils/record";

export async function createDealAsync(deal: Deal, navigate: NavigateFunction) {
  try {
    const response = await dealService.post(`/`, deal);
    handleRecordActionResponseV3(
      "Deal",
      "created",
      (x) => handleDealFormNavigate(navigate, x, {}),
      response
    );

    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating deal."));
    throw error;
  }
}

export async function createDealAsyncV2(deal: DealCreate) {
  try {
    const response = await coreService.post(`/deal`, deal);
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating deal."));
    throw error;
  }
}

export async function updateDealAsync(
  id: number | string,
  deal: object,
  navigate: NavigateFunction
) {
  try {
    const response = await dealService.put(`/${id}`, deal);
    handleRecordActionResponseV3(
      "Deal",
      "updated",
      (x) => handleDealFormNavigate(navigate, x, {}),
      response
    );
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating deal."));
    throw error;
  }
}

export async function updateDealAsyncV2(id: string, deal: Partial<DealCreate>) {
  try {
    const response = await coreService.put(`/deal/${id}`, deal);
    return response.data.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating deal."));
    throw error;
  }
}

export async function deleteDealAsync(id: number | string) {
  try {
    const response = await dealService.delete(`/${id}`);
    handleRecordActionResponseV3("Deal", "deleted", null, response);
  } catch (error) {
    dispatch(openErrorNotification("Error deleting deal."));
    throw error;
  }
}
