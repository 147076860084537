import _ from "lodash";
import React, { createContext, useContext, useEffect } from "react";
import { useQuery } from "react-query";

import { DealQueryKeys } from "pages/deal/constants/dealQueryKeys";
import { OrgPreferencesReadExtended } from "types/api/deal/preferences";
import { DealFeature, DealField } from "types/deal";
import { dealService } from "utils/axios";

interface DealOrgPreferencesContextType {
  data: OrgPreferencesReadExtended | null;
  isSuccess: boolean;
}

// Initialize context
const DealOrgPreferencesContext = createContext<DealOrgPreferencesContextType>({
  data: null,
  isSuccess: false,
});

// Create context provider
export function DealOrgPreferencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    /**
     * Trigger the idempotent Create Default Records endpoint from the UI as a
     * backup in case the service-to-service call failed when creating the
     * organization
     */
    dealService
      .post(`/create_default_records`, null)
      .then(function () {})
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  // Get org preferences
  const { data = null, isSuccess } = useQuery<OrgPreferencesReadExtended>({
    queryKey: [DealQueryKeys.dealOrgPreferences],
    queryFn: () => {
      return dealService.get("/org_preferences/").then((res) => {
        if (res.data) {
          return res.data;
        }
      });
    },
    retry: 3,
    staleTime: Infinity,
  });

  return (
    <DealOrgPreferencesContext.Provider value={{ data, isSuccess }}>
      {children}
    </DealOrgPreferencesContext.Provider>
  );
}

// Custom hook to use this context
export function useDealOrgPreferences() {
  const context = useContext(DealOrgPreferencesContext);
  if (!context) {
    throw new Error(
      "useDealOrgPreferences must be used within a DealOrgPreferencesProvider"
    );
  }
  return context;
}

export function isFeatureEnabledOrgPreferences(
  dealOrgPreferences: OrgPreferencesReadExtended | null,
  feature: DealFeature
) {
  return _.includes(_.get(dealOrgPreferences, "deal_features"), feature);
}

export function isWeightedEnabledOrgPreferences(
  dealOrgPreferences: OrgPreferencesReadExtended | null
) {
  return (
    isFeatureEnabledOrgPreferences(
      dealOrgPreferences,
      DealFeature.WEIGHTED_COMMISSION
    ) ||
    isFeatureEnabledOrgPreferences(
      dealOrgPreferences,
      DealFeature.WEIGHTED_TRANSACTION_VALUE
    )
  );
}

export function isFieldVisibleOrgPreferences(
  dealOrgPreferences: OrgPreferencesReadExtended | null,
  field: DealField
) {
  return _.includes(_.get(dealOrgPreferences, "deal_fields_visible"), field);
}

export function isFieldRequiredOrgPreferences(
  dealOrgPreferences: OrgPreferencesReadExtended | null,
  field: DealField
) {
  return (
    isFieldVisibleOrgPreferences(dealOrgPreferences, field) &&
    _.includes(_.get(dealOrgPreferences, "deal_fields_required"), field)
  );
}

export const updateOrgPreferencesAPI = (data: object) => {
  // Replace this with your actual API endpoint
  return dealService.put(`/org_preferences/`, data).then((res) => {
    return res.data;
  });
};
