import { DeleteFilled } from "@ant-design/icons";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik } from "formik";
import { reduce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { LightTooltip } from "components/form/basic/creone_field";
import { FormDialogActions } from "components/form/standard/FormDialogActions";
import { getFieldMetadataFromDataType } from "components/form/standard/utils/editableFormMetadata";
import {
  deriveYupSchemaFromMetadataV3,
  getInitialValuesV3,
  getOnSubmitV3,
} from "components/form/standard/utils/formMetadata";
import { ConfirmDeleteDialog } from "components/form_v2/editable/components/ConfirmDeleteDialog";
import { getDynamicFieldComponent } from "components/form_v2/editable/utils";
import MainCard from "components/MainCard";
import { PropertyBaseMetadata } from "constants/objectMetadata/propertyMetadata";
import { usePopForm, usePushForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupRequiredFieldsProvider } from "contexts/YupRequiredFieldsContext";
import useAuth from "hooks/useAuth";
import { CloseButton } from "pages/deal/components/CloseButton";
import { useFormFieldDictQuery } from "queries/useFormField";
import { useFormLayoutQuery } from "queries/useFormLayout";
import {
  getPropertyRecordQueryKey,
  usePropertyRecordQuery,
} from "queries/usePropertyRecord";
import { RecordActionResponse } from "types/api/deal/api";
import {
  CustomFormEntityEnum,
  FormFieldRead,
} from "types/api/deal/dynamic_form/form_field";
import { FormLayoutRead } from "types/api/deal/dynamic_form/form_layout";
import { PropertyCreate, PropertyRead } from "types/api/deal/property";
import { EditableFormObjectMetadata } from "types/formMetadata";
import {
  createPropertyAsync,
  deletePropertyAsync,
  updatePropertyAsync,
} from "utils/property";

interface PropertyFormikValues {
  id?: string;
  name: string;
}

interface PropertyFormProps {
  record?: PropertyRead;
  changes?: Partial<PropertyFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  formFields: Record<string, FormFieldRead>;
}

function PropertyForm({
  record,
  changes = {},
  submitData,
  formLayout,
  formFields,
}: PropertyFormProps) {
  const PropertyMetadata: EditableFormObjectMetadata = useMemo(
    () => ({
      ...PropertyBaseMetadata,
      additional_data: reduce(
        formFields,
        (acc, value) => ({
          ...acc,
          [value.name]: getFieldMetadataFromDataType(
            value.data_type,
            value.name,
            value.display_name
          ),
        }),
        {}
      ),
    }),
    [formFields]
  );

  return (
    <PropertyFormBase
      submitData={submitData}
      formLayout={formLayout}
      changes={changes}
      record={record}
      metadata={PropertyMetadata}
    />
  );
}

interface PropertyFormBaseProps {
  record?: PropertyRead;
  changes?: Partial<PropertyFormikValues>;
  submitData: (data: any) => Promise<void>;
  formLayout: FormLayoutRead;
  metadata: EditableFormObjectMetadata;
}

function PropertyFormBase({
  record,
  changes = {},
  submitData,
  formLayout,
  metadata,
}: PropertyFormBaseProps) {
  const queryClient = useQueryClient();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const popForm = usePopForm();

  const initialValues = useMemo(
    () =>
      getInitialValuesV3<PropertyRead, Partial<PropertyFormikValues>>(
        metadata,
        record,
        changes,
        usersDisplay,
        user
      ),
    [changes, metadata, record, user, usersDisplay]
  );

  const validationSchema = useMemo(
    () => deriveYupSchemaFromMetadataV3(metadata),
    [metadata]
  );

  const onSubmit = useMemo(
    () => getOnSubmitV3(metadata, submitData),
    [metadata, submitData]
  );

  const DynamicFieldComponent = useMemo(
    () => getDynamicFieldComponent(metadata),
    [metadata]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ handleSubmit }) => (
          <YupRequiredFieldsProvider validationSchema={validationSchema}>
            <Form>
              <Dialog open={true} scroll={"paper"} maxWidth={"md"}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "20px",
                  }}
                >
                  <DialogTitle>{record ? "Edit" : "New"} Property </DialogTitle>
                  <CloseButton onClose={popForm} />
                </div>
                <DialogContent dividers>
                  <Stack spacing={2}>
                    {formLayout.layout.map((section, sectionIndex) => (
                      <MainCard
                        title={section.name}
                        key={`section-${sectionIndex}`}
                      >
                        <Grid container spacing={2}>
                          {section.content.map((item) => {
                            return (
                              <DynamicFieldComponent
                                columnWidth={item.column_width}
                                fieldName={
                                  item.field_type === "default"
                                    ? `${item.field_name}`
                                    : `additional_data.${item.field_name}`
                                }
                                key={`field-${item.field_name}`}
                              />
                            );
                          })}
                        </Grid>
                      </MainCard>
                    ))}
                  </Stack>
                </DialogContent>
                <FormDialogActions
                  canDelete={!!record?.id}
                  handleDelete={() => setDeleteModalOpen(true)}
                />
              </Dialog>
              <ConfirmDeleteDialog
                entity="Property"
                isOpen={deleteModalOpen}
                handleDeleteClose={() => setDeleteModalOpen(false)}
                handleDeleteField={() => {
                  record &&
                    deletePropertyAsync(record?.id).then(() => {
                      queryClient.invalidateQueries(["property_table"]);
                      setDeleteModalOpen(false);
                      popForm();
                    });
                }}
              />
            </Form>
          </YupRequiredFieldsProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
}

interface PropertyEditFormProps {
  id: string;
  changes?: Partial<PropertyFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const PropertyEditForm = ({
  id,
  changes,
  handleClose,
  successCallback,
}: PropertyEditFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: PropertyCreate) => {
      const responseObj = await updatePropertyAsync(id, record, pushForm);
      await queryClient.invalidateQueries(getPropertyRecordQueryKey(id));
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, id, pushForm, queryClient, successCallback]
  );

  const { data } = usePropertyRecordQuery(id);

  return !!formLayout && !!formFields && !!data ? (
    <PropertyForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
      record={data}
    />
  ) : null;
};

interface PropertyCreateFormProps {
  changes?: Partial<PropertyFormikValues>;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
}

const PropertyCreateForm = ({
  changes,
  handleClose,
  successCallback,
}: PropertyCreateFormProps) => {
  const pushForm = usePushForm();
  const { data: formLayout } = useFormLayoutQuery(
    CustomFormEntityEnum.property
  );
  const { data: formFields } = useFormFieldDictQuery(
    CustomFormEntityEnum.property
  );

  const queryClient = useQueryClient();

  const submitData = useCallback(
    async (record: PropertyCreate) => {
      const responseObj = await createPropertyAsync(record, pushForm);
      await queryClient.invalidateQueries(["property_table"]);
      successCallback(responseObj);
      handleClose();
    },
    [handleClose, pushForm, queryClient, successCallback]
  );

  return !!formLayout && !!formFields ? (
    <PropertyForm
      submitData={submitData}
      formLayout={formLayout}
      formFields={formFields}
      changes={changes}
    />
  ) : null;
};

const PropertyFormPage = ({
  id,
  handleClose,
  successCallback,
  changes,
}: {
  id?: string;
  handleClose: () => void;
  successCallback: (actionList: RecordActionResponse[]) => void;
  changes?: Partial<PropertyFormikValues>;
}) => {
  return id ? (
    <PropertyEditForm
      id={id}
      handleClose={handleClose}
      successCallback={successCallback}
      changes={changes}
    />
  ) : (
    <PropertyCreateForm
      handleClose={handleClose}
      successCallback={successCallback}
      changes={changes}
    />
  );
};

export default PropertyFormPage;
