import { Balance, RequestPageOutlined } from "@mui/icons-material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { FormattedMessage } from "react-intl";

import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - BACK OFFICE ||============================== //
const back_office: NavItemType = {
  id: "group-back-office",
  title: <FormattedMessage id="back-office" />,
  type: "group",
  children: [
    {
      id: "bo-vouchers",
      title: <FormattedMessage id="bo-vouchers" />,
      type: "item",
      breadcrumbs: false,
      url: "/back-office/vouchers/overview",
      icon: RequestPageOutlined,
    },
    {
      id: "bo-accounting",
      title: "Accounting",
      type: "item",
      url: "/back-office/accounting/overview",
      icon: Balance,
    },
    // {
    //   id: "bo-invoices",
    //   title: <FormattedMessage id="bo-invoices" />,
    //   type: "item",
    //   breadcrumbs: false,
    //   url: "/back-office/invoices/overview",
    //   icon: FileTextOutlined,
    // },
    {
      id: "bo-reports",
      title: <FormattedMessage id="bo-reports" />,
      type: "item",
      url: "/back-office/reports/overview",
      icon: BarChartIcon,
    },
  ],
};

export default back_office;
