import Button from "@mui/material/Button";
import { useFormikContext } from "formik";
import _ from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { CreoneField } from "components/form/basic/creone_field";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { parseDealStageDict } from "pages/deal/utils/deal";
import { startEditingRecord } from "store/reducers/record";
import { ProspectStatusEnum } from "types/api/deal/enum";
import { SimpleOption } from "types/api/deal/form";
import { DealStage, DealType } from "types/deal";
import { FormIdentifier } from "types/record";
import { FieldComponentProps } from "types/standardForm";

const getOptionFromMultiple = (optionValues: SimpleOption[] | null) => {
  // If only one option was selected in the multi-select, return this option
  if (_.isArray(optionValues) && _.size(optionValues) === 1)
    return _.head(optionValues);
  // Else, return null
  return null;
};

export function ConvertToDealButton(props: FieldComponentProps) {
  const { values, setValues } = useFormikContext();

  const isNewForm = useMemo(() => !_.get(values, "id"), [values]);

  const singleDealType = useMemo(
    () => getOptionFromMultiple(_.get(values, "prospect_types", null))?.key,
    [values]
  );

  // When deal type changes, update the status
  const queryOptions = useMemo(
    () => getBaseQueryOptionsDealService<Record<number, DealStage>>("/stage/"),
    []
  );
  const { data: dealStages = [] } = useQuery<Record<number, DealStage>>({
    ...queryOptions,
    enabled: !isNewForm && !_.isNil(singleDealType),
  });
  const firstStage = useMemo(
    () =>
      typeof singleDealType === "string"
        ? _.get(parseDealStageDict(dealStages, singleDealType as DealType), "0")
        : null,
    [dealStages, singleDealType]
  );

  const convertToDeal = (incomingChanges: any) => {
    startEditingRecord(
      undefined,
      FormIdentifier.DealViewProForm,
      incomingChanges
    );
  };

  return (
    <CreoneField {...props}>
      <LightTooltip
        title={
          isNewForm
            ? "Save the Prospect before converting to a Deal"
            : "Convert to Deal"
        }
      >
        <Button
          variant="outlined"
          color={"primary"}
          onClick={() => {
            // Update current record
            setValues((x: any) => ({
              ...x,
              status: ProspectStatusEnum.converted,
              conversion_date: new Date(),
            }));
            // Open deal form
            const incomingChanges = {
              deal_type: singleDealType,
              stage: firstStage,
              size_est: _.get(values, "size_est"),
              size_type: _.get(values, "size_type"),
              client_industry: _.get(values, "client_industry"),
              source_type: _.get(values, "source_type"),
              contact_id: _.get(values, "id"),
              contact: {
                key: _.get(values, "id"),
                label: _.get(values, "full_name"),
              },
              asset_type: _.get(
                getOptionFromMultiple(_.get(values, "asset_types", null)),
                "label"
              ),
              submarket: _.get(
                getOptionFromMultiple(_.get(values, "submarkets", null)),
                "label"
              ),
              property_record: _.get(
                getOptionFromMultiple(_.get(values, "property_names", null)),
                "label"
              ),
            };
            convertToDeal(incomingChanges);
          }}
          disabled={isNewForm}
          size={"small"}
          sx={{ height: "34.25px" }}
        >
          Convert to Deal
        </Button>
      </LightTooltip>
    </CreoneField>
  );
}
