import { DeleteFilled, InfoCircleFilled } from "@ant-design/icons";
import { Alert, Button, Grid, IconButton, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useFormikContext } from "formik";
import { size } from "lodash";
import React from "react";

import { LightTooltip } from "components/form/basic/creone_field";
import { usePopForm } from "contexts/FormDialogsContext";

export function FormDialogActions({
  saveButtonText = "Save",
  canDelete,
  handleDelete,
}: {
  saveButtonText?: string;
  canDelete?: boolean;
  handleDelete?: () => void;
}) {
  const { submitCount, errors, isSubmitting, handleSubmit } =
    useFormikContext();
  const popForm = usePopForm();

  return (
    <DialogActions>
      <Grid container style={{ display: "flex" }} alignItems="center">
        {canDelete && !!handleDelete && (
          <Grid item xs>
            <LightTooltip title="Delete this property.">
              <IconButton onClick={handleDelete} size="large" color="error">
                <DeleteFilled />
              </IconButton>
            </LightTooltip>
          </Grid>
        )}
        <Grid item xs={true}></Grid>
        <Grid item>
          {submitCount > 0 && size(errors) ? (
            <Alert color="error" icon={<InfoCircleFilled />}>
              Please correct one or more errors on the form.
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
        {/* Adjust spacing to keep the error warning centered */}
        {canDelete && !!handleDelete && <Grid item xs={true}></Grid>}
        <Grid item xs>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            alignItems="center"
            sx={{ mr: 1 }}
          >
            <Button color="secondary" onClick={popForm} size={"large"}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              size={"large"}
              variant="contained"
              disabled={isSubmitting}
            >
              {saveButtonText}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </DialogActions>
  );
}
