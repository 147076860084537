import { assign, get, isArray, isNil, map, size, sortBy, sumBy } from "lodash";
import * as Yup from "yup";

import config from "config";
import {
  isFeatureEnabledOrgPreferences,
  isFieldRequiredOrgPreferences,
} from "contexts/DealOrgPreferencesContext";
import { getUSStateOptions } from "pages/deal/constants/address";
import {
  DEAL_TYPE_OPTIONS,
  dealStatusDisplay,
  SIZE_TYPE_OPTIONS,
} from "pages/deal/constants/deal_enums";
import {
  dateSubmitHandler,
  isDealViewPro,
  keyDatesSubmitHandler,
  simpleOptionSubmitHandler,
  submitCurrency,
  submitPercent,
  submitTwoDecimal,
} from "pages/deal/utils/deal_form";
import { BrokerRoleEnum } from "types/api/deal/commissions";
import { DealCreate, DealUpdate } from "types/api/deal/deal";
import { DealExtended } from "types/api/deal/dealExtended";
import { DealStatusEnum, SizeTypeEnum } from "types/api/deal/enum";
import { OrgPreferencesReadExtended } from "types/api/deal/preferences";
import { AccessTokenRead } from "types/api/user_management/access_token";
import { DealFeature, DealField, DealStageType, DealType } from "types/deal";
import { DealFormikSubmitValues, DealFormikValues } from "types/form/dealForm";
import { emptyKeyDateUpdate } from "types/keyDate";
import { areFloatsEqual } from "utils/math";

export const DEAL_FIELD_DISPLAY_NAMES = {
  _address_search: "Address search",
  _commission_est_percent_toggle: "",
  address_city: "City",
  address_country: "Country",
  address_latitude: "Latitude",
  address_line_1: "Line 1",
  address_line_2: "Line 2",
  address_longitude: "Longitude",
  address_postal_code: "Zip",
  address_state: "State",
  asset_type: "Asset Type",
  break: "Break",
  client_industry: "Client Industry",
  close_date: "Actual Close Date",
  close_date_est: "Estimated Close Date",
  commission: "Actual Commission",
  commission_est: "Estimated Commission",
  commission_payments: "Commission Payments",
  commission_payments_percent_toggle: "Commission Payment Date Breakdown",
  commission_percent_toggle: "Commission",
  commission_split: "Commission Split",
  commission_split_percent_toggle: "Commission Split",
  contact: "Primary Contact",
  counterparty_broker_company: "Outside Brokerage Company",
  created_at: "Date Created",
  created_by: "Created By",
  deal_type: "Deal Type",
  hire_date: "Hire Date",
  is_dual_agency: "Is there an Outside Broker on this deal?",
  key_dates: "Key Dates",
  lease_expiration: "Lease Expiration",
  lost_date: "Lost Date",
  lost_reason: "Lost Reason",
  move_in_date: "Actual Move In Date",
  move_in_date_est: "Estimated Move In Date",
  name: "Deal Name",
  notes_plaintext: "",
  outside_broker: "Outside Broker Name",
  probability_override: "Probability",
  property_name: "Property Name",
  property_record: "Property",
  relocation_date: "Actual Relocation Date",
  relocation_date_est: "Estimated Relocation Date",
  shared_broker: "Shared Broker Name",
  shared_broker_company: "Shared Brokerage Company",
  shared_deal: "Is this client being shared with another broker?",
  size: "Actual Size",
  size_est: "Estimated Size",
  size_type: "",
  source_type: "Deal Source",
  stage: "Stage",
  stage_stepper: "",
  status: "Status",
  submarket: "Submarket",
  tenant_name: "Tenant Name",
  transaction_value: "Actual Transaction Value",
  transaction_value_est: "Estimated Transaction Value",
  updated_at: "Date Modified",
  updated_by: "Modified By",
};

export const getDealFormInitialValues = (
  user: AccessTokenRead
): DealFormikValues => ({
  _address_search: null,
  _commission_est_percent_toggle: false,
  address_city: null,
  address_country: null,
  address_latitude: null,
  address_line_1: null,
  address_line_2: null,
  address_longitude: null,
  address_postal_code: null,
  address_state: null,
  asset_type: null,
  client_industry: null,
  close_date: null,
  close_date_est: null,
  commission: null,
  commission_est: null,
  commission_payments: [
    { amount: null, percent: null, date: null, paid: false },
    { amount: null, percent: null, date: null, paid: false },
  ],
  commission_payments_percent_toggle: true,
  commission_percent_toggle: false,
  commission_split: [
    {
      user: {
        key: user?.user_id,
        label: `${user?.first_name} ${user?.last_name}`,
      },
      broker_role: BrokerRoleEnum.EXECUTING_AND_SOURCING,
      estimate_percent: 1,
      estimate_amount: null,
      actual_percent: 1,
      actual_amount: null,
    },
  ],
  commission_split_percent_toggle: true,
  contact: null,
  counterparty_broker_company: null,
  created_at: null,
  created_by: null,
  deal_type: null,
  hire_date: null,
  is_dual_agency: false,
  key_dates: [
    {
      date: null,
      date_type: null,
      is_delete: false,
    },
  ],
  lease_expiration: null,
  lost_date: null,
  lost_reason: null,
  move_in_date: null,
  move_in_date_est: null,
  name: null,
  notes_plaintext: "",
  outside_broker: null,
  probability_override: null,
  property_name: null,
  property_record: null,
  relocation_date: null,
  relocation_date_est: null,
  shared_broker: null,
  shared_broker_company: null,
  shared_deal: false,
  size: null,
  size_est: null,
  size_type: SizeTypeEnum.square_feet,
  source_type: null,
  stage: null,
  stage_stepper: null,
  status: DealStatusEnum.open,
  submarket: null,
  tenant_name: null,
  transaction_value: null,
  transaction_value_est: null,
  updated_at: null,
  updated_by: null,
});

// TODO: Deal with the values params in the below visibility functions
// export const getDealFormFieldVisibility = (
//   dealOrgPreferences: OrgPreferencesReadExtended
// ) => ({
//   _address_search: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   address_city: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   address_country: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   address_latitude: () => false,
//   address_line_1: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   address_line_2: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   address_longitude: () => false,
//   address_postal_code: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   address_state: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.ADDRESS
//   ),
//   client_industry: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.CLIENT_INDUSTRY
//   ),
//   commission_payments:
//     isClosed(values) &&
//     isFeatureEnabledOrgPreferences(
//       dealOrgPreferences,
//       DealFeature.COMMISSION_PAYMENTS
//     ),
//   commission_payments_percent_toggle: (values) => isClosed(values),
//   commission_split: isFeatureEnabledOrgPreferences(
//     dealOrgPreferences,
//     DealFeature.COMMISSION_SPLITS
//   ),
//   contact: isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.CONTACT),
//   counterparty_broker_company: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.OUTSIDE_BROKER
//   ),
//   hire_date: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.HIRE_DATE
//   ),
//   is_dual_agency: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.OUTSIDE_BROKER
//   ),
//   lease_expiration:
//     isDealType(values, [
//       DealType.landlord_agency,
//       DealType.tenant_representation,
//       DealType.lease_both,
//     ]) &&
//     isClosed(values) &&
//     isFieldVisibleOrgPreferences(
//       dealOrgPreferences,
//       DealField.LEASE_EXPIRATION
//     ),
//   move_in_date:
//     isDealType(values, [DealType.landlord_agency, DealType.lease_both]) &&
//     isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.MOVE_IN_DATE),
//   move_in_date_est:
//     isDealType(values, [DealType.landlord_agency, DealType.lease_both]) &&
//     isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.MOVE_IN_DATE),
//   outside_broker: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.OUTSIDE_BROKER
//   ),
//   probability_override: useIsStageProbabilityEnabled,
//   property_name: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.PROPERTY_NAME
//   ),
//   property_record: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.PROPERTY_NAME
//   ),
//   relocation_date:
//     isDealType(values, [DealType.tenant_representation, DealType.lease_both]) &&
//     isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.RELOCATION_DATE),
//   relocation_date_est:
//     isDealType(values, [DealType.tenant_representation, DealType.lease_both]) &&
//     isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.RELOCATION_DATE),
//   shared_broker: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.SHARED_BROKER
//   ),
//   shared_broker_company: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.SHARED_BROKER
//   ),
//   shared_deal: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.SHARED_BROKER
//   ),
//   size: isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
//   size_est: isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
//   size_type: isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
//   source_type: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.SOURCE_TYPE
//   ),
//   stage: isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.STAGE),
//   submarket: isFieldVisibleOrgPreferences(
//     dealOrgPreferences,
//     DealField.SUBMARKET
//   ),
//   tenant_name:
//     isDealType(values, [
//       DealType.landlord_agency,
//       DealType.tenant_representation,
//       DealType.lease_both,
//     ]) &&
//     isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.TENANT_NAME),
// });

export const getDealFormSchema = (
  dealOrgPreferences: OrgPreferencesReadExtended | null
) => ({
  _address_search: Yup.object().nullable(),
  _commission_est_percent_toggle: Yup.boolean().nullable(),
  address_city: Yup.string().max(255).nullable(),
  address_country: Yup.string().max(255).nullable(),
  address_latitude: Yup.number().nullable(),
  address_line_1: Yup.string()
    .max(255)
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
      then: (schema) => schema.required("Address is required."),
    }),
  address_line_2: Yup.string().max(255).nullable(),
  address_longitude: Yup.number().nullable(),
  address_postal_code: Yup.string().max(255).nullable(),
  address_state: Yup.string().max(2).nullable(),
  asset_type: Yup.string().required(`Asset Type is required.`),
  break: Yup.object().nullable(),
  client_industry: Yup.string()
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(
          dealOrgPreferences,
          DealField.CLIENT_INDUSTRY
        ),
      then: (schema) => schema.required("Client Industry is required."),
    }),
  close_date: Yup.date()
    .nullable()
    .typeError(`Close Date must be a date.`)
    .when("status", {
      is: DealStatusEnum.closed,
      then: (schema) => schema.required("Close Date is required."),
    }),
  close_date_est: Yup.date()
    .nullable()
    .typeError(`Estimated Close Date must be a date.`)
    .when("status", {
      is: (x: DealStatusEnum) => x !== DealStatusEnum.closed,
      then: (schema) => schema.required(`Estimated Close Date is required.`),
    }),
  commission: Yup.number()
    .min(0, "Commission must be positive.")
    .nullable()
    .typeError(`A value must be entered for Commission.`)
    .when("status", {
      is: DealStatusEnum.closed,
      then: (schema) => schema.required("Commission is required."),
    }),
  commission_est: Yup.number()
    .nullable()
    .min(0, "Estimated Commission must be positive.")
    .typeError(`A number is expected for Estimated Commission.`)
    .when("status", {
      is: (x: DealStatusEnum) => x !== DealStatusEnum.closed,
      then: (schema) => schema.required(`Estimated Commission is required.`),
    }),
  commission_payments: Yup.array().when(["status"], {
    is: (status: DealStatusEnum) =>
      status === DealStatusEnum.closed &&
      isFeatureEnabledOrgPreferences(
        dealOrgPreferences,
        DealFeature.COMMISSION_PAYMENTS
      ),
    then: (schema) =>
      schema
        .of(
          Yup.object().shape({
            amount: Yup.number().nullable().min(0, "Amount must be positive."),
            date: Yup.date()
              .nullable()
              .when(["amount", "percent"], {
                is: (amount: number | null, percent: number | null) =>
                  (!isNil(amount) && amount > 0) ||
                  (!isNil(percent) && percent > 0),
                then: (schema) =>
                  schema.required(
                    "Date Due is required when Amount Due is provided."
                  ),
              }),
            paid: Yup.boolean().nullable(),
            percent: Yup.number()
              .nullable()
              .min(0, "Percent must be at least 0%.")
              .max(1, "Percent must be less than 100%."),
          })
        )
        .test(
          "first-item-amount-required",
          "First Amount Due is required",
          function (value, testContext) {
            const isPercent = get(
              testContext,
              "parent.commission_payments_percent_toggle",
              false
            );
            if (value && value.length > 0) {
              if (isPercent) {
                return (
                  value[0].percent !== undefined && value[0].percent !== null
                );
              } else {
                return (
                  value[0].amount !== undefined && value[0].amount !== null
                );
              }
            }
            return true;
          }
        )
        .test("amount-total-100", "amount-total-100", (items, testContext) => {
          const isPercent = get(
            testContext,
            "parent.commission_payments_percent_toggle",
            false
          );
          const commission = get(testContext, "parent.commission", null);

          if (isPercent) {
            const total = sumBy(items, (item) => item?.percent || 0);
            return areFloatsEqual(total, 1);
          } else {
            const total = sumBy(items, (item) => item?.amount || 0);
            return areFloatsEqual(total, commission);
          }
        }),
  }),
  commission_payments_percent_toggle: Yup.boolean().nullable(),
  commission_percent_toggle: Yup.boolean().nullable(),
  commission_split: Yup.array()
    .of(
      Yup.object().shape({
        user: Yup.object().required("Broker name must be provided."),
        broker_role: Yup.string().nullable(),
        estimate_percent: Yup.number()
          .nullable()
          .when(["status"], {
            is: (status: DealStatusEnum) => status !== DealStatusEnum.closed,
            then: (schema) =>
              schema
                .min(0, "Estimated Split must be positive.")
                .max(100, "Estimated Split must be less than 100%."),
          }),
        estimate_amount: Yup.number().nullable(),
        actual_percent: Yup.number()
          .nullable()
          .when(["status"], {
            is: (status: DealStatusEnum) => status === DealStatusEnum.closed,
            then: (schema) =>
              schema
                .min(0, "Actual Split must be positive.")
                .max(100, "Actual Split must be less than 100%."),
          }),
        actual_amount: Yup.number().nullable(),
      })
    )
    .test(
      "estimate-split-total-100",
      "estimate-split-total-100",
      (items, testContext) => {
        const isPercent = get(
          testContext,
          "parent.commission_split_percent_toggle",
          false
        );
        const commission_est = get(testContext, "parent.commission_est", null);

        if (isPercent) {
          const total = sumBy(items, (item) => item?.estimate_percent || 0);
          return areFloatsEqual(total, 1);
        } else {
          const total = sumBy(items, (item) => item?.estimate_amount || 0);
          return areFloatsEqual(total, commission_est);
        }
      }
    )
    .test(
      "actual-split-total-100",
      "actual-split-total-100",
      (items, testContext) => {
        const isClosed =
          get(testContext, "parent.status", DealStatusEnum.open) ===
          DealStatusEnum.closed;
        if (!isClosed) return true;

        const isPercent = get(
          testContext,
          "parent.commission_split_percent_toggle",
          false
        );
        const commission = get(testContext, "parent.commission", null);

        if (isPercent) {
          const total = sumBy(items, (item) => item?.actual_percent || 0);
          return areFloatsEqual(total, 1);
        } else {
          const total = sumBy(items, (item) => item?.actual_amount || 0);
          return areFloatsEqual(total, commission);
        }
      }
    ),
  commission_split_percent_toggle: Yup.boolean().nullable(),
  contact: Yup.object()
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.CONTACT),
      then: (schema) => schema.required("Primary Contact is required."),
    }),
  counterparty_broker_company: Yup.string().nullable(),
  created_at: Yup.date().nullable(),
  created_by: Yup.string().nullable(),
  deal_type: Yup.string().required(`Deal Type is required.`),
  hire_date: Yup.date()
    .nullable()
    .typeError(`Hire Date must be a date.`)
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.HIRE_DATE),
      then: (schema) => schema.required("Hire Date is required."),
    }),
  is_dual_agency: Yup.boolean().nullable(),
  key_dates: Yup.array().of(
    Yup.object().shape(
      {
        id: Yup.number().nullable(),
        is_delete: Yup.boolean().nullable(),
        date_type: Yup.string()
          .nullable()
          .when(["date", "is_delete"], {
            is: (date: any, is_delete: boolean) => !is_delete && !!date,
            then: (schema) => schema.required("Date Type is required."),
            otherwise: (schema) => schema,
          }),
        date: Yup.date()
          .nullable()
          .typeError("Date is required.")
          .when(["date_type", "is_delete"], {
            is: (date_type: any, is_delete: boolean) =>
              !is_delete && !!date_type,
            then: (schema) => schema.required("Date is required."),
            otherwise: (schema) => schema,
          }),
      },
      [["date_type", "date"]]
    )
  ),
  lease_expiration: Yup.date()
    .nullable()
    .typeError(`Lease Expiration must be a date.`)
    .when(["status", "deal_type"], {
      is: (status: DealStatusEnum, dealType: DealType) =>
        [
          DealType.landlord_agency,
          DealType.tenant_representation,
          DealType.lease_both,
        ].includes(dealType) &&
        status === DealStatusEnum.closed &&
        isFieldRequiredOrgPreferences(
          dealOrgPreferences,
          DealField.LEASE_EXPIRATION
        ),
      then: (schema) => schema.required("Lease Expiration is required."),
    }),
  lost_date: Yup.date()
    .nullable()
    .typeError(`Lost Date must be a date.`)
    .when("status", {
      is: DealStatusEnum.lost,
      then: (schema) => schema.required("Lost Date is required."),
    }),
  lost_reason: Yup.string()
    .nullable()
    .when("status", {
      is: DealStatusEnum.lost,
      then: (schema) => schema.required("Lost Reason is required."),
    }),
  move_in_date: Yup.date().nullable().typeError(`Move In Date must be a date.`),
  move_in_date_est: Yup.date()
    .nullable()
    .typeError(`Estimated Move In Date must be a date.`),
  name: Yup.string()
    .max(255, "Deal Name must be less than 256 characters.")
    .required(`Deal Name is required.`),
  notes_plaintext: Yup.string()
    .max(25000, "Notes must be less than 25,000 characters.")
    .nullable(),
  outside_broker: Yup.object().nullable(),
  probability_override: Yup.number()
    .nullable()
    .positive("Probability must be greater than 0%.")
    .max(100, "Probability must not exceed 100%"),
  property_record: Yup.object()
    .nullable()
    .when("deal_type", {
      is: () =>
        isFieldRequiredOrgPreferences(
          dealOrgPreferences,
          DealField.PROPERTY_NAME
        ),
      then: (schema) => schema.required("Property Name is required."),
    }),
  relocation_date: Yup.date()
    .nullable()
    .typeError(`Relocation Date must be a date.`),
  relocation_date_est: Yup.date()
    .nullable()
    .typeError(`Estimated Relocation Date must be a date.`),
  shared_broker: Yup.object().nullable(),
  shared_broker_company: Yup.string().nullable(),
  shared_deal: Yup.boolean().nullable(),
  size: Yup.number()
    .nullable()
    .min(0, "Actual Size must be positive.")
    .when("status", {
      is: (x: DealStatusEnum) =>
        x === DealStatusEnum.closed &&
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
      then: (schema) =>
        schema
          .typeError("Actual Size must be a number.")
          .required(`Actual Size must be a number.`),
    }),
  size_est: Yup.number()
    .nullable()
    .min(0, "Estimated Size must be positive.")
    .when("status", {
      is: (x: DealStatusEnum) =>
        x === DealStatusEnum.open &&
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
      then: (schema) =>
        schema
          .typeError("Estimated Size must be a number.")
          .required(`Estimated Size must be a number.`),
    }),
  size_type: Yup.string()
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
      then: (schema) =>
        schema.required("Choose a measurement type for the property size."),
    }),
  source_type: Yup.string()
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(
          dealOrgPreferences,
          DealField.SOURCE_TYPE
        ),
      then: (schema) => schema.required("Deal Source is required."),
    }),
  square_feet: Yup.number()
    .min(0, "Square Feet must be positive.")
    .nullable()
    .typeError(`Square Feet must be a number.`),
  square_feet_est: Yup.number()
    .min(0, "Estimated Square Feet must be positive.")
    .nullable()
    .typeError(`Estimated Square Feet must be a number.`),
  stage: Yup.object()
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.STAGE),
      then: (schema) => schema.required("Stage is required."),
    }),
  stage_stepper: Yup.number().nullable(),
  status: Yup.string().required(`Status is required.`).nullable(),
  submarket: Yup.string()
    .nullable()
    .when([], {
      is: () =>
        isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SUBMARKET),
      then: (schema) => schema.required("Submarket is required."),
    }),
  tenant_name: Yup.string()
    .nullable()
    .when("deal_type", {
      is: (x: DealType) =>
        x === DealType.tenant_representation &&
        isDealViewPro() &&
        isFieldRequiredOrgPreferences(
          dealOrgPreferences,
          DealField.TENANT_NAME
        ),
      then: (schema) => schema.required("Tenant Name is required."),
    }),
  transaction_value: Yup.number()
    .min(0, "Actual Transaction Value must be positive.")
    .nullable()
    .typeError(`Actual Transaction Value must be a number.`)
    .when(["status"], {
      is: (status: DealStatusEnum) => status === DealStatusEnum.closed,
      then: (schema) =>
        schema.required("Actual Transaction Value is required."),
    }),
  transaction_value_est: Yup.number()
    .min(0, "Estimated Transaction Value must be positive.")
    .nullable()
    .typeError(`Estimated Transaction Value must be a number.`)
    .when(["status"], {
      is: (status: DealStatusEnum) => status !== DealStatusEnum.closed,
      then: (schema) =>
        schema.required("Estimated Transaction Value is required."),
    }),
  updated_at: Yup.date().nullable(),
  updated_by: Yup.string().nullable(),
});

export const DEAL_FORM_COMPONENTS = {
  _address_search: "GoogleMapsAutocomplete",
  _commission_est_percent_toggle: "DollarPercentToggle",
  address_city: "TextFieldString",
  address_country: "TextFieldString",
  address_latitude: "TextFieldString",
  address_line_1: "TextFieldString",
  address_line_2: "TextFieldString",
  address_longitude: "TextFieldString",
  address_postal_code: "TextFieldString",
  address_state: "SelectOption",
  asset_type: "DealOptionAutocompleteNew",
  client_industry: "DealOptionAutocompleteNew",
  close_date: "DatePicker",
  close_date_est: "DatePicker",
  commission: "TextFieldCurrency",
  commission_est: "TextFieldCurrency",
  commission_payments: "CommissionPaymentSection",
  commission_payments_percent_toggle: "DollarPercentToggle",
  commission_percent_toggle: "DollarPercentToggle",
  commission_split: "CommissionSplitSection",
  commission_split_percent_toggle: "DollarPercentToggle",
  contact: "ContactLookupField",
  counterparty_broker_company: "DealOptionAutocompleteNew",
  created_at: "DataDateTimeDisplay",
  created_by: "DataUserDisplay",
  deal_type: "DealTypeSelectOption",
  hire_date: "DatePicker",
  is_dual_agency: "SwitchField",
  key_dates: "KeyDatesArrayComponent",
  lease_expiration: "DatePicker",
  lost_date: "DatePicker",
  lost_reason: "DealOptionAutocompleteNew",
  move_in_date: "DatePicker",
  move_in_date_est: "DatePicker",
  name: "TextFieldString",
  notes_plaintext: "TextFieldStringMultiline",
  outside_broker: "ContactLookupField",
  probability_override: "ProbabilityOverrideField",
  property_name: "DealOptionAutocompleteNew",
  property_record: "PropertyLookupField",
  relocation_date: "DatePicker",
  relocation_date_est: "DatePicker",
  shared_broker: "ContactLookupField",
  shared_broker_company: "DealOptionAutocompleteNew",
  shared_deal: "SwitchField",
  size: "TextFieldTwoDecimal",
  size_est: "TextFieldTwoDecimal",
  size_type: "SelectOption",
  source_type: "DealOptionAutocompleteNew",
  square_feet: "TextFieldWholeNumber",
  square_feet_est: "TextFieldWholeNumber",
  stage: "SelectStageAsync",
  stage_stepper: "StageStepper",
  status: "StatusToggleButton",
  submarket: "DealOptionAutocompleteNew",
  tenant_name: "DealOptionAutocompleteNew",
  transaction_value: "TextFieldCurrency",
  transaction_value_est: "TextFieldCurrency",
  updated_at: "DataDateTimeDisplay",
  updated_by: "DataUserDisplay",
};

export const virtual = {
  _address_search: true,
  _commission_est_percent_toggle: true,
  break: true,
  created_at: true,
  created_by: true,
  stage_stepper: true,
  updated_at: true,
  updated_by: true,
};

// TODO: Move the below functionality into the individual components
// export const additionalChangeHandler = {
//   _address_search: (value, setFieldValue) => {
//     if (get(value, "city")) {
//       setFieldValue("address_line_1", get(value, "line_1"));
//       setFieldValue("address_line_2", get(value, "line_2"));
//       setFieldValue("address_city", get(value, "city"));
//       setFieldValue("address_state", get(value, "state"));
//       setFieldValue("address_postal_code", get(value, "postal_code"));
//       setFieldValue("address_country", get(value, "country"));
//       setFieldValue("address_latitude", get(value, "latitude"));
//       setFieldValue("address_longitude", get(value, "longitude"));
//     }
//   },
//   asset_type: (value: string, setFieldValue, prevValue, values) => {
//     const textValue = value;
//     if (isNil(get(values, "size_est")) && isNil(get(values, "size"))) {
//       if (["Land"].includes(textValue)) {
//         setFieldValue("size_type", SizeType.acres);
//       } else if (
//         ["Multifamily", "Senior / Assisted Living"].includes(textValue)
//       ) {
//         setFieldValue("size_type", SizeType.units);
//       } else {
//         setFieldValue("size_type", SizeType.square_feet);
//       }
//     }
//   },
//   is_dual_agency: (value: any, setFieldValue: any) => {
//     if (!value) {
//       setFieldValue("counterparty_broker_company", null);
//       setFieldValue("outside_broker", null);
//     }
//   },
//   property_record: async (value, setFieldValue, prev, values) => {
//     if (!value || !value.key) return;
//
//     try {
//       const { data: property } = await dealService.get<PropertyExtended>(
//         `/property/${value.key}`
//       );
//
//       if (!property) return;
//
//       const propertyFieldValues: Record<string, any> = {
//         submarket: parseDealOptionAsString(property.submarket),
//         asset_type: parseDealOptionAsString(property.asset_type),
//         address_line_1: property.address_line_1,
//         address_line_2: property.address_line_2,
//         address_city: property.address_city,
//         address_state: property.address_state,
//         address_postal_code: property.address_postal_code,
//         address_country: property.address_country,
//       };
//
//       Object.entries(propertyFieldValues).forEach(([formField, fieldValue]) => {
//         if (!get(values, formField)) {
//           setFieldValue(formField, fieldValue);
//         }
//       });
//     } catch (error) {
//       console.error("Error fetching property details:", error);
//     }
//   },
//   shared_deal: (value: any, setFieldValue: any) => {
//     if (!value) {
//       setFieldValue("shared_broker_company", null);
//       setFieldValue("shared_broker", null);
//     }
//   },
//   status: (value, setFieldValue, prevValue) => {
//     if (value === DealStatus.lost) {
//       setFieldValue("lost_date", new Date());
//     } else if (value === DealStatus.open && prevValue === DealStatus.lost) {
//       setFieldValue("lost_date", null);
//     }
//   },
// };

export const prepareDealFormDataForSubmit = (
  dealFormValues: DealFormikSubmitValues | Partial<DealFormikSubmitValues>
): DealCreate | DealUpdate => ({
  asset_type_name: dealFormValues.asset_type,
  client_industry_name: dealFormValues.client_industry,
  close_date: dateSubmitHandler(dealFormValues.close_date),
  close_date_est: dateSubmitHandler(dealFormValues.close_date_est),
  commission: submitCurrency(dealFormValues.commission),
  commission_est: submitCurrency(dealFormValues.commission_est),
  commission_payments_percent_toggle:
    dealFormValues.commission_payments_percent_toggle,
  commission_percent_toggle: dealFormValues.commission_percent_toggle,
  commission_split_percent_toggle:
    dealFormValues.commission_split_percent_toggle,
  contact_id: simpleOptionSubmitHandler(dealFormValues.contact),
  counterparty_broker_company_name: dealFormValues.counterparty_broker_company,
  deal_type: dealFormValues.deal_type,
  hire_date: dateSubmitHandler(dealFormValues.hire_date),
  is_dual_agency: dealFormValues.is_dual_agency,
  key_dates_input: keyDatesSubmitHandler(dealFormValues.key_dates),
  lease_expiration: dateSubmitHandler(dealFormValues.lease_expiration),
  lost_date: dateSubmitHandler(dealFormValues.lost_date),
  lost_reason_name: dealFormValues.lost_reason,
  move_in_date: dateSubmitHandler(dealFormValues.move_in_date),
  move_in_date_est: dateSubmitHandler(dealFormValues.move_in_date_est),
  name: dealFormValues.name,
  notes_plaintext: dealFormValues.notes_plaintext,
  outside_broker_id: simpleOptionSubmitHandler(dealFormValues.outside_broker),
  probability_override: submitPercent(dealFormValues.probability_override),
  property_id: simpleOptionSubmitHandler(dealFormValues.property_record) as
    | number
    | null,
  property_name_name: dealFormValues.property_name,
  relocation_date: dateSubmitHandler(dealFormValues.relocation_date),
  relocation_date_est: dateSubmitHandler(dealFormValues.relocation_date_est),
  shared_broker_company_name: dealFormValues.shared_broker_company,
  shared_broker_id: simpleOptionSubmitHandler(dealFormValues.shared_broker),
  shared_deal: dealFormValues.shared_deal,
  size: submitTwoDecimal(dealFormValues.size),
  size_est: submitTwoDecimal(dealFormValues.size_est),
  size_type: dealFormValues.size_type,
  source_type_name: dealFormValues.source_type,
  stage_id: get(dealFormValues.stage, "id") as number | null,
  status: dealFormValues.status,
  submarket_name: dealFormValues.submarket,
  tenant_name_name: dealFormValues.tenant_name,
  transaction_value: submitCurrency(dealFormValues.transaction_value),
  transaction_value_est: submitCurrency(dealFormValues.transaction_value_est),
  commission_payments: map(dealFormValues.commission_payments, (x) => ({
    ...x,
    amount: submitCurrency(x.amount),
    date: dateSubmitHandler(x.date),
  })),
  commission_split: map(dealFormValues.commission_split, (y) => ({
    ...y,
    user_id: y.user.key,
    estimate_amount: submitCurrency(y.estimate_amount),
    actual_amount: submitCurrency(y.actual_amount),
  })),
});

export const loadDealFormData = (
  deal: DealExtended,
  usersDisplay: Record<string, string>
): Partial<DealFormikValues> => ({
  ...deal,
  stage: deal.stage as DealStageType | null,
  asset_type: get(deal.asset_type, "value", null),
  client_industry: get(deal.client_industry, "value", null),
  commission_split: map(deal.commission_split, (y) => ({
    ...y,
    user: {
      key: y.user_id,
      label: get(usersDisplay, y.user_id, "Unknown") as string,
    },
  })),
  contact: deal.contact && {
    key: deal.contact.id,
    label: deal.contact.full_name,
  },
  counterparty_broker_company: get(
    deal.counterparty_broker_company,
    "value",
    null
  ),
  key_dates:
    !isArray(deal.key_dates) || size(deal.key_dates) === 0
      ? [emptyKeyDateUpdate]
      : sortBy(
          map(deal.key_dates, (x) =>
            assign({}, x, { date_type: get(x?.date_type, "value", null) })
          ),
          "date"
        ),
  lost_reason: get(deal.lost_reason, "value", null),
  outside_broker: deal.outside_broker && {
    key: deal.outside_broker.id,
    label: deal.outside_broker.full_name,
  },
  probability_override: isNil(deal.probability_override)
    ? null
    : deal.probability_override * 100,
  property_name: get(deal.property_name, "value", null),
  property_record: deal.property_record && {
    key: deal.property_record.id,
    label: deal.property_record.name,
  },
  shared_broker: deal.shared_broker && {
    key: deal.shared_broker.id,
    label: deal.shared_broker.full_name,
  },
  shared_broker_company: get(deal.shared_broker_company, "value", null),
  source_type: get(deal.source_type, "value", null),
  submarket: get(deal.submarket, "value", null),
  tenant_name: get(deal.tenant_name, "value", null),
});

export const options = {
  address_state: getUSStateOptions(),
  size_type: SIZE_TYPE_OPTIONS,
  status: map(dealStatusDisplay, (val, idx) => ({ key: idx, label: val })),
};

// TODO: Move the below functionality into the individual components
// export const disabled = {
//   close_date: (values) => !isClosed(values),
//   commission: (values) => !isClosed(values),
//   counterparty_broker_company: (values) => !isDualAgency(values),
//   lease_expiration: (values) => !isClosed(values),
//   lost_date: (values) => !isLost(values),
//   lost_reason: (values) => !isLost(values),
//   move_in_date: (values) => !isClosed(values),
//   outside_broker: (values) => !isDualAgency(values),
//   relocation_date: (values) => !isClosed(values),
//   shared_broker: (values) => !isSharedDeal(values),
//   shared_broker_company: (values) => !isSharedDeal(values),
//   size: (values) => !isClosed(values),
//   square_feet: (values) => !isClosed(values),
//   stage: (values) => !dealTypeSelected(values),
// };

// TODO: Move the below functionality into the individual components
// export const formatForDisplay = {
//   close_date: formatDateForDisplay,
//   close_date_est: formatDateForDisplay,
//   commission: formatCurrency,
//   commission_est: formatCurrency,
//   hire_date: formatDateForDisplay,
//   lease_expiration: formatDateForDisplay,
//   lost_date: formatDateForDisplay,
//   move_in_date: formatDateForDisplay,
//   move_in_date_est: formatDateForDisplay,
//   property_record: formatSimpleOptionForDisplay,
//   relocation_date: formatDateForDisplay,
//   relocation_date_est: formatDateForDisplay,
//   size: formatWholeNumber,
//   size_est: formatWholeNumber,
//   size_type: (x) => get(sizeTypeDisplay, x, ""),
//   transaction_value: formatCurrency,
//   transaction_value_est: formatCurrency,
// };
