import { Droppable } from "@hello-pangea/dnd";
import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import MainCard from "components/MainCard";
import {
  DealAttachmentFolderRead,
  DealAttachmentRead,
} from "types/api/deal/deal_attachment";

export const FoldersList: React.FC<{
  folders: DealAttachmentFolderRead[];
  attachments: DealAttachmentRead[];
  loading: boolean;
  handleFolderClick: (id: string | null) => void;
  isDragging: boolean;
  readOnly?: boolean;
  selectedFolderId: string | null;
  handleFolderDelete: (id: string) => void;
  setSelectedItem: (item: { id: string; name: string }) => void;
  handleOpenModal: (
    mode: "create-folder" | "rename-folder" | "rename-file",
    item?: { id: string; name: string }
  ) => void;
}> = ({
  folders,
  attachments,
  loading,
  handleFolderClick,
  handleFolderDelete,
  handleOpenModal,
  selectedFolderId,
  setSelectedItem,
  isDragging,
  readOnly = false,
}) => {
  const FOLDER_NAME_LENGTH_LIMIT = 18;

  if (loading) {
    return <CircularProgress />;
  }

  const [folderMenu, setFolderMenu] = useState<{
    anchorEl: HTMLElement | null;
    folderId: string | null;
  }>({
    anchorEl: null,
    folderId: null,
  });

  const handleFolderMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    folderId: string
  ) => {
    setFolderMenu({ anchorEl: event.currentTarget, folderId });
    setSelectedItem({
      id: folderId,
      name: folders.find((folder) => folder.id === folderId)?.name || "",
    });
  };

  const handleFolderMenuClose = () => {
    setFolderMenu({ anchorEl: null, folderId: null });
  };

  return (
    <>
      {[{ id: null, name: "All Files", deal_id: 0 }, ...folders].map(
        (folder, index) => {
          const isAllFiles = folder.id === null;
          const filesInFolder = isAllFiles
            ? attachments
            : attachments.filter((a) => a.folder_id === folder.id);
          return (
            <Droppable
              key={index}
              droppableId={isAllFiles ? "All Files" : folder.id}
              isDropDisabled={readOnly || isAllFiles}
            >
              {(provided, snapshot) => (
                <MainCard
                  ref={provided.innerRef}
                  sx={{
                    height: 86,
                    cursor: "pointer",
                    transition: "opacity 0.2s ease, border-color 0.2s ease",
                    "&:hover": {
                      borderColor: !isDragging && "primary.main",
                    },
                    ...(snapshot.isDraggingOver &&
                      !isAllFiles && {
                        borderColor: "primary.main",
                        outline: 1,
                        outlineColor: "primary.main",
                      }),
                    opacity:
                      isDragging && !snapshot.isDraggingOver && !isAllFiles
                        ? 0.5
                        : 1,
                    ...(selectedFolderId === folder.id
                      ? {
                          outline: 1,
                          outlineColor: "primary.main",
                          borderColor: "primary.main",
                        }
                      : {}),
                  }}
                  contentSX={{ px: 2, py: isAllFiles ? 1.75 : 1 }}
                  onClick={() => handleFolderClick(folder.id)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <FolderOpenIcon sx={{ color: "#2289DF", mr: 1.5 }} />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: 15,
                          fontWeight: 600,
                          color: "grey.600",
                        }}
                      >
                        {folder.name.length > FOLDER_NAME_LENGTH_LIMIT
                          ? `${folder.name.slice(0, FOLDER_NAME_LENGTH_LIMIT)}...`
                          : folder.name}
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 1 }}>
                      {!readOnly && !isAllFiles && (
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleFolderMenuClick(event, folder.id);
                          }}
                        >
                          <MoreHoriz fontSize="small" />
                        </IconButton>
                      )}
                      <Menu
                        anchorEl={folderMenu.anchorEl}
                        open={Boolean(
                          folderMenu.anchorEl &&
                            folderMenu.folderId === folder.id
                        )}
                        onClose={handleFolderMenuClose}
                      >
                        <MenuItem
                          onClick={(event) => {
                            if (folder.id !== null) {
                              event?.stopPropagation();
                              handleOpenModal("rename-folder", {
                                id: folder.id,
                                name: folder.name,
                              });
                            }
                            handleFolderMenuClose();
                          }}
                        >
                          <Edit fontSize="small" sx={{ mr: 1 }} />
                          Rename
                        </MenuItem>
                        {filesInFolder.length === 0 && (
                          <MenuItem
                            onClick={() => {
                              if (folder.id !== null) {
                                handleFolderDelete(folder.id);
                              }
                              handleFolderMenuClose();
                            }}
                          >
                            <Delete fontSize="small" sx={{ mr: 1 }} />
                            Delete
                          </MenuItem>
                        )}
                      </Menu>
                    </Box>
                  </Box>
                  <Typography variant="caption" color="textSecondary">
                    {filesInFolder.length} file
                    {filesInFolder.length !== 1 ? "s" : ""}
                  </Typography>
                  {provided.placeholder}
                </MainCard>
              )}
            </Droppable>
          );
        }
      )}
    </>
  );
};
