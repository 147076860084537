import { useFormikContext } from "formik";

import { SwitchField } from "pages/deal/components/SwitchField";
import { FieldComponentProps } from "types/standardForm";

export const SharedDealField = (props: FieldComponentProps) => {
  const { setFieldValue } = useFormikContext();

  const additionalChangeHandler = async (value: boolean) => {
    if (!value) {
      await setFieldValue("shared_broker_company", null);
      await setFieldValue("shared_broker", null);
    }
  };
  return (
    <SwitchField {...props} additionalChangeHandler={additionalChangeHandler} />
  );
};
