import {
  AppstoreOutlined,
  DatabaseOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { Flag } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import { ProMenuBadge } from "pages/deal/components/ProMenuBadge";
import { Product } from "types/license";
import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //
const deal_view: NavItemType = {
  id: "group-deal-view",
  title: <FormattedMessage id="deal-view" />,
  type: "group",
  children: [
    {
      id: "dv-overview",
      title: <FormattedMessage id="dv-overview" />,
      type: "item",
      url: "/deals/overview",
      icon: DatabaseOutlined,
    },
    {
      id: "dv-dashboard",
      title: <ProMenuBadge textId="dv-dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: AppstoreOutlined,
    },
    {
      id: "dv-insights",
      title: <FormattedMessage id="dv-insights" />,
      type: "collapse",
      icon: LineChartOutlined,
      children: [
        {
          id: "dv-insights-commission",
          title: <FormattedMessage id="dv-insights-commission" />,
          type: "item",
          url: "/deals/insights/commission",
        },
        {
          id: "dv-insights-business",
          title: <FormattedMessage id="dv-insights-business" />,
          type: "item",
          url: "/deals/insights/business",
        },
        {
          id: "dv-insights-broker",
          title: <FormattedMessage id="dv-insights-broker" />,
          type: "item",
          url: "/deals/insights/broker",
        },
      ],
    },
    {
      id: "dv-comparisons",
      title: <ProMenuBadge textId="dv-comparisons-goals" />,
      type: "item",
      url: "/deals/comparisons/goals",
      icon: Flag,
    },
  ],
};

export default deal_view;
