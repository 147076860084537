import { Box } from "@mui/material";
import _, { get, isNil, map, sumBy } from "lodash";
import * as Yup from "yup";

import {
  DataDateTimeDisplay,
  DataUserDisplay,
} from "components/form/basic/data_display";
import { DollarPercentToggle } from "components/form/basic/DollarPercentToggle";
import StageStepper from "components/form/basic/StageProgress";
import { CommissionPaymentSection } from "components/form/compound/CommissionPaymentSection";
import { CommissionSplitSection } from "components/form/compound/CommissionSplitSection";
import { DatePicker } from "components/form/DatePicker";
import { DealTypeSelectOption } from "components/form/DealTypeSelectOption";
import { ProbabilityOverrideField } from "components/form/ProbabilityOverrideField";
import { SelectOption } from "components/form/SelectOption";
import {
  formatDateForDisplay,
  formatSimpleOptionForDisplay,
} from "components/form/standard/utils/formatting";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import {
  TextFieldString,
  TextFieldStringMultiline,
} from "components/form/TextFieldString";
import {
  TextFieldTwoDecimal,
  TextFieldWholeNumber,
} from "components/form/TextFieldWholeNumber";
import { FormikDealStatusChip } from "components/form_v2/deal/DealStatusChip";
import config from "config";
import {
  isFeatureEnabledOrgPreferences,
  isFieldRequiredOrgPreferences,
  isFieldVisibleOrgPreferences,
} from "contexts/DealOrgPreferencesContext";
import useAuth from "hooks/useAuth";
import KeyDatesArrayComponent from "pages/deal/components/form/KeyDatesArrayComponent";
import GoogleMapsAutocomplete from "pages/deal/components/GoogleMapsAutocomplete";
import {
  ContactLookupField,
  PropertyLookupField,
} from "pages/deal/components/LookupField";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { SelectStageAsync } from "pages/deal/components/SelectStageAsync";
import { SwitchField } from "pages/deal/components/SwitchField";
import { getUSStateOptions } from "pages/deal/constants/address";
import {
  dealStatusDisplay,
  SIZE_TYPE_OPTIONS,
  sizeTypeDisplay,
} from "pages/deal/constants/deal_enums";
import {
  dateSubmitHandler,
  dealTypeSelected,
  isClosed,
  isDealType,
  isDealViewPro,
  isDualAgency,
  isLost,
  isSharedDeal,
  keyDatesLoadHandler,
  keyDatesSubmitHandler,
  loadPercent,
  parseContactAsSimpleOption,
  parseDealOptionAsString,
  parsePropertyAsSimpleOption,
  retrieveId,
  simpleOptionSubmitHandler,
  submitCurrency,
  submitPercent,
  submitTwoDecimal,
  useIsStageProbabilityEnabled,
} from "pages/deal/utils/deal_form";
import { formatCurrency, formatWholeNumber } from "pages/deal/utils/reporting";
import { DealStatusEnum } from "types/api/deal/enum";
import { PropertyExtended } from "types/api/deal/property";
import { DealFeature, DealField, DealType, SizeType } from "types/deal";
import { emptyKeyDateUpdate } from "types/keyDate";
import { ObjectMetadata } from "types/standardForm";
import { dealService } from "utils/axios";
import { areFloatsEqual } from "utils/math";

export const NOTES_PLAINTEXT_SCHEMA = Yup.string()
  .max(25000, "Notes must be less than 25,000 characters.")
  .nullable();

const useInitialCommissionSplitValue = () => {
  const { user } = useAuth();
  return [
    {
      user: {
        key: user?.user_id,
        label: `${user?.first_name} ${user?.last_name}`,
      },
      broker_role: "executing_and_sourcing",
      estimate_percent: 1,
      estimate_amount: null,
      actual_percent: 1,
      actual_amount: null,
    },
  ];
};

export const DealMetadata: ObjectMetadata = {
  _address_search: {
    fieldName: "_address_search",
    displayName: "Address search",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.object().nullable(),
    component: GoogleMapsAutocomplete,
    virtual: true,
    additionalChangeHandler: (value, setFieldValue) => {
      if (_.get(value, "city")) {
        setFieldValue("address_line_1", _.get(value, "line_1"));
        setFieldValue("address_line_2", _.get(value, "line_2"));
        setFieldValue("address_city", _.get(value, "city"));
        setFieldValue("address_state", _.get(value, "state"));
        setFieldValue("address_postal_code", _.get(value, "postal_code"));
        setFieldValue("address_country", _.get(value, "country"));
        setFieldValue("address_latitude", _.get(value, "latitude"));
        setFieldValue("address_longitude", _.get(value, "longitude"));
      }
    },
  },
  commission_payments_percent_toggle: {
    fieldName: "commission_payments_percent_toggle",
    displayName: "Commission Payment Date Breakdown",
    initialValue: true,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle,
    visibility: (values) => isClosed(values),
  },
  commission_split_percent_toggle: {
    fieldName: "commission_split_percent_toggle",
    displayName: "Commission Split",
    initialValue: true,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle,
  },
  commission_percent_toggle: {
    fieldName: "commission_percent_toggle",
    displayName: "Commission",
    initialValue: false,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle,
  },
  _deal_category: {
    fieldName: "_deal_category",
    displayName: "",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: TextFieldString,
    virtual: true,
  },
  shared_deal: {
    fieldName: "shared_deal",
    displayName: "Is this client being shared with another broker?",
    initialValue: false,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
    _schema: Yup.boolean().nullable(),
    component: SwitchField,
    additionalChangeHandler: (value: any, setFieldValue: any) => {
      if (!value) {
        setFieldValue("shared_broker_company", null);
        setFieldValue("shared_broker", null);
      }
    },
  },
  is_dual_agency: {
    fieldName: "is_dual_agency",
    displayName: "Is there an Outside Broker on this deal?",
    initialValue: false,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.OUTSIDE_BROKER
      ),
    _schema: Yup.boolean().nullable(),
    component: SwitchField,
    additionalChangeHandler: (value: any, setFieldValue: any) => {
      if (!value) {
        setFieldValue("counterparty_broker_company", null);
        setFieldValue("outside_broker", null);
      }
    },
  },
  probability_override: {
    fieldName: "probability_override",
    displayName: "Probability",
    initialValue: null,
    _schema: Yup.number()
      .nullable()
      .positive("Probability must be greater than 0%.")
      .max(100, "Probability must not exceed 100%"),
    component: ProbabilityOverrideField,
    submitHandler: submitPercent,
    loadHandler: loadPercent,
    visibility: useIsStageProbabilityEnabled,
  },
  address_city: {
    fieldName: "address_city",
    displayName: "City",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString,
  },
  address_country: {
    fieldName: "address_country",
    displayName: "Country",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString,
  },
  address_latitude: {
    fieldName: "address_latitude",
    displayName: "Latitude",
    visibility: () => false,
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: TextFieldString,
  },
  address_line_1: {
    fieldName: "address_line_1",
    displayName: "Line 1",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .max(255)
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.ADDRESS
            ),
          then: (schema) => schema.required("Address is required."),
        }),
    component: TextFieldString,
  },
  address_line_2: {
    fieldName: "address_line_2",
    displayName: "Line 2",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString,
  },
  address_longitude: {
    fieldName: "address_longitude",
    displayName: "Longitude",
    visibility: () => false,
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: TextFieldString,
  },
  address_postal_code: {
    fieldName: "address_postal_code",
    displayName: "Zip",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    _schema: Yup.string().max(255).nullable(),
    component: TextFieldString,
  },
  address_state: {
    fieldName: "address_state",
    displayName: "State",
    initialValue: null,
    _schema: Yup.string().max(2).nullable(),
    component: SelectOption,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.ADDRESS),
    options: getUSStateOptions(),
  },
  asset_type: {
    fieldName: "asset_type",
    displayName: "Asset Type",
    initialValue: null,
    _schema: Yup.string().required(`Asset Type is required.`),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "asset_type_name",
    additionalChangeHandler: (
      value: string,
      setFieldValue,
      prevValue,
      values
    ) => {
      // If no values have been entered for Size (est or actual), autofill the Size Type field
      const textValue = value;
      if (
        _.isNil(_.get(values, "size_est")) &&
        _.isNil(_.get(values, "size"))
      ) {
        if (["Land"].includes(textValue)) {
          setFieldValue("size_type", SizeType.acres);
        } else if (
          ["Multifamily", "Senior / Assisted Living"].includes(textValue)
        ) {
          setFieldValue("size_type", SizeType.units);
        } else {
          setFieldValue("size_type", SizeType.square_feet);
        }
      }
    },
  },
  client_industry: {
    fieldName: "client_industry",
    displayName: "Client Industry",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.CLIENT_INDUSTRY
      ),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.CLIENT_INDUSTRY
            ),
          then: (schema) => schema.required("Client Industry is required."),
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "client_industry_name",
  },
  contact: {
    fieldName: "contact",
    displayName: "Primary Contact",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.CONTACT),
    _schema: (dealOrgPreferences) =>
      Yup.object()
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.CONTACT
            ),
          then: (schema) => schema.required("Primary Contact is required."),
        }),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: "contact_id",
  },
  outside_broker: {
    fieldName: "outside_broker",
    displayName: "Outside Broker Name",
    disabled: (values) => !isDualAgency(values),
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.OUTSIDE_BROKER
      ),
    _schema: Yup.object().nullable(),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: "outside_broker_id",
  },
  shared_broker: {
    fieldName: "shared_broker",
    displayName: "Shared Broker Name",
    disabled: (values) => !isSharedDeal(values),
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
    _schema: Yup.object().nullable(),
    component: ContactLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parseContactAsSimpleOption,
    fieldCorrespondence: "shared_broker_id",
  },
  close_date: {
    fieldName: "close_date",
    displayName: "Actual Close Date",
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Close Date must be a date.`)
      .when("status", {
        is: DealStatusEnum.closed,
        then: (schema) => schema.required("Close Date is required."),
      }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  close_date_est: {
    fieldName: "close_date_est",
    displayName: "Estimated Close Date",
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Estimated Close Date must be a date.`)
      .when("status", {
        is: (x: DealStatusEnum) => x !== DealStatusEnum.closed,
        then: (schema) => schema.required(`Estimated Close Date is required.`),
      }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  commission: {
    fieldName: "commission",
    displayName: "Actual Commission",
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Commission must be positive.")
      .nullable()
      .typeError(`A value must be entered for Commission.`)
      .when("status", {
        is: DealStatusEnum.closed,
        then: (schema) => schema.required("Commission is required."),
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency,
  },
  commission_est: {
    fieldName: "commission_est",
    displayName: "Estimated Commission",
    initialValue: null,
    _schema: Yup.number()
      .nullable()
      .min(0, "Estimated Commission must be positive.")
      .typeError(`A number is expected for Estimated Commission.`)
      .when("status", {
        is: (x: DealStatusEnum) => x !== DealStatusEnum.closed,
        then: (schema) => schema.required(`Estimated Commission is required.`),
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency,
  },
  _commission_est_percent_toggle: {
    fieldName: "_commission_est_percent_toggle",
    displayName: "",
    initialValue: false,
    _schema: Yup.boolean().nullable(),
    component: DollarPercentToggle,
    virtual: true,
  },
  counterparty_broker_company: {
    fieldName: "counterparty_broker_company",
    displayName: "Outside Brokerage Company",
    disabled: (values) => !isDualAgency(values),
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.OUTSIDE_BROKER
      ),
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "counterparty_broker_company_name",
  },
  deal_type: {
    fieldName: "deal_type",
    displayName: "Deal Type",
    initialValue: null,
    _schema: Yup.string().required(`Deal Type is required.`),
    component: DealTypeSelectOption,
  },
  hire_date: {
    fieldName: "hire_date",
    displayName: "Hire Date",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.HIRE_DATE),
    _schema: (dealOrgPreferences) =>
      Yup.date()
        .nullable()
        .typeError(`Hire Date must be a date.`)
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.HIRE_DATE
            ),
          then: (schema) => schema.required("Hire Date is required."),
        }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  key_dates: {
    fieldName: "key_dates",
    fieldCorrespondence: "key_dates_input",
    displayName: "Key Dates",
    initialValue: () => [emptyKeyDateUpdate],
    _schema: Yup.array().of(
      Yup.object().shape(
        {
          id: Yup.number().nullable(),
          is_delete: Yup.boolean().nullable(),
          date_type: Yup.string()
            .nullable()
            .when(["date", "is_delete"], {
              is: (date: any, is_delete: boolean) => !is_delete && !!date,
              then: (schema) => schema.required("Date Type is required."),
              otherwise: (schema) => schema,
            }),
          date: Yup.date()
            .nullable()
            .typeError("Date is required.")
            .when(["date_type", "is_delete"], {
              is: (date_type: any, is_delete: boolean) =>
                !is_delete && !!date_type,
              then: (schema) => schema.required("Date is required."),
              otherwise: (schema) => schema,
            }),
        },
        [["date_type", "date"]]
      )
    ),
    component: KeyDatesArrayComponent,
    submitHandler: keyDatesSubmitHandler,
    loadHandler: keyDatesLoadHandler,
  },
  lease_expiration: {
    fieldName: "lease_expiration",
    displayName: "Lease Expiration",
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [
        DealType.landlord_agency,
        DealType.tenant_representation,
        DealType.lease_both,
      ]) &&
      isClosed(values) &&
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.LEASE_EXPIRATION
      ),
    initialValue: null,
    _schema: (dealOrgPreferences) =>
      Yup.date()
        .nullable()
        .typeError(`Lease Expiration must be a date.`)
        .when(["status", "deal_type"], {
          is: (status: DealStatusEnum, dealType: DealType) =>
            [
              DealType.landlord_agency,
              DealType.tenant_representation,
              DealType.lease_both,
            ].includes(dealType) &&
            status === DealStatusEnum.closed &&
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.LEASE_EXPIRATION
            ),
          then: (schema) => schema.required("Lease Expiration is required."),
        }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    disabled: (values) => !isClosed(values),
    formatForDisplay: formatDateForDisplay,
  },
  lost_date: {
    fieldName: "lost_date",
    displayName: "Lost Date",
    disabled: (values) => !isLost(values),
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Lost Date must be a date.`)
      .when("status", {
        is: DealStatusEnum.lost,
        then: (schema) => schema.required("Lost Date is required."),
      }),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  lost_reason: {
    fieldName: "lost_reason",
    displayName: "Lost Reason",
    disabled: (values) => !isLost(values),
    initialValue: null,
    _schema: Yup.string()
      .nullable()
      .when("status", {
        is: DealStatusEnum.lost,
        then: (schema) => schema.required("Lost Reason is required."),
      }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "lost_reason_name",
  },
  move_in_date: {
    fieldName: "move_in_date",
    displayName: "Actual Move In Date",
    disabled: (values) => !isClosed(values),
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.landlord_agency, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.MOVE_IN_DATE),
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Move In Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  move_in_date_est: {
    fieldName: "move_in_date_est",
    displayName: "Estimated Move In Date",
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [DealType.landlord_agency, DealType.lease_both]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.MOVE_IN_DATE),
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Estimated Move In Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  name: {
    fieldName: "name",
    displayName: "Deal Name",
    initialValue: null,
    _schema: Yup.string()
      .max(255, "Deal Name must be less than 256 characters.")
      .required(`Deal Name is required.`),
    component: TextFieldString,
    componentProps: {
      tooltip: "An example of Deal Name is Tenant A - Main Street - Suite B",
    },
  },
  notes_plaintext: {
    fieldName: "notes_plaintext",
    displayName: "",
    initialValue: "",
    _schema: NOTES_PLAINTEXT_SCHEMA,
    component: TextFieldStringMultiline,
  },
  property_record: {
    fieldName: "property_record",
    displayName: "Property",
    visibility: (values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.PROPERTY_NAME),
    initialValue: null,
    _schema: (dealOrgPreferences) =>
      Yup.object()
        .nullable()
        .when("deal_type", {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.PROPERTY_NAME
            ),
          then: (schema) => schema.required("Property Name is required."),
        }),
    component: PropertyLookupField,
    submitHandler: simpleOptionSubmitHandler,
    loadHandler: parsePropertyAsSimpleOption,
    formatForDisplay: formatSimpleOptionForDisplay,
    additionalChangeHandler: async (value, setFieldValue, prev, values) => {
      // Drop out early if no value
      if (!value || !value.key) return;

      try {
        const { data: property } = await dealService.get<PropertyExtended>(
          `/property/${value.key}`
        );

        if (!property) return;

        // Define fields to check and set dynamically
        const propertyFieldValues: Record<string, any> = {
          submarket: parseDealOptionAsString(property.submarket),
          asset_type: parseDealOptionAsString(property.asset_type),
          address_line_1: property.address_line_1,
          address_line_2: property.address_line_2,
          address_city: property.address_city,
          address_state: property.address_state,
          address_postal_code: property.address_postal_code,
          address_country: property.address_country,
        };

        // Set only if values[field] is empty
        Object.entries(propertyFieldValues).forEach(
          ([formField, fieldValue]) => {
            if (!get(values, formField)) {
              setFieldValue(formField, fieldValue);
            }
          }
        );
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    },
    fieldCorrespondence: "property_id",
  },
  relocation_date: {
    fieldName: "relocation_date",
    displayName: "Actual Relocation Date",
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [
        DealType.tenant_representation,
        DealType.lease_both,
      ]) &&
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.RELOCATION_DATE
      ),
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.date().nullable().typeError(`Relocation Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  relocation_date_est: {
    fieldName: "relocation_date_est",
    displayName: "Estimated Relocation Date",
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [
        DealType.tenant_representation,
        DealType.lease_both,
      ]) &&
      isFieldVisibleOrgPreferences(
        dealOrgPreferences,
        DealField.RELOCATION_DATE
      ),
    initialValue: null,
    _schema: Yup.date()
      .nullable()
      .typeError(`Estimated Relocation Date must be a date.`),
    component: DatePicker,
    submitHandler: dateSubmitHandler,
    formatForDisplay: formatDateForDisplay,
  },
  shared_broker_company: {
    fieldName: "shared_broker_company",
    displayName: "Shared Brokerage Company",
    disabled: (values) => !isSharedDeal(values),
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SHARED_BROKER),
    _schema: Yup.string().nullable(),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "shared_broker_company_name",
  },
  size: {
    fieldName: "size",
    displayName: "Actual Size",
    disabled: (values) => !isClosed(values),
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
    _schema: (dealOrgPreferences) =>
      Yup.number()
        .nullable()
        .min(0, "Actual Size must be positive.")
        .when("status", {
          is: (x: DealStatusEnum) =>
            x === DealStatusEnum.closed &&
            isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
          then: (schema) =>
            schema
              .typeError("Actual Size must be a number.")
              .required(`Actual Size must be a number.`),
        }),
    component: TextFieldTwoDecimal,
    submitHandler: submitTwoDecimal,
    formatForDisplay: formatWholeNumber,
  },
  size_est: {
    fieldName: "size_est",
    displayName: "Estimated Size",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
    _schema: (dealOrgPreferences) =>
      Yup.number()
        .nullable()
        .min(0, "Estimated Size must be positive.")
        .when("status", {
          is: (x: DealStatusEnum) =>
            x === DealStatusEnum.open &&
            isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
          then: (schema) =>
            schema
              .typeError("Estimated Size must be a number.")
              .required(`Estimated Size must be a number.`),
        }),
    component: TextFieldTwoDecimal,
    submitHandler: submitTwoDecimal,
    formatForDisplay: formatWholeNumber,
  },
  size_type: {
    fieldName: "size_type",
    displayName: "",
    options: SIZE_TYPE_OPTIONS,
    initialValue: SizeType.square_feet,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SIZE),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.SIZE),
          then: (schema) =>
            schema.required("Choose a measurement type for the property size."),
        }),
    component: SelectOption,
    formatForDisplay: (x) => _.get(sizeTypeDisplay, x, ""),
  },
  source_type: {
    fieldName: "source_type",
    displayName: "Deal Source",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SOURCE_TYPE),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.SOURCE_TYPE
            ),
          then: (schema) => schema.required("Deal Source is required."),
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "source_type_name",
  },
  square_feet: {
    fieldName: "square_feet",
    displayName: "Square Feet",
    disabled: (values) => !isClosed(values),
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Square Feet must be positive.")
      .nullable()
      .typeError(`Square Feet must be a number.`),
    component: TextFieldWholeNumber,
  },
  square_feet_est: {
    fieldName: "square_feet_est",
    displayName: "Estimated Square Feet",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Estimated Square Feet must be positive.")
      .nullable()
      .typeError(`Estimated Square Feet must be a number.`),
    component: TextFieldWholeNumber,
  },
  stage: {
    fieldName: "stage",
    displayName: "Stage",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.STAGE),
    _schema: (dealOrgPreferences) =>
      Yup.object()
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(dealOrgPreferences, DealField.STAGE),
          then: (schema) => schema.required("Stage is required."),
        }),
    component: SelectStageAsync,
    submitHandler: retrieveId,
    fieldCorrespondence: "stage_id",
    disabled: (values) => !dealTypeSelected(values),
  },
  status: {
    fieldName: "status",
    displayName: "Status",
    options: _.map(dealStatusDisplay, (val, idx) => ({ key: idx, label: val })),
    initialValue: DealStatusEnum.open,
    _schema: Yup.string().required(`Status is required.`).nullable(),
    component: FormikDealStatusChip,
  },
  submarket: {
    fieldName: "submarket",
    displayName: "Submarket",
    initialValue: null,
    visibility: (_values, dealOrgPreferences) =>
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.SUBMARKET),
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when([], {
          is: () =>
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.SUBMARKET
            ),
          then: (schema) => schema.required("Submarket is required."),
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "submarket_name",
  },
  tenant_name: {
    fieldName: "tenant_name",
    displayName: "Tenant Name",
    visibility: (values, dealOrgPreferences) =>
      isDealType(values, [
        DealType.landlord_agency,
        DealType.tenant_representation,
        DealType.lease_both,
      ]) &&
      isFieldVisibleOrgPreferences(dealOrgPreferences, DealField.TENANT_NAME),
    initialValue: null,
    _schema: (dealOrgPreferences) =>
      Yup.string()
        .nullable()
        .when("deal_type", {
          is: (x: DealType) =>
            x === DealType.tenant_representation &&
            isDealViewPro() &&
            isFieldRequiredOrgPreferences(
              dealOrgPreferences,
              DealField.TENANT_NAME
            ),
          then: (schema) => schema.required("Tenant Name is required."),
        }),
    component: DealOptionAutocompleteNew,
    loadHandler: parseDealOptionAsString,
    fieldCorrespondence: "tenant_name_name",
  },
  transaction_value: {
    fieldName: "transaction_value",
    displayName: "Actual Transaction Value",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Actual Transaction Value must be positive.")
      .nullable()
      .typeError(`Actual Transaction Value must be a number.`)
      .when(["status"], {
        is: (status: DealStatusEnum) => status === DealStatusEnum.closed,
        then: (schema) =>
          schema.required("Actual Transaction Value is required."),
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency,
  },
  transaction_value_est: {
    fieldName: "transaction_value_est",
    displayName: "Estimated Transaction Value",
    initialValue: null,
    _schema: Yup.number()
      .min(0, "Estimated Transaction Value must be positive.")
      .nullable()
      .typeError(`Estimated Transaction Value must be a number.`)
      .when(["status"], {
        is: (status: DealStatusEnum) => status !== DealStatusEnum.closed,
        then: (schema) =>
          schema.required("Estimated Transaction Value is required."),
      }),
    component: TextFieldCurrency,
    submitHandler: submitCurrency,
    formatForDisplay: formatCurrency,
  },
  commission_split: {
    fieldName: "commission_split",
    displayName: "Commission Split",
    visibility: (_values, dealOrgPreferences) =>
      isFeatureEnabledOrgPreferences(
        dealOrgPreferences,
        DealFeature.COMMISSION_SPLITS
      ),
    component: CommissionSplitSection,
    initialValue: useInitialCommissionSplitValue,
    _schema: Yup.array()
      .of(
        Yup.object().shape({
          user: Yup.object().required("Broker name must be provided."),
          broker_role: Yup.string().nullable(),
          estimate_percent: Yup.number()
            .nullable()
            .when(["status"], {
              is: (status: DealStatusEnum) => status !== DealStatusEnum.closed,
              then: (schema) =>
                schema
                  .min(0, "Estimated Split must be positive.")
                  .max(100, "Estimated Split must be less than 100%."),
            }),
          estimate_amount: Yup.number().nullable(),
          actual_percent: Yup.number()
            .nullable()
            .when(["status"], {
              is: (status: DealStatusEnum) => status === DealStatusEnum.closed,
              then: (schema) =>
                schema
                  .min(0, "Actual Split must be positive.")
                  .max(100, "Actual Split must be less than 100%."),
            }),
          actual_amount: Yup.number().nullable(),
        })
      )
      .test(
        "estimate-split-total-100",
        "estimate-split-total-100",
        (items, testContext) => {
          const isPercent = get(
            testContext,
            "parent.commission_split_percent_toggle",
            false
          );
          const commission_est = get(
            testContext,
            "parent.commission_est",
            null
          );

          if (isPercent) {
            const total = sumBy(items, (item) => item?.estimate_percent || 0);
            return areFloatsEqual(total, 1);
          } else {
            const total = sumBy(items, (item) => item?.estimate_amount || 0);
            return areFloatsEqual(total, commission_est);
          }
        }
      )
      .test(
        "actual-split-total-100",
        "actual-split-total-100",
        (items, testContext) => {
          // Only enforce this test if the status is Closed
          const isClosed =
            get(testContext, "parent.status", DealStatusEnum.open) ===
            DealStatusEnum.closed;
          if (!isClosed) return true;

          const isPercent = get(
            testContext,
            "parent.commission_split_percent_toggle",
            false
          );
          const commission = get(testContext, "parent.commission", null);

          if (isPercent) {
            const total = sumBy(items, (item) => item?.actual_percent || 0);
            return areFloatsEqual(total, 1);
          } else {
            const total = sumBy(items, (item) => item?.actual_amount || 0);
            return areFloatsEqual(total, commission);
          }
        }
      ),
    loadHandler: (x, userDisplay: Record<string, string>) =>
      map(x, (y) => ({
        ...y,
        user: isNil(y.user_id)
          ? null
          : {
              key: y.user_id,
              label: get(userDisplay, y.user_id, "Unknown"),
            },
      })),
    submitHandler: (x) =>
      map(x, (y) => ({
        ...y,
        user_id: y.user.key,
        estimate_amount: submitCurrency(y.estimate_amount),
        actual_amount: submitCurrency(y.actual_amount),
      })),
  },
  commission_payments: {
    fieldName: "commission_payments",
    displayName: "Commission Payments",
    initialValue: [
      { amount: null, percent: null, date: null, paid: false },
      { amount: null, percent: null, date: null, paid: false },
    ],
    _schema: (dealOrgPreferences) =>
      Yup.array().when(["status"], {
        is: (status: DealStatusEnum) =>
          status === DealStatusEnum.closed &&
          isFeatureEnabledOrgPreferences(
            dealOrgPreferences,
            DealFeature.COMMISSION_PAYMENTS
          ),
        then: (schema) =>
          schema
            .of(
              Yup.object().shape({
                amount: Yup.number()
                  .nullable()
                  .min(0, "Amount must be positive."),
                date: Yup.date()
                  .nullable()
                  .when(["amount", "percent"], {
                    is: (amount: number | null, percent: number | null) =>
                      (!_.isNil(amount) && amount > 0) ||
                      (!_.isNil(percent) && percent > 0),
                    then: (schema) =>
                      schema.required(
                        "Date Due is required when Amount Due is provided."
                      ),
                  }),
                paid: Yup.boolean().nullable(),
                percent: Yup.number()
                  .nullable()
                  .min(0, "Percent must be at least 0%.")
                  .max(1, "Percent must be less than 100%."),
              })
            )
            .test(
              "first-item-amount-required",
              "First Amount Due is required",
              function (value, testContext) {
                const isPercent = _.get(
                  testContext,
                  "parent.commission_payments_percent_toggle",
                  false
                );
                if (value && value.length > 0) {
                  if (isPercent) {
                    return (
                      value[0].percent !== undefined &&
                      value[0].percent !== null
                    );
                  } else {
                    return (
                      value[0].amount !== undefined && value[0].amount !== null
                    );
                  }
                }
                return true;
              }
            )
            .test(
              "amount-total-100",
              "amount-total-100",
              (items, testContext) => {
                // Only enforce this test if the field is set to Amount
                const isPercent = _.get(
                  testContext,
                  "parent.commission_payments_percent_toggle",
                  false
                );
                const commission = _.get(
                  testContext,
                  "parent.commission",
                  null
                );

                if (isPercent) {
                  const total = _.sumBy(items, (item) => item?.percent || 0);
                  return areFloatsEqual(total, 1);
                } else {
                  const total = _.sumBy(items, (item) => item?.amount || 0);
                  return areFloatsEqual(total, commission);
                }
              }
            ),
      }),
    component: CommissionPaymentSection,
    visibility: (values, dealOrgPreferences) =>
      isClosed(values) &&
      isFeatureEnabledOrgPreferences(
        dealOrgPreferences,
        DealFeature.COMMISSION_PAYMENTS
      ),
    submitHandler: (value) =>
      _.map(value, (x) => ({
        ...x,
        amount: submitCurrency(x.amount),
        date: dateSubmitHandler(x.date),
      })),
  },
  break: {
    fieldName: "Break",
    displayName: "Break",
    initialValue: null,
    _schema: Yup.object().nullable(),
    component: () => <Box sx={{ width: "100%" }}></Box>,
    virtual: true,
  },
  stage_stepper: {
    fieldName: "stage_stepper",
    displayName: "",
    initialValue: null,
    _schema: Yup.number().nullable(),
    component: StageStepper,
    virtual: true,
  },
  created_at: {
    fieldName: "created_at",
    displayName: "Date Created",
    initialValue: null,
    _schema: Yup.date().nullable(),
    component: DataDateTimeDisplay,
    virtual: true,
  },
  updated_at: {
    fieldName: "updated_at",
    displayName: "Date Modified",
    initialValue: null,
    _schema: Yup.date().nullable(),
    component: DataDateTimeDisplay,
    virtual: true,
  },
  created_by: {
    fieldName: "created_by",
    displayName: "Created By",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DataUserDisplay,
    virtual: true,
  },
  updated_by: {
    fieldName: "updated_by",
    displayName: "Modified By",
    initialValue: null,
    _schema: Yup.string().nullable(),
    component: DataUserDisplay,
    virtual: true,
  },
};
