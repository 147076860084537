import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import contactNavigationReducer from "store/reducers/contactNavigation";
import dealNavigationReducer from "store/reducers/dealNavigation";
import invoiceNavigationReducer from "store/reducers/invoiceNavigation";
import ledgerTransactionNavigationReducer from "store/reducers/ledgerTransactionNavigation";
import propertyNavigationReducer from "store/reducers/propertyNavigation";
import reportNavigationReducer from "store/reducers/reportNavigation";
import voucherNavigationReducer from "store/reducers/voucherNavigation";

import calendar from "./calendar";
import dealOptionV2Reducer from "./dealOptionV2";
import dealReducerV2 from "./dealV2";
import dealWidgetsConfigReducer from "./dealWidgetsConfig";
import menu from "./menu";
import onboardingDataReducer from "./onboardingData";
import recordReducer from "./record";
import registrationDataReducer from "./registrationData";
import tableViewReducer from "./tableView";

// ==============================|| COMBINE REDUCERS ||============================== //
const DEFAULT_PERSIST_PREFIX = "cre-onesource-";

const reducers = combineReducers({
  calendar,
  menu,
  record: recordReducer,
  tableView: tableViewReducer,
  dealV2: dealReducerV2,
  dealOptionV2: dealOptionV2Reducer,
  dealNavigation: dealNavigationReducer,
  contactNavigation: contactNavigationReducer,
  propertyNavigation: propertyNavigationReducer,
  invoiceNavigation: invoiceNavigationReducer,
  ledgerTransactionNavigation: ledgerTransactionNavigationReducer,
  voucherNavigation: voucherNavigationReducer,
  reportNavigation: reportNavigationReducer,
  dealWidgetsConfig: persistReducer(
    {
      key: "deal-widgets-config",
      storage,
      keyPrefix: DEFAULT_PERSIST_PREFIX,
    },
    dealWidgetsConfigReducer
  ),
  registrationData: persistReducer(
    {
      key: "registration-data",
      storage,
      keyPrefix: DEFAULT_PERSIST_PREFIX,
      blacklist: ["password"],
    },
    registrationDataReducer
  ),
  onboardingData: persistReducer(
    {
      key: "onboarding-data-v2",
      storage,
      keyPrefix: DEFAULT_PERSIST_PREFIX,
    },
    onboardingDataReducer
  ),
});

export default reducers;
