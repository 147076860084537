import * as Yup from "yup";

import { dateSubmitHandler, submitCurrency } from "pages/deal/utils/deal_form";
import { CustomDataTypeEnum } from "types/api/deal/dynamic_form/form_field";
import {
  EditableFormFieldMetadata,
  EditableFormObjectMetadata,
} from "types/formMetadata";

export const getFieldMetadataFromDataType = (
  dataType: CustomDataTypeEnum,
  fieldName: string,
  displayName: string
): EditableFormFieldMetadata => {
  const map: EditableFormObjectMetadata = {
    text: {
      fieldName: fieldName,
      displayName: displayName,
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: "TextFieldString",
      detail_component: "TextFieldDetail",
    },
    multiline_text: {
      fieldName: fieldName,
      displayName: displayName,
      _schema: Yup.string().nullable(),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: "",
      component: "TextFieldStringMultiline",
      detail_component: "TextFieldDetail",
    },
    whole_number: {
      fieldName: fieldName,
      displayName: displayName,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: "TextFieldWholeNumber",
      detail_component: "TextFieldDetail",
    },
    whole_number_unformatted: {
      fieldName: fieldName,
      displayName: displayName,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: "TextFieldWholeNumberUnformatted",
      detail_component: "TextFieldDetail",
    },
    decimal_number: {
      fieldName: fieldName,
      displayName: displayName,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      loadHandler: (x: any) => x,
      submitHandler: (x: any) => x,
      initialValue: null,
      component: "TextFieldDecimalNumber",
      detail_component: "TextFieldDetail",
    },
    percent_number: {
      fieldName: fieldName,
      displayName: displayName,
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .max(1, `${displayName} cannot be greater than 100%.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: "TextFieldPercent",
      detail_component: "TextFieldDetail",
    },
    currency: {
      fieldName: fieldName,
      displayName: displayName,
      initialValue: null,
      _schema: Yup.number()
        .min(0, `${displayName} must be positive.`)
        .nullable()
        .typeError(`${displayName} must be a number.`),
      component: "TextFieldCurrency",
      submitHandler: submitCurrency,
      detail_component: "CurrencyFieldDetail",
    },
    date: {
      fieldName: fieldName,
      displayName: displayName,
      initialValue: null,
      _schema: Yup.date()
        .nullable()
        .typeError(`${displayName} must be a date.`),
      component: "DatePicker",
      submitHandler: dateSubmitHandler,
      detail_component: "DateFieldDetail",
    },
    boolean: {
      fieldName: fieldName,
      displayName: displayName,
      initialValue: false,
      _schema: Yup.boolean().nullable(),
      component: "CheckboxField",
      detail_component: "TextFieldDetail",
    },
  };

  return map[dataType] as EditableFormFieldMetadata;
};
