import { Stack, Typography, useTheme } from "@mui/material";

interface ReadOnlyFormSectionProps {
  label: string;
}

export function ReadOnlyFormSection({ label }: ReadOnlyFormSectionProps) {
  return (
    <Stack
      alignItems="center"
      justifyContent={"center"}
      style={{ width: "100%", height: "120px" }}
    >
      <Typography variant={"h5"}>{label}</Typography>
    </Stack>
  );
}
