import { useQuery } from "react-query";

import {
  LedgerTransactionRead,
  LedgerTransactionReadList,
} from "types/api/deal/ledger_transaction";
import { coreService } from "utils/axios";

export const getLedgerTransactionQueryKey = (ledger_transaction_id: string) => [
  "ledger_transaction",
  ledger_transaction_id,
];

export const useLedgerTransactionQuery = (ledger_transaction_id: string) => {
  const query = useQuery<LedgerTransactionRead>({
    queryKey: getLedgerTransactionQueryKey(ledger_transaction_id),
    queryFn: () =>
      coreService
        .get(`/ledger_transaction/${ledger_transaction_id}`)
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};

export const getLedgerTransactionsQueryKey = (queryParams?: URLSearchParams) =>
  queryParams
    ? ["ledger_transaction_table", queryParams.toString()]
    : ["ledger_transaction_table"];

export const useLedgerTransactionsQuery = (queryParams: URLSearchParams) => {
  const query = useQuery<LedgerTransactionReadList>({
    queryKey: getLedgerTransactionsQueryKey(queryParams),
    queryFn: () =>
      coreService
        .get(`/ledger_transaction`, { params: queryParams })
        .then((response) => {
          return response.data;
        }),
  });

  return query;
};
