import { Box, InputLabel, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const FieldDetail = ({
  label,
  displayValue,
}: {
  label: string;
  displayValue?: string;
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%", overflow: "visible" }}>
      <Stack spacing={theme.spacing(0.5)}>
        <InputLabel>{label}</InputLabel>
        <Typography variant={"subtitle1"}>{displayValue ?? "--"}</Typography>
      </Stack>
    </Box>
  );
};
