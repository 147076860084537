import {
  Box,
  Chip,
  Link,
  Stack,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import MainCard from "components/MainCard";
import { EmptyTable } from "components/third-party/ReactTable";
import { usePushForm } from "contexts/FormDialogsContext";
import { LoadingOverlay } from "pages/deal/sections/charts/ChartOverlay";
import { usePropertyRecordsQuery } from "queries/usePropertyRecord";
import { ContactRead } from "types/api/deal/contact";
import { PropertyExtended } from "types/api/deal/property";
import { formatAddressForDisplay } from "utils/address";

interface PropertyPreviewPaneProps {
  contactRecord: ContactRead;
}

function PropertyCard(props: { property: PropertyExtended }) {
  const pushForm = usePushForm();
  const { property } = props;

  return (
    <MainCard>
      <Stack spacing={0.5}>
        <Link
          sx={{ cursor: "pointer", fontWeight: 600 }}
          onClick={() =>
            pushForm({ identifier: "PropertyForm", id: `${property.id}` })
          }
        >
          {property.name}
        </Link>

        {/* Render asset_type and submarket as chips if they exist */}
        {(property.asset_type?.value || property.submarket?.value) && (
          <Stack direction="row" spacing={1}>
            {property.asset_type?.value && (
              <Chip label={property.asset_type.value} size="small" />
            )}
            {property.submarket?.value && (
              <Chip label={property.submarket.value} size="small" />
            )}
          </Stack>
        )}

        <Typography variant="body1">
          {formatAddressForDisplay(property)}
        </Typography>
      </Stack>
    </MainCard>
  );
}

const PropertyPreviewPane = ({ contactRecord }: PropertyPreviewPaneProps) => {
  const pushForm = usePushForm();
  const [showMore, setShowMore] = useState(false);

  const queryParams = useMemo(
    () =>
      new URLSearchParams({
        property_owner_id: `${contactRecord.id}`,
        limit: "100",
      }),
    [contactRecord.id]
  );

  const { data: propertyOverviewData = { data: [], stats: {} }, isLoading } =
    usePropertyRecordsQuery(queryParams);

  const numProperties = useMemo(
    () => _.size(propertyOverviewData.data),
    [propertyOverviewData.data]
  );

  // Determine which properties to render based on showMore
  const propertiesToDisplay = useMemo(() => {
    if (!propertyOverviewData.data) return [];
    return showMore
      ? propertyOverviewData.data
      : propertyOverviewData.data.slice(0, 1);
  }, [propertyOverviewData.data, showMore]);

  return (
    <Box>
      <LoadingOverlay showChart={!isLoading} height={"100%"} width={"100%"}>
        <MainCard
          title={`Properties They Own (${numProperties})`}
          secondary={
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                const changes = {
                  property_owner_id: contactRecord.id,
                  property_owner: {
                    key: contactRecord.id,
                    label: contactRecord.full_name,
                  },
                };
                pushForm({ identifier: "PropertyForm", changes });
              }}
            >
              + Add
            </Link>
          }
        >
          {!!propertyOverviewData.data && propertyOverviewData.data.length ? (
            <Stack sx={{ width: 1 }} spacing={1.5}>
              {_.map(propertiesToDisplay, (property) => (
                <PropertyCard key={property.id} property={property} />
              ))}

              {/* Show the "Show more" text button only if there's more than one property and we haven't toggled it yet */}
              {propertyOverviewData.data.length > 1 && !showMore && (
                <span>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => setShowMore(true)}
                  >
                    Show More
                  </Link>
                </span>
              )}
            </Stack>
          ) : (
            <Table>
              <TableBody>
                <EmptyTable
                  msg={"No associated Properties found."}
                  colSpan={10}
                />
              </TableBody>
            </Table>
          )}
        </MainCard>
      </LoadingOverlay>
    </Box>
  );
};

export default PropertyPreviewPane;
