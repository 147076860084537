import { PlusOutlined } from "@ant-design/icons";
import { Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { FieldArray, FormikProps, useField, useFormikContext } from "formik";
import { find, get, sumBy } from "lodash";
import React, { useMemo, useState } from "react";

import { SelectOption } from "components/form/SelectOption";
import { formatDateForDisplay } from "components/form/standard/utils/formatting";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldPercent } from "components/form/TextFieldPercent";
import { TextFieldWholeNumber } from "components/form/TextFieldWholeNumber";
import { DEAL_TYPE_OPTIONS } from "pages/deal/constants/deal_enums";
import {
  formatCurrency,
  formatPercentageWithDecimal,
  formatWholeNumber,
} from "pages/deal/utils/reporting";
import { LookupItem } from "types/api/deal/form";
import {
  DetailTypeEnum,
  TransactionDetailRead,
} from "types/api/deal/transaction_detail";

export const DETAIL_TYPE_ITEMS_MAP = {
  [DetailTypeEnum.RENT]: "Rent",
  [DetailTypeEnum.FREE_RENT]: "Free Rent",
  [DetailTypeEnum.EXPENSE]: "Expense",
  [DetailTypeEnum.SALE]: "Sale",
  [DetailTypeEnum.FEE]: "Fee",
  [DetailTypeEnum.OTHER]: "Other",
};

const DETAIL_TYPE_ITEMS_LEASE: LookupItem[] = [
  { key: DetailTypeEnum.RENT, label: "Rent" },
  { key: DetailTypeEnum.FREE_RENT, label: "Free Rent" },
  { key: DetailTypeEnum.EXPENSE, label: "Expense" },
  { key: DetailTypeEnum.OTHER, label: "Other" },
];

const DETAIL_TYPE_ITEMS_SALE: LookupItem[] = [
  { key: DetailTypeEnum.SALE, label: "Sale" },
  { key: DetailTypeEnum.OTHER, label: "Other" },
];

const DETAIL_TYPE_ITEMS_OTHER: LookupItem[] = [
  { key: DetailTypeEnum.FEE, label: "Fee" },
  { key: DetailTypeEnum.OTHER, label: "Other" },
];

/**
 * Calculates the end date of a lease based on the start date and duration in months.
 *
 * @param startDateStr - The lease start date in 'YYYY-MM-DD' format
 * @param months - The duration of the lease in months
 * @returns The lease end date in 'YYYY-MM-DD' format
 */
function calculateLeaseEndDate(startDateStr: string, months: number): string {
  // Parse the date parts from the string to avoid timezone issues
  const [yearStr, monthStr, dayStr] = startDateStr.split("-");
  const year = parseInt(yearStr, 10);
  const month = parseInt(monthStr, 10) - 1; // JS months are 0-indexed
  const day = parseInt(dayStr, 10);

  // Create a new date for the end date
  const endDate = new Date(Date.UTC(year, month, day));

  // Add the specified number of months
  endDate.setUTCMonth(endDate.getUTCMonth() + months);

  // Set the date to one day before the same day of the month
  endDate.setUTCDate(endDate.getUTCDate() - 1);

  // Format the end date as YYYY-MM-DD
  const endYear = endDate.getUTCFullYear();
  const endMonth = String(endDate.getUTCMonth() + 1).padStart(2, "0");
  const endDay = String(endDate.getUTCDate()).padStart(2, "0");

  return `${endYear}-${endMonth}-${endDay}`;
}
/**
 * Calculates the whole number of months between two dates,
 * rounding up any partial month.
 *
 * @param startDateStr - The start date in 'YYYY-MM-DD' format
 * @param endDateStr - The end date in 'YYYY-MM-DD' format
 * @returns The number of months between the two dates, rounded up.
 */
function calculateRoundedMonthsBetween(
  startDateStr: string,
  endDateStr: string
): number {
  // Parse the start and end dates (adjusting month index for JS Date)
  const [startYear, startMonth, startDay] = startDateStr.split("-").map(Number);
  const [endYear, endMonth, endDay] = endDateStr.split("-").map(Number);

  const startDate = new Date(Date.UTC(startYear, startMonth - 1, startDay));
  const endDate = new Date(Date.UTC(endYear, endMonth - 1, endDay));

  // Compute the number of full months between the dates.
  // This formula calculates the naive difference in months and then subtracts one
  // if the end day is earlier than the start day (i.e. the last month is not fully complete).
  let fullMonths =
    (endDate.getUTCFullYear() - startDate.getUTCFullYear()) * 12 +
    (endDate.getUTCMonth() - startDate.getUTCMonth());
  if (endDate.getUTCDate() < startDate.getUTCDate()) {
    fullMonths--;
  }

  // If the end date does not fall exactly on the same day-of-month as the start date,
  // then there is a partial month which should be counted.
  if (endDate.getUTCDate() !== startDate.getUTCDate()) {
    fullMonths++;
  }

  return fullMonths;
}

const recalcRow = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"],
  recalcFields: (keyof TransactionDetailRead)[]
) => {
  const detail = getFieldProps(`transaction_details[${index}]`).value;

  let quantity = detail.quantity ?? 0;
  let startDate = detail.date_start;
  let endDate = detail.date_end;
  let totalPerQuantity = detail.total_per_quantity ?? 0;
  let totalAmount = detail.total_amount ?? 0;
  let commissionPercent = detail.commission_percent ?? 0;
  let commissionAmount = detail.commission_amount ?? 0;

  if (recalcFields.includes("quantity") && startDate && endDate) {
    quantity = calculateRoundedMonthsBetween(startDate, endDate);
    await setFieldValue(`transaction_details[${index}].quantity`, quantity);
  }

  if (recalcFields.includes("date_end") && startDate && quantity) {
    endDate = calculateLeaseEndDate(startDate, quantity);
    await setFieldValue(`transaction_details[${index}].date_end`, endDate);
  }

  if (recalcFields.includes("total_per_quantity") && quantity) {
    totalPerQuantity = totalAmount / quantity;
    await setFieldValue(
      `transaction_details[${index}].total_per_quantity`,
      totalPerQuantity
    );
  }

  if (recalcFields.includes("total_amount")) {
    totalAmount = quantity * totalPerQuantity;
    await setFieldValue(
      `transaction_details[${index}].total_amount`,
      totalAmount
    );
  }

  if (recalcFields.includes("commission_percent") && totalAmount) {
    commissionPercent = commissionAmount / totalAmount;
    await setFieldValue(
      `transaction_details[${index}].commission_percent`,
      commissionPercent
    );
  }

  if (recalcFields.includes("commission_amount")) {
    commissionAmount = commissionPercent * totalAmount;
    await setFieldValue(
      `transaction_details[${index}].commission_amount`,
      commissionAmount
    );
  }
};

const handleQuantityChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, [
    "date_end",
    "total_amount",
    "commission_amount",
  ]);
};

const handleStartDateChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, [
    "quantity",
    "total_amount",
    "commission_amount",
  ]);
};

const handleEndDateChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, [
    "quantity",
    "total_amount",
    "commission_amount",
  ]);
};

const handleTotalPerQuantityChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, [
    "total_amount",
    "commission_amount",
  ]);
};

const handleTotalAmountChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, [
    "total_per_quantity",
    "commission_amount",
  ]);
};

const handleCommissionPercentChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, ["commission_amount"]);
};

const handleCommissionAmountChange = async (
  index: number,
  getFieldProps: FormikProps<any>["getFieldProps"],
  setFieldValue: FormikProps<any>["setFieldValue"]
) => {
  await recalcRow(index, getFieldProps, setFieldValue, ["commission_percent"]);
};

function DetailTypeCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
  dealCategory,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
  dealCategory: "lease" | "sale" | "other";
}) {
  const [detailTypeField] = useField(
    `transaction_details[${index}].detail_type`
  );

  const options = useMemo(() => {
    switch (dealCategory) {
      case "lease":
        return DETAIL_TYPE_ITEMS_LEASE;
      case "sale":
        return DETAIL_TYPE_ITEMS_SALE;
      default:
        return DETAIL_TYPE_ITEMS_OTHER;
    }
  }, [dealCategory]);

  return (
    <TableCell
      onClick={() => startEditing(index, "detail_type")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "detail_type") ? (
        <SelectOption
          fieldName={`transaction_details[${index}].detail_type`}
          options={options}
          showLabel={false}
          onBlur={() => stopEditing()}
          autoFocus
        />
      ) : (
        get(DETAIL_TYPE_ITEMS_MAP, detailTypeField.value, "--")
      )}
    </TableCell>
  );
}

function QuantityCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [quantityField] = useField(`transaction_details[${index}].quantity`);

  return (
    <TableCell
      onClick={() => startEditing(index, "quantity")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "quantity") ? (
        <TextFieldWholeNumber
          fieldName={`transaction_details[${index}].quantity`}
          showLabel={false}
          onBlur={async () => {
            stopEditing();
            await handleQuantityChange(index, getFieldProps, setFieldValue);
          }}
          autoFocus
        />
      ) : (
        formatWholeNumber(quantityField.value)
      )}
    </TableCell>
  );
}

function StartDateCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [startDateField] = useField(`transaction_details[${index}].date_start`);

  return (
    <TableCell
      onClick={() => startEditing(index, "date_start")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "date_start") ? (
        <TextField
          fullWidth
          type="date"
          autoFocus
          value={startDateField.value || ""}
          onChange={(e) =>
            setFieldValue(
              `transaction_details[${index}].date_start`,
              e.target.value
            )
          }
          onBlur={async () => {
            stopEditing();
            await handleStartDateChange(index, getFieldProps, setFieldValue);
          }}
        />
      ) : (
        formatDateForDisplay(startDateField.value)
      )}
    </TableCell>
  );
}

function EndDateCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [endDateField] = useField(`transaction_details[${index}].date_end`);

  return (
    <TableCell
      onClick={() => startEditing(index, "date_end")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "date_end") ? (
        <TextField
          fullWidth
          type="date"
          autoFocus
          value={endDateField.value || ""}
          onChange={(e) =>
            setFieldValue(
              `transaction_details[${index}].date_end`,
              e.target.value
            )
          }
          onBlur={async () => {
            stopEditing();
            await handleEndDateChange(index, getFieldProps, setFieldValue);
          }}
        />
      ) : (
        formatDateForDisplay(endDateField.value)
      )}
    </TableCell>
  );
}

function TotalPerQuantityCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [totalPerQuantityField] = useField(
    `transaction_details[${index}].total_per_quantity`
  );

  return (
    <TableCell
      onClick={() => startEditing(index, "total_per_quantity")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "total_per_quantity") ? (
        <TextFieldCurrency
          fieldName={`transaction_details.${index}.total_per_quantity`}
          showLabel={false}
          autoFocus
          onBlur={async () => {
            stopEditing();
            await handleTotalPerQuantityChange(
              index,
              getFieldProps,
              setFieldValue
            );
          }}
        />
      ) : (
        formatCurrency(totalPerQuantityField.value)
      )}
    </TableCell>
  );
}

function TotalAmountCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [totalAmountField] = useField(
    `transaction_details[${index}].total_amount`
  );

  return (
    <TableCell
      onClick={() => startEditing(index, "total_amount")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "total_amount") ? (
        <TextFieldCurrency
          fieldName={`transaction_details.${index}.total_amount`}
          showLabel={false}
          onBlur={async () => {
            stopEditing();
            await handleTotalAmountChange(index, getFieldProps, setFieldValue);
          }}
          autoFocus
        />
      ) : (
        formatCurrency(totalAmountField.value)
      )}
    </TableCell>
  );
}

function CommissionPercentCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [commissionPercentField] = useField(
    `transaction_details[${index}].commission_percent`
  );

  return (
    <TableCell
      onClick={() => startEditing(index, "commission_percent")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "commission_percent") ? (
        <TextFieldPercent
          fieldName={`transaction_details.${index}.commission_percent`}
          showLabel={false}
          onBlur={async () => {
            stopEditing();
            await handleCommissionPercentChange(
              index,
              getFieldProps,
              setFieldValue
            );
          }}
          autoFocus
        />
      ) : (
        formatPercentageWithDecimal(commissionPercentField.value)
      )}
    </TableCell>
  );
}

function CommissionAmountCell({
  isEditing,
  startEditing,
  stopEditing,
  index,
}: {
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
  index: number;
}) {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [commissionAmountField] = useField(
    `transaction_details[${index}].commission_amount`
  );

  return (
    <TableCell
      onClick={() => startEditing(index, "commission_amount")}
      style={{ cursor: "pointer" }}
    >
      {isEditing(index, "commission_amount") ? (
        <TextFieldCurrency
          fieldName={`transaction_details.${index}.commission_amount`}
          showLabel={false}
          autoFocus
          onBlur={async () => {
            stopEditing();
            await handleCommissionAmountChange(
              index,
              getFieldProps,
              setFieldValue
            );
          }}
        />
      ) : (
        formatCurrency(commissionAmountField.value)
      )}
    </TableCell>
  );
}

const LeaseTable = ({
  transactionDetails,
  isEditing,
  startEditing,
  stopEditing,
}: {
  transactionDetails: TransactionDetailRead[];
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
}) => {
  return (
    <FieldArray
      name="transaction_details"
      render={({ remove, push }) => {
        return (
          <>
            <TableContainer>
              <Table size="small" sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Months</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Rent/Month</TableCell>
                    <TableCell>Total Rent</TableCell>
                    <TableCell>Commission %</TableCell>
                    <TableCell>Commission Amount</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {transactionDetails.map((detail: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <DetailTypeCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                        dealCategory={"lease"}
                      />
                      <QuantityCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <StartDateCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <EndDateCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <TotalPerQuantityCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <TotalAmountCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <CommissionPercentCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <CommissionAmountCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <TableCell align="center">
                        <IconButton color="error" onClick={() => remove(index)}>
                          <Clear />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {/* Footer for totals */}
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={8} align="right">
                      <strong>Totals:</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {formatCurrency(
                          sumBy(transactionDetails, "commission_amount")
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                <Button
                  color="primary"
                  startIcon={<PlusOutlined />}
                  onClick={() =>
                    push({
                      detail_type: DetailTypeEnum.RENT,
                      quantity: 1,
                      date_start: null,
                      date_end: null,
                      total_amount: null,
                      commission_percent: null,
                      commission_amount: null,
                    })
                  }
                  variant="dashed"
                  sx={{ bgcolor: "transparent !important" }}
                >
                  Add Detail
                </Button>
              </Box>
            </Stack>
          </>
        );
      }}
    />
  );
};

const SaleTable = ({
  transactionDetails,
  isEditing,
  startEditing,
  stopEditing,
}: {
  transactionDetails: TransactionDetailRead[];
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
}) => {
  return (
    <FieldArray
      name="transaction_details"
      render={({ remove, push }) => {
        return (
          <>
            <TableContainer>
              <Table size="small" sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Sale Price</TableCell>
                    <TableCell>Commission %</TableCell>
                    <TableCell>Commission Amount</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {transactionDetails.map((detail: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <DetailTypeCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                        dealCategory={"sale"}
                      />
                      <TotalPerQuantityCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <CommissionPercentCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <CommissionAmountCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <TableCell align="center">
                        <IconButton color="error" onClick={() => remove(index)}>
                          <Clear />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {/* Footer for totals */}
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      <strong>Totals:</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {formatCurrency(
                          sumBy(transactionDetails, "commission_amount")
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                <Button
                  color="primary"
                  startIcon={<PlusOutlined />}
                  onClick={() =>
                    push({
                      detail_type: DetailTypeEnum.SALE,
                      quantity: 1,
                      date_start: null,
                      date_end: null,
                      total_amount: null,
                      commission_percent: null,
                      commission_amount: null,
                    })
                  }
                  variant="dashed"
                  sx={{ bgcolor: "transparent !important" }}
                >
                  Add Detail
                </Button>
              </Box>
            </Stack>
          </>
        );
      }}
    />
  );
};

const OtherTable = ({
  transactionDetails,
  isEditing,
  startEditing,
  stopEditing,
}: {
  transactionDetails: TransactionDetailRead[];
  isEditing: (rowIndex: number, fieldName: string) => boolean;
  startEditing: (rowIndex: number, fieldName: string) => void;
  stopEditing: () => void;
}) => {
  return (
    <FieldArray
      name="transaction_details"
      render={({ remove, push }) => {
        return (
          <>
            <TableContainer>
              <Table size="small" sx={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Commission Amount</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {transactionDetails.map((detail: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <DetailTypeCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                        dealCategory={"other"}
                      />
                      <CommissionAmountCell
                        index={index}
                        isEditing={isEditing}
                        startEditing={startEditing}
                        stopEditing={stopEditing}
                      />
                      <TableCell align="center">
                        <IconButton color="error" onClick={() => remove(index)}>
                          <Clear />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {/* Footer for totals */}
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <strong>Totals:</strong>
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight={600}>
                        {formatCurrency(
                          sumBy(transactionDetails, "commission_amount")
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                <Button
                  color="primary"
                  startIcon={<PlusOutlined />}
                  onClick={() =>
                    push({
                      detail_type: DetailTypeEnum.FEE,
                      quantity: 1,
                      date_start: null,
                      date_end: null,
                      total_amount: null,
                      commission_percent: null,
                      commission_amount: null,
                    })
                  }
                  variant="dashed"
                  sx={{ bgcolor: "transparent !important" }}
                >
                  Add Detail
                </Button>
              </Box>
            </Stack>
          </>
        );
      }}
    />
  );
};

const TransactionDetailsField = () => {
  const [dealTypeField] = useField("deal_type");
  const [transactionDetailsField] = useField("transaction_details");

  const [editingCell, setEditingCell] = useState<{
    row: number | null;
    field: string | null;
  }>({ row: null, field: null });

  // Helper to determine if a specific cell is being edited
  const isEditing = (rowIndex: number, fieldName: string) => {
    return editingCell.row === rowIndex && editingCell.field === fieldName;
  };

  // Start editing a cell
  const startEditing = (rowIndex: number, fieldName: string) => {
    setEditingCell({ row: rowIndex, field: fieldName });
  };

  // Stop editing and revert to plaintext
  const stopEditing = () => {
    setEditingCell({ row: null, field: null });
  };

  const dealCategory = get(
    find(DEAL_TYPE_OPTIONS, { key: dealTypeField.value }),
    "category"
  );

  if (!dealCategory) return "Select a deal type";

  return dealCategory === "lease" ? (
    <LeaseTable
      transactionDetails={transactionDetailsField.value}
      isEditing={isEditing}
      startEditing={startEditing}
      stopEditing={stopEditing}
    />
  ) : dealCategory === "sale" ? (
    <SaleTable
      transactionDetails={transactionDetailsField.value}
      isEditing={isEditing}
      startEditing={startEditing}
      stopEditing={stopEditing}
    />
  ) : (
    <OtherTable
      transactionDetails={transactionDetailsField.value}
      isEditing={isEditing}
      startEditing={startEditing}
      stopEditing={stopEditing}
    />
  );
};

export default TransactionDetailsField;
