import dayjs from "dayjs";
import { FormikValues } from "formik";
import _ from "lodash";

import {
  isWeightedEnabledOrgPreferences,
  useDealOrgPreferences,
} from "contexts/DealOrgPreferencesContext";
import { cachedLicense } from "hooks/useCheckLicense";
import { Contact } from "types/api/deal/contact";
import { Deal } from "types/api/deal/deal";
import { DealStatusEnum } from "types/api/deal/enum";
import { LookupItem, SimpleOption } from "types/api/deal/form";
import { KeyDate } from "types/api/deal/keyDate";
import { OptionRead } from "types/api/deal/option";
import { Property } from "types/api/deal/property";
import { AppProduct } from "types/api/user_management/user";
import { Company } from "types/company";
import { DealStage, DealStageType, DealType } from "types/deal";
import { DealOption } from "types/dealOption";
import { KeyDateFormikValue } from "types/form/dealForm";
import { emptyKeyDateUpdate, KeyDatesUpdate } from "types/keyDate";
import { Product } from "types/license";

export const dealTypeSelected = (values: FormikValues) =>
  !_.isEmpty(_.get(values, "deal_type"));
export const isClosed = (values: FormikValues) =>
  _.get(values, "status") === DealStatusEnum.closed;
export const isLost = (values: FormikValues) =>
  _.get(values, "status") === DealStatusEnum.lost;
export const isDealType = (
  values: FormikValues,
  type: DealType | DealType[]
) => {
  if (typeof type === "string") return _.get(values, "deal_type") === type;
  return type.includes(_.get(values, "deal_type"));
};
export const isSharedDeal = (values: FormikValues) =>
  _.get(values, "shared_deal", false);
export const isDualAgency = (values: FormikValues) =>
  _.get(values, "is_dual_agency", false);
export const isDealViewPro = () => cachedLicense(Product.deal_view_pro);

export const useIsStageProbabilityEnabled = (values: FormikValues) => {
  const { data: dealOrgPreferences } = useDealOrgPreferences();

  return (
    _.get(values, "status") === DealStatusEnum.open &&
    isWeightedEnabledOrgPreferences(dealOrgPreferences)
  );
};
export const dateSubmitHandler = (x: any) => {
  if (_.isDate(x)) {
    return dayjs(x).format("YYYY-MM-DDT00:00:00");
  } else {
    return x;
  }
};

export const submitTwoDecimal = (x?: number | null) =>
  _.isNil(x) ? null : _.floor(x, 2);
export const submitCurrency = (x?: number | null) =>
  _.isNil(x) ? null : _.floor(x, 2);
export const submitPercent = (x?: number | null) =>
  _.isNil(x) ? null : _.floor(x, 2) / 100;
export const loadPercent = (x?: number | null) => (_.isNil(x) ? null : x * 100);
export const simpleOptionSubmitHandler = (
  x: SimpleOption | LookupItem | null | undefined
) => _.get(x, "key", null);
export const simpleOptionSubmitHandlerMultiple = (x: SimpleOption[] | null) =>
  _.isNil(x) ? null : _.map(x, "key");
export const retrieveId = (x?: DealStageType) => _.get(x, "id");

export const parseDealStage = (stage: DealStage) => ({
  id: stage?.id || null,
  value: stage?.value || "",
  order: stage?.order ?? null,
  probability: stage?.probability ?? null,
});

export const keyDatesSubmitHandler = (
  keyDatesData: KeyDateFormikValue[] | undefined
) => {
  // Iterate through the key date rows and leave out any empty rows
  return _.reduce(
    keyDatesData,
    (acc: any[], value) => {
      if (value.date) {
        return [
          ...acc,
          {
            id: _.get(value, "id"),
            date: dateSubmitHandler(_.get(value, "date")),
            date_type_name: _.get(value, "date_type"),
            is_delete: _.get(value, "is_delete", false),
          },
        ];
      } else {
        // Empty row, don't append a value
        return acc;
      }
    },
    []
  );
};

export const keyDatesLoadHandler = (keyDatesData: any[]) => {
  // Add placeholder value if empty
  if (!_.isArray(keyDatesData) || _.size(keyDatesData) === 0) {
    keyDatesData = [emptyKeyDateUpdate];
  }

  // Process the date type values and return sorted by date ascending
  return _.sortBy(
    _.map(keyDatesData, (x) =>
      _.assign({}, x, { date_type: parseDealOptionAsString(x?.date_type) })
    ),
    "date"
  );
};

export const parseContactAsSimpleOption = (x: Contact | null) =>
  parseRecordAsSimpleOption<Contact>(x, "full_name");
export const parseCompanyAsSimpleOption = (x: Company | null) =>
  parseRecordAsSimpleOption<Company>(x, "name");
export const parsePropertyAsSimpleOption = (x: Property | null) =>
  parseRecordAsSimpleOption<Property>(x, "name");
export const parseDealAsSimpleOption = (x: Deal | null) =>
  parseRecordAsSimpleOption<Deal>(x, "name");
export const parseDealOptionAsSimpleOption = (x: DealOption | null) =>
  parseRecordAsSimpleOption<DealOption>(x, "value");
export const parseDealOptionAsSimpleOptionMultiple = (x: DealOption[] | null) =>
  _.sortBy(parseRecordAsSimpleOptionMultiple<DealOption>(x, "value"), "label");
export const parseAppProductAsSimpleOptionMultiple = (x: AppProduct[] | null) =>
  _.sortBy(
    parseRecordAsSimpleOptionMultiple<AppProduct>(x, "display_name"),
    "label"
  );

export const parseRecordAsSimpleOption = <T extends object>(
  x: T | null | undefined,
  displayField: string
) => {
  if (_.isNil(x)) return null;

  return {
    key: _.get(x, "id", null),
    label: _.get(x, displayField, ""),
  };
};

const parseRecordAsSimpleOptionMultiple = <T extends object>(
  x: T[] | null,
  displayField: string
) => {
  if (_.isNil(x)) return null;

  return _.map(x, (x) => ({
    key: _.get(x, "id", null),
    label: _.get(x, displayField, ""),
  }));
};

// Plaintext approach to options
export const parseDealOptionAsString = (x: DealOption | OptionRead | null) =>
  _.get(x, "value", null);

export const getClosedDealFormIncomingChanges = () => ({
  status: DealStatusEnum.closed,
  close_date: new Date(),
  commission_payments_percent_toggle: true,
  commission_payments: [
    { amount: null, percent: 1, date: new Date(), paid: false },
    { amount: null, percent: null, date: null, paid: false },
  ],
});
