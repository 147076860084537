import { Theme } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as FileSaver from "file-saver";
import _ from "lodash";

dayjs.extend(relativeTime);

const MONTHS_ABBR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatChartDateV2 = (year: number, month: number) => {
  let returnStr = "";
  try {
    returnStr = `${MONTHS_ABBR[_.toNumber(month) - 1]} ${`${year}`.slice(-2)}`;
  } catch (e) {}

  return returnStr;
};

export function getInflatedMaxForChart(max: number) {
  return _.ceil((max * 1.1) / 10) * 10;
}

const getOrdinalSuffix = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function formatChartDay(year: number, month: number, day: number) {
  // Create a Date object using the provided year, month, and day
  const date = new Date(year, month - 1, day);

  // Format the date using the desired options
  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
  });

  // Return with English ordinal suffix
  return `${formattedDate}${getOrdinalSuffix(day)}`;
}

export const formatWholeNumber = (val: number | null | undefined) => {
  let formatNumber = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
  });
  return _.isNil(val) ? "--" : formatNumber.format(val);
};

export const formatCurrency = (val: number | null | undefined) => {
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });
  return _.isNil(val) ? "--" : formatCurrency.format(val);
};

export const formatCurrencyCompact = (val: number) => {
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    notation: "compact",
  });
  return _.isNil(val) ? "" : formatCurrency.format(val);
};

export const formatCurrencyWholeNumber = (val: number | null | undefined) => {
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return _.isNil(val) ? "" : formatCurrency.format(val);
};

export function formatPercentage(
  percentage?: number | null | undefined
): string {
  if (_.isNil(percentage)) {
    return "0%";
  }

  return `${Math.round(percentage * 100)}%`;
}

export function formatPercentageWithDecimal(
  percentage?: number | null | undefined
): string {
  if (_.isNil(percentage)) {
    return "0%";
  }

  return `${percentage * 100}%`;
}

export const getLeaseExpirationColor = (date: Date, theme: Theme): string => {
  const now = dayjs();
  const queryDate = dayjs(date);

  if (_.isNil(date) || dayjs(queryDate).isAfter(now.add(18, "month"))) {
    return "";
  }
  if (dayjs(queryDate).isAfter(now.add(12, "month"))) {
    return theme.palette.primary.main;
  }
  if (dayjs(queryDate).isAfter(now.add(6, "month"))) {
    return theme.palette.warning.dark;
  }
  return theme.palette.error.dark;
};

export const colorGunmetal = "#2D3343";
export const colorSeaGreen = "#2A8B53";
export const colorRaisinBlack = "#272727";

export const colorRed = "#D2222D";

export const colorGreen = "#238823";

export const radialBarBlue = "#E6F7FF";
export const radialBarBlueFilled = "#1890FF";
export const radialBarGreen = "#F6FFED";
export const radialBarGreenFilled = "#52C41A";
export const radialBarOrange = "#FFE7BA";
export const radialBarOrangeFilled = "#F56E22";

export const getColorPaletteCategorical = () => {
  return ["#298C54", "#388C8F", "#7878FF", "#B25E9C", "#F5872E", "#FFC107"];
};

export function formatDateString(value: any) {
  if (!value) return value;
  // Check if the string is in the format YYYY-MM-DD (ISO 8601 date without time)
  const isDateOnlyFormat = /^\d{4}-\d{2}-\d{2}$/.test(value);
  // Append "T00:00:00" if it's missing the time component
  const dateString = isDateOnlyFormat ? `${value}T00:00:00` : value;
  return new Date(dateString).toLocaleDateString("en-US");
}

export function formatDateTimeString(value: Date | string | null | undefined) {
  return value && dayjs(value).format("M/D/YYYY [at] h:mma");
}

export function displayRelativeDate(date: any) {
  const now = dayjs();
  const targetDate = dayjs(date);

  return targetDate.from(now);
}

export function getPercentage(val: number, total: number) {
  return total ? _.multiply(_.divide(val ?? 0, total), 100) : 0;
}

export function saveApexChart(exportChartId: string, title: string) {
  //@ts-ignore
  ApexCharts.exec(exportChartId, "dataURI", { scale: 2 }).then(({ imgURI }) => {
    FileSaver.saveAs(
      imgURI,
      `CRE OneSource ${title} ${dayjs().format("YYYY-MM-DD")}.png`
    );
  });
}
