import { CheckboxField } from "components/form/CheckboxField";
import { CommissionPaymentSection } from "components/form/compound/CommissionPaymentSection";
import { CommissionSplitSection } from "components/form/compound/CommissionSplitSection";
import { DatePicker } from "components/form/DatePicker";
import { DealTypeSelectOption } from "components/form/DealTypeSelectOption";
import { ActivityForm } from "components/form/forms/ActivityForm";
import { CommissionGoalForm } from "components/form/forms/CommissionGoalForm";
import { CompanyForm } from "components/form/forms/CompanyForm";
import { ContactForm } from "components/form/forms/ContactForm";
import { ContactNameOnlyForm } from "components/form/forms/ContactNameOnlyForm";
import { ContactNotesForm } from "components/form/forms/ContactNotesForm";
import { ContactQuickCreateForm } from "components/form/forms/ContactQuickCreateForm";
import { ConvertToProspectForm } from "components/form/forms/ConvertToProspectForm";
import { DealNotesForm } from "components/form/forms/DealNotesForm";
import { DealPreviewForm } from "components/form/forms/DealPreviewForm";
import { DealViewProForm } from "components/form/forms/DealViewProForm";
import { OptionValueForm } from "components/form/forms/OptionValueForm";
import { QuickAddWizardForm } from "components/form/forms/QuickAddWizardForm";
import { RoleForm } from "components/form/forms/RoleForm";
import { TeamForm } from "components/form/forms/TeamForm";
import { UserForm } from "components/form/forms/UserForm";
import InvoicesField from "components/form/forms/voucher/InvoicesField";
import TransactionDetailsField from "components/form/forms/voucher/TransactionDetailsField";
import { TransactionPartiesField } from "components/form/forms/voucher/TransactionPartiesField";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldPercent } from "components/form/TextFieldPercent";
import {
  TextFieldString,
  TextFieldStringMultiline,
} from "components/form/TextFieldString";
import {
  TextFieldTenDecimal,
  TextFieldWholeNumber,
  TextFieldWholeNumberUnformatted,
} from "components/form/TextFieldWholeNumber";
import {
  DealDocumentsDetail,
  DealDocumentsField,
} from "components/form_v2/deal/attachments/DealDocumentsField";
import { DealAssetTypeField } from "components/form_v2/deal/DealAssetTypeField";
import { DealPropertyLookupField } from "components/form_v2/deal/DealPropertyLookupField";
import { OutsideBrokerField } from "components/form_v2/deal/OutsideBrokerField";
import { SharedDealField } from "components/form_v2/deal/SharedDealField";
import { AddressFieldDetail } from "components/form_v2/detail/AddressFieldDetail";
import { ContactFieldDetail } from "components/form_v2/detail/ContactFieldDetail";
import { CurrencyFieldDetail } from "components/form_v2/detail/CurrencyFieldDetail";
import { DateFieldDetail } from "components/form_v2/detail/DateFieldDetail";
import { DealTypeFieldDetail } from "components/form_v2/detail/deal/DealTypeFieldDetail";
import { EstimatedSizeFieldDetail } from "components/form_v2/detail/deal/EstimatedSizeFieldDetail";
import InvoicesDetail from "components/form_v2/detail/deal/InvoicesDetail";
import TransactionDetailsDetail from "components/form_v2/detail/deal/TransactionDetailsDetail";
import { OptionFieldDetail } from "components/form_v2/detail/OptionFieldDetail";
import { PropertyFieldDetail } from "components/form_v2/detail/PropertyFieldDetail";
import { SizeFieldDetail } from "components/form_v2/detail/SizeFieldDetail";
import { TextFieldDetail } from "components/form_v2/detail/TextFieldDetail";
import KeyDatesSection from "components/form_v2/editable/deal/KeyDatesSection";
import { FormikAddressSearchField } from "components/form_v2/property/FormikAddressSearchField";
import { PropertyLotSizeField } from "components/form_v2/property/PropertyLotSizeField";
import { PropertySizeField } from "components/form_v2/property/PropertySizeField";
import {
  ContactLookupField,
  DealLookupField,
  PropertyLookupField,
  UserLookupField,
} from "pages/deal/components/LookupField";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { ReadOnlyAddress } from "pages/settings/team/sections/dynamic_form/ReadOnlyAddress";
import { ReadOnlyFormSection } from "pages/settings/team/sections/dynamic_form/ReadOnlyFormSection";
import { ReadOnlyLotSize } from "pages/settings/team/sections/dynamic_form/ReadOnlyLotSize";
import { ReadOnlySize } from "pages/settings/team/sections/dynamic_form/ReadOnlySize";
import {
  ReadOnlyDatePickerField,
  ReadOnlyLookupField,
  ReadOnlyTextFieldCurrency,
  ReadOnlyTextFieldString,
  ReadOnlyTextFieldStringMultiline,
} from "pages/settings/team/sections/dynamic_form/ReadOnlyTextFieldString";
import { FormIdentifier, FormIdentifierMapType } from "types/record";

export const formIdentifierMap: FormIdentifierMapType = {
  [FormIdentifier.DealViewProForm]: {
    component: DealViewProForm,
    loadUrl: "/",
  },
  [FormIdentifier.DealPreviewForm]: {
    component: DealPreviewForm,
    loadUrl: "/",
  },
  [FormIdentifier.DealNotesForm]: {
    component: DealNotesForm,
    loadUrl: "/",
  },
  [FormIdentifier.CommissionGoalForm]: {
    component: CommissionGoalForm,
    loadUrl: "/commission_goal/",
  },
  [FormIdentifier.CompanyForm]: {
    component: CompanyForm,
    loadUrl: "/company/",
  },
  [FormIdentifier.ContactForm]: {
    component: ContactForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ContactQuickCreateForm]: {
    component: ContactQuickCreateForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ContactNameOnlyForm]: {
    component: ContactNameOnlyForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ContactNotesForm]: {
    component: ContactNotesForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ConvertToProspectForm]: {
    component: ConvertToProspectForm,
    loadUrl: "/contact/",
  },
  [FormIdentifier.ActivityForm]: {
    component: ActivityForm,
    loadUrl: "/activity/",
  },
  [FormIdentifier.QuickAddWizardForm]: {
    component: QuickAddWizardForm,
    loadUrl: null,
  },
  [FormIdentifier.RoleForm]: {
    component: RoleForm,
    loadUrl: "/organization/role/",
    service: "user",
  },
  [FormIdentifier.UserForm]: {
    component: UserForm,
    loadUrl: "/user/team/member/v2/",
    service: "user",
  },
  [FormIdentifier.TeamForm]: {
    component: TeamForm,
    loadUrl: "/user/team/",
    service: "user",
  },
  [FormIdentifier.OptionForm]: {
    component: OptionValueForm,
    loadUrl: "/option/",
  },
};

export const readOnlyComponentMap = {
  DatePicker: ReadOnlyDatePickerField,
  TextFieldCurrency: ReadOnlyTextFieldCurrency,
  TextFieldString: ReadOnlyTextFieldString,
  TextFieldStringMultiline: ReadOnlyTextFieldStringMultiline,
  TextFieldWholeNumber: ReadOnlyTextFieldString,
  TextFieldWholeNumberUnformatted: ReadOnlyTextFieldString,
  // Special
  ContactLookupField: ReadOnlyLookupField,
  DealLookupField: ReadOnlyLookupField,
  DealOptionAutocompleteNew: ReadOnlyLookupField,
  FormikAddressSearchField: ReadOnlyAddress,
  PropertyLookupField: ReadOnlyLookupField,
  PropertyLotSizeField: ReadOnlyLotSize,
  PropertySizeField: ReadOnlySize,
  UserLookupField: ReadOnlyLookupField,
  VoucherFormAddress: ReadOnlyAddress,
  DealPropertyLookupField: ReadOnlyLookupField,
  DealAssetTypeField: ReadOnlyLookupField,
  DealDocumentsField: ReadOnlyFormSection,
  TransactionDetailsField: ReadOnlyFormSection,
  TransactionPartiesField: ReadOnlyFormSection,
  KeyDatesSection: ReadOnlyFormSection,
  InvoicesField: ReadOnlyFormSection,
  CommissionPaymentSection: ReadOnlyFormSection,
  CommissionSplitSection: ReadOnlyFormSection,
};

export const componentMap = {
  CheckboxField,
  ContactLookupField,
  DatePicker,
  DealLookupField,
  DealOptionAutocompleteNew,
  DealPropertyLookupField,
  DealTypeSelectOption,
  FormikAddressSearchField,
  PropertyLookupField,
  PropertyLotSizeField,
  PropertySizeField,
  TextFieldCurrency,
  TextFieldDecimalNumber: TextFieldTenDecimal,
  TextFieldPercent,
  TextFieldString,
  TextFieldStringMultiline,
  TextFieldWholeNumber,
  TextFieldWholeNumberUnformatted,
  UserLookupField,
  DealAssetTypeField,
  SharedDealField,
  OutsideBrokerField,
  DealDocumentsField,
  TransactionDetailsField,
  TransactionPartiesField,
  KeyDatesSection,
  InvoicesField,
  CommissionSplitSection,
  CommissionPaymentSection,
};

export const detailComponentMap = {
  AddressFieldDetail,
  ContactFieldDetail,
  CurrencyFieldDetail,
  DateFieldDetail,
  DealTypeFieldDetail,
  EstimatedSizeFieldDetail,
  OptionFieldDetail,
  PropertyFieldDetail,
  SizeFieldDetail,
  TextFieldDetail,
  TransactionDetailsDetail,
  InvoicesDetail,
  DealDocumentsDetail,
};
