import { useQuery } from "react-query";

import {
  DealAttachmentFolderRead,
  DealAttachmentRead,
} from "types/api/deal/deal_attachment";
import { coreService } from "utils/axios";

export const getDealFoldersQueryKey = (dealId: number) => [
  "deal_attachment_folder",
  dealId,
];
export const useDealFoldersQuery = (dealId: number) => {
  return useQuery<DealAttachmentFolderRead[]>({
    queryKey: getDealFoldersQueryKey(dealId),
    queryFn: async () => {
      const res = await coreService.get(
        `/deal_attachment_folder/deal/${dealId}`
      );
      return res.data;
    },
  });
};

export const getDealAttachmentsQueryKey = (dealId: number) => [
  "deal_attachment",
  dealId,
];
export const useDealAttachmentsQuery = (dealId: number) => {
  return useQuery<DealAttachmentRead[]>({
    queryKey: getDealAttachmentsQueryKey(dealId),
    queryFn: async () => {
      const res = await coreService.get(`/deal_attachment/deal/${dealId}`);
      return res.data;
    },
  });
};
