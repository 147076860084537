import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

import { DatePicker } from "components/form/DatePicker";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldString } from "components/form/TextFieldString";
import { TextFieldWholeNumber } from "components/form/TextFieldWholeNumber";
import { DealExtended } from "types/api/deal/dealExtended";
import { coreService } from "utils/axios";

interface DownloadInvoiceButtonProps {
  invoiceId: string;
  invoiceName?: string;
}

const DownloadInvoiceButton: React.FC<DownloadInvoiceButtonProps> = ({
  invoiceId,
  invoiceName,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      // Use the dealService Axios instance to call the endpoint
      const response = await coreService.get(`invoices/${invoiceId}/pdf`, {
        responseType: "blob", // Important to receive binary data
      });

      // Convert response to Blob
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a hidden <a> element to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = `${invoiceName}.pdf`;
      // (You can name the file however you wish.)
      document.body.appendChild(link);

      // Trigger download
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the invoice:", error);
      // You might display an error message or toast here
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      disabled={loading}
    >
      {loading ? "Downloading..." : "Download Invoice"}
    </Button>
  );
};

const InvoicesDetail = ({
  record,
  fieldName,
  label,
}: {
  record: DealExtended;
  fieldName: string;
  label: string;
}) => {
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>No. of Payments</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell align={"center"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.invoices.map((detail: any, index: any) => (
              <TableRow key={index}>
                <TableCell>
                  <DatePicker
                    fieldName={`invoices[${index}].date`}
                    showLabel={false}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldCurrency
                    fieldName={`invoices.${index}.amount`}
                    showLabel={false}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldWholeNumber
                    fieldName={`invoices[${index}].payment_quantity`}
                    showLabel={false}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldString
                    fieldName={`invoices[${index}].description`}
                    showLabel={false}
                  />
                </TableCell>
                <TableCell>
                  <TextFieldString
                    fieldName={`invoices[${index}].invoice_code`}
                    showLabel={false}
                  />
                </TableCell>

                {/* Action */}
                <TableCell align="center">
                  {!!detail?.id && (
                    <DownloadInvoiceButton
                      invoiceId={detail.id}
                      invoiceName={detail.invoice_code}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {/* Footer for totals */}
        </Table>
      </TableContainer>
    </>
  );
};

export default InvoicesDetail;
