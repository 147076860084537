import { useField } from "formik";
import React from "react";
import { useQueryClient } from "react-query";

import { MenuChip } from "components/table/field/MenuChip";
import { usePushForm } from "contexts/FormDialogsContext";
import { DEAL_STATUS_OPTIONS } from "pages/deal/constants/deal_enums";
import { getClosedDealFormIncomingChanges } from "pages/deal/utils/deal_form";
import { DealStatusEnum } from "types/api/deal/enum";
import { updateDealAsyncV2 } from "utils/deal";
import { openNotification } from "utils/notistack";

export const DealStatusChip = ({
  value,
  onChange,
}: {
  value: DealStatusEnum;
  onChange: (value: string) => Promise<void>;
}) => {
  return (
    <MenuChip
      value={value}
      options={DEAL_STATUS_OPTIONS}
      handleOptionClick={onChange}
    />
  );
};

export const FormikDealStatusChip = () => {
  const [statusField, , statusHelpers] = useField("status");
  const [idField] = useField("id");
  const pushForm = usePushForm();

  const onChange = async (value: string) => {
    if (idField.value) {
      // Existing record
      if (value === DealStatusEnum.lost) {
        pushForm({
          identifier: "DealLostFieldsForm",
          id: `${idField.value}`,
          changes: {
            status: DealStatusEnum.lost,
            lost_date: new Date(),
          },
        });
      } else if (value === DealStatusEnum.closed) {
        pushForm({
          identifier: "DealClosedFieldsForm",
          id: `${idField.value}`,
          changes: getClosedDealFormIncomingChanges(),
        });
      } else {
        // Set value
        await statusHelpers.setValue(value);
      }
    } else {
      // New record
      await statusHelpers.setValue(value);
    }
  };

  return <DealStatusChip value={statusField.value} onChange={onChange} />;
};

export const StandaloneDealStatusChip = ({
  value,
  recordId,
}: {
  recordId: number;
  value: DealStatusEnum;
}) => {
  const pushForm = usePushForm();
  const queryClient = useQueryClient();

  const onChange = async (option: string) => {
    if (option === DealStatusEnum.lost && recordId) {
      pushForm({
        identifier: "DealLostFieldsForm",
        id: `${recordId}`,
        changes: {
          status: DealStatusEnum.lost,
          lost_date: new Date(),
        },
      });
    } else if (option === DealStatusEnum.closed && recordId) {
      pushForm({
        identifier: "DealClosedFieldsForm",
        id: `${recordId}`,
        changes: getClosedDealFormIncomingChanges(),
      });
    } else if (option === DealStatusEnum.open && recordId) {
      await updateDealAsyncV2(`${recordId}`, { status: DealStatusEnum.open });
      openNotification(`Deal updated.`, "success");
      // Invalidate all queries (supports legacy functionality)
      queryClient.invalidateQueries();
    }
  };

  return <DealStatusChip value={value} onChange={onChange} />;
};
