import { useFormikContext } from "formik";
import React, { createContext, useContext, useMemo } from "react";
import { AnyObject, ObjectSchema } from "yup";

// Initialize context
export const YupRequiredFieldsContext = createContext<Set<string>>(new Set());

// Helper function to recursively extract required fields.
const extractRequiredFields = (
  fields: Record<string, any>,
  prefix = ""
): string[] => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    // Build the full field name (e.g., "deal_fields.deal_type")
    const fullKey = prefix ? `${prefix}.${key}` : key;

    // Even if the parent field is optional, we want to extract required nested fields.
    if (!value.optional) {
      acc.push(fullKey);
    }

    // If the field has nested fields, recursively extract from them.
    if (value.fields && Object.keys(value.fields).length > 0) {
      acc.push(...extractRequiredFields(value.fields, fullKey));
    }
    return acc;
  }, [] as string[]);
};

// Create context provider
export function YupRequiredFieldsProvider({
  validationSchema,
  children,
}: {
  children: React.ReactNode;
  validationSchema: ObjectSchema<{}, AnyObject, {}, "">;
}) {
  const { values, errors } = useFormikContext();
  // Get the live schema description hydrated with values
  const yupDescription = useMemo(
    () => validationSchema.describe({ value: values }),
    [validationSchema, values]
  );

  const requiredFieldsSet = useMemo(() => {
    const requiredFields = extractRequiredFields(yupDescription.fields);
    return new Set(requiredFields);
  }, [yupDescription]);

  return (
    <YupRequiredFieldsContext.Provider value={requiredFieldsSet}>
      {children}
    </YupRequiredFieldsContext.Provider>
  );
}

// Custom hook to check membership in this context
export function useIsFieldRequired(fieldName: string): boolean {
  const requiredFieldsSet = useContext(YupRequiredFieldsContext);
  return requiredFieldsSet.has(fieldName);
}
