import { get } from "lodash";

import { FieldDetail } from "components/form_v2/detail/FieldDetail";

export const PropertyFieldDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  const displayValue = get(record, [fieldName, "name"]);

  return <FieldDetail label={label} displayValue={displayValue} />;
};
