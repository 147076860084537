import React, { createContext, useContext, useState } from "react";

import { RecordActionResponse } from "types/api/deal/api";

export interface FormInstance {
  identifier:
    | "ShareContactForm"
    | "ShareContactBulkForm"
    | "EditContactBulkForm"
    | "DeleteContactBulkForm"
    | "DealClosedFieldsForm"
    | "DealLostFieldsForm"
    | "PropertyForm"
    | "ReportForm"
    | "LedgerTransactionForm";
  id?: string;
  changes?: Record<string, any>;
  successCallback?:
    | ((actionList: RecordActionResponse[]) => void)
    | (() => void);
  meta?: any;
  sectionIndex?: number;
}

interface FormDialogsContextValue {
  forms: FormInstance[];
  pushForm: (form: FormInstance) => void;
  replaceForm: (form: FormInstance) => void;
  popForm: () => void;
}

const FormDialogsContext = createContext<FormDialogsContextValue | undefined>(
  undefined
);

export const FormDialogsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [forms, setFormDialogs] = useState<FormInstance[]>([]);

  const pushForm = (form: FormInstance) => {
    setFormDialogs((prevFormDialogs) => [...prevFormDialogs, form]);
  };

  const replaceForm = (form: FormInstance) => {
    setFormDialogs((prevFormDialogs) => [
      ...prevFormDialogs.slice(0, -1), // Take all except the last one
      form, // Add the new form at the end
    ]);
  };

  const popForm = () => {
    setFormDialogs((prevFormDialogs) => prevFormDialogs.slice(0, -1));
  };

  return (
    <FormDialogsContext.Provider
      value={{ forms, pushForm, replaceForm, popForm }}
    >
      {children}
    </FormDialogsContext.Provider>
  );
};

export const useFormDialogs = (): FormInstance[] => {
  const context = useContext(FormDialogsContext);
  if (context === undefined) {
    throw new Error("useFormDialogs must be used within a FormDialogsProvider");
  }
  return context.forms;
};

export const usePushForm = () => {
  const context = useContext(FormDialogsContext);
  if (context === undefined) {
    throw new Error("usePushForm must be used within a FormDialogsProvider");
  }
  return context.pushForm;
};

export const usePopForm = () => {
  const context = useContext(FormDialogsContext);
  if (context === undefined) {
    throw new Error("usePopForm must be used within a FormDialogsProvider");
  }
  return context.popForm;
};

export const useReplaceForm = () => {
  const context = useContext(FormDialogsContext);
  if (context === undefined) {
    throw new Error("usePopForm must be used within a FormDialogsProvider");
  }
  return context.replaceForm;
};
