import { get } from "lodash";

import { FieldDetail } from "components/form_v2/detail/FieldDetail";
import { sizeTypeDisplay } from "pages/deal/constants/deal_enums";
import { formatWholeNumber } from "pages/deal/utils/reporting";

export const EstimatedSizeFieldDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  const displayValue = `${formatWholeNumber(get(record, "size_est"))} ${get(sizeTypeDisplay, get(record, "size_type") ?? "", "")}`;

  return <FieldDetail label={label} displayValue={displayValue} />;
};
