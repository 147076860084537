import { PlusOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FieldArray, useField } from "formik";
import React from "react";

import { DatePicker } from "components/form/DatePicker";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldString } from "components/form/TextFieldString";
import { TextFieldWholeNumber } from "components/form/TextFieldWholeNumber";

const InvoicesField = () => {
  const [invoicesField] = useField("invoices");

  return (
    <FieldArray
      name="invoices"
      render={({ remove, push }) => {
        return (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 800 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>No. of Payments</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Invoice Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoicesField.value.map((detail: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell>
                        <DatePicker
                          fieldName={`invoices[${index}].date`}
                          showLabel={false}
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldCurrency
                          fieldName={`invoices.${index}.amount`}
                          showLabel={false}
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldWholeNumber
                          fieldName={`invoices[${index}].payment_quantity`}
                          showLabel={false}
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldString
                          fieldName={`invoices[${index}].description`}
                          showLabel={false}
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldString
                          fieldName={`invoices[${index}].invoice_code`}
                          showLabel={false}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* Footer for totals */}
              </Table>
            </TableContainer>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 0 }}>
                <Button
                  color="primary"
                  startIcon={<PlusOutlined />}
                  onClick={() =>
                    push({
                      date: null,
                      payment_quantity: 1,
                      amount: 0,
                      invoice_code: "",
                      description: "",
                    })
                  }
                  variant="dashed"
                  sx={{ bgcolor: "transparent !important" }}
                >
                  Add Invoice
                </Button>
              </Box>
            </Stack>
          </>
        );
      }}
    />
  );
};

export default InvoicesField;
