import {
  dateFormats,
  regexExpressions,
} from "pages/data_import/constants/common";
import { dealStatusDisplay } from "pages/deal/constants/deal_enums";
import { DealStatusEnum } from "types/api/deal/enum";

const brokerRoleValues = [
  {
    display_label: "Executing and Sourcing",
    value: "executing_and_sourcing",
  },
  {
    display_label: "Executing",
    value: "executing",
  },
  {
    display_label: "Sourcing",
    value: "sourcing",
  },
  {
    display_label: "N/A",
    value: "none",
  },
];

const dealStatusValues = [
  {
    display_label: "Open",
    value: "open",
  },
  {
    display_label: "Closed",
    value: "closed",
  },
  {
    display_label: "Lost",
    value: "lost",
  },
];

export const getNewDealImportColumns = (
  brokerValues: Array<{ value: string; display_label: string }>,
  selectedDealStatus: DealStatusEnum
) => {
  return [
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "name",
      display_label: "Deal Name",
      type: "text",
      required: true,
      matching_keywords: "Deal Name*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "deal_type",
      display_label: "Deal Type",
      type: "list",
      validators: {
        values: [
          {
            value: "sale_both",
            display_label: "Sale - Both",
          },
          {
            value: "acquisition",
            display_label: "Acquisition",
          },
          {
            value: "disposition",
            display_label: "Disposition",
          },
          {
            value: "lease_both",
            display_label: "Lease - Both",
          },
          {
            value: "landlord_agency",
            display_label: "Landlord Agency",
          },
          {
            display_label: "Tenant Rep",
            value: "tenant_representation",
          },
          {
            value: "tenant_representation",
            display_label: "Tenant Representation",
          },
          {
            value: "referral",
            display_label: "Referral",
          },
          {
            value: "consulting",
            display_label: "Consulting",
          },
          {
            value: "other",
            display_label: "Other",
          },
        ],
        case_sensitive: false,
      },
      required: true,
      matching_keywords: "Deal Type*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "asset_type_str",
      display_label: "Asset Type",
      type: "text",
      required: true,
      matching_keywords: "Asset Type*",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "transaction_value_est",
      display_label: "Estimated Transaction Value",
      type: "regex",
      validators: {
        expression: regexExpressions.money,
      },
      required: true,
      matching_keywords: "Estimated Transaction Value*",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "transaction_value",
      display_label: "Actual Transaction Value",
      type: "regex",
      validators: {
        expression: regexExpressions.money,
      },
      required: true,
      matching_keywords: "Actual Transaction Value*",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "commission_est",
      display_label: "Estimated Commission",
      type: "regex",
      validators: {
        expression: regexExpressions.money,
      },
      required: true,
      matching_keywords: "Estimated Commission*",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission",
      display_label: "Actual Commission",
      type: "regex",
      validators: {
        expression: regexExpressions.money,
      },
      required: true,
      matching_keywords: "Actual Commission*",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "close_date_est",
      display_label: "Estimated Close Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: true,
      matching_keywords: "Estimated Close Date*",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "close_date",
      display_label: "Actual Close Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: true,
      matching_keywords: "Actual Close Date*",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "size_est",
      display_label: "Estimated Size",
      type: "regex",
      validators: {
        expression: regexExpressions.number,
      },
      required: true,
      matching_keywords: "Estimated Size*",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "size",
      display_label: "Actual Size",
      type: "regex",
      validators: {
        expression: regexExpressions.number,
      },
      required: true,
      matching_keywords: "Actual Size*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "size_type",
      display_label: "Size Type",
      type: "list",
      validators: {
        values: [
          {
            display_label: "Square Feet",
            value: "square_feet",
          },
          {
            display_label: "Acres",
            value: "acres",
          },
          {
            display_label: "Units",
            value: "units",
          },
        ],
        case_sensitive: false,
      },
      default_value: "Square Feet",
      required: true,
      matching_keywords: "Size Type*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "source_type_str",
      display_label: "Deal Source",
      type: "text",
      required: false,
      matching_keywords: "Deal Source*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "client_industry_str",
      display_label: "Client Industry",
      type: "text",
      required: false,
      matching_keywords: "Client Industry*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "contact_name",
      display_label: "Primary Contact Name",
      type: "text",
      required: false,
      matching_keywords: "Primary Contact Name*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "property_name_str",
      display_label: "Property Name",
      type: "text",
      required: false,
      matching_keywords: "Property Name* (Disposition or LLA deals only)",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "tenant_name_str",
      display_label: "Tenant Name",
      type: "text",
      required: false,
      matching_keywords: "Tenant Name* (Tenant Rep deals only)",
    },
    {
      _visibility: [DealStatusEnum.lost],
      column_name: "lost_date",
      display_label: "Lost Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: true,
      matching_keywords: "Lost Date*",
    },
    {
      _visibility: [DealStatusEnum.lost],
      column_name: "lost_reason_str",
      display_label: "Lost Reason",
      type: "text",
      required: true,
      matching_keywords: "Lost Reason*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_broker_1",
      display_label: "Broker 1 Name",
      type: "list",
      validators: {
        values: brokerValues,
        case_sensitive: false,
      },
      required: true,
      matching_keywords: "Broker 1 Name*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_role_1",
      display_label: "Broker 1 Role",
      type: "list",
      validators: {
        values: brokerRoleValues,
        case_sensitive: false,
      },
      required: true,
      matching_keywords: "Broker 1 Role*",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "commission_percent_est_1",
      display_label: "Broker 1 Split (Estimated)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: true,
      matching_keywords: "Broker 1 Split (Estimated)*",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_percent_1",
      display_label: "Broker 1 Split (Actual)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: true,
      matching_keywords: "Broker 1 Split (Actual)*",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_broker_2",
      display_label: "Broker 2 Name",
      type: "list",
      validators: {
        values: brokerValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 2 Name",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_role_2",
      display_label: "Broker 2 Role",
      type: "list",
      validators: {
        values: brokerRoleValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 2 Role",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "commission_percent_est_2",
      display_label: "Broker 2 Split (Estimated)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 2 Split (Estimated)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_percent_2",
      display_label: "Broker 2 Split (Actual)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 2 Split (Actual)",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_broker_3",
      display_label: "Broker 3 Name",
      type: "list",
      validators: {
        values: brokerValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 3 Name",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_role_3",
      display_label: "Broker 3 Role",
      type: "list",
      validators: {
        values: brokerRoleValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 3 Role",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "commission_percent_est_3",
      display_label: "Broker 3 Split (Estimated)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 3 Split (Estimated)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_percent_3",
      display_label: "Broker 3 Split (Actual)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 3 Split (Actual)",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_broker_4",
      display_label: "Broker 4 Name",
      type: "list",
      validators: {
        values: brokerValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 4 Name",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_role_4",
      display_label: "Broker 4 Role",
      type: "list",
      validators: {
        values: brokerRoleValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 4 Role",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "commission_percent_est_4",
      display_label: "Broker 4 Split (Estimated)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 4 Split (Estimated)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_percent_4",
      display_label: "Broker 4 Split (Actual)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 4 Split (Actual)",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_broker_5",
      display_label: "Broker 5 Name",
      type: "list",
      validators: {
        values: brokerValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 5 Name",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "commission_role_5",
      display_label: "Broker 5 Role",
      type: "list",
      validators: {
        values: brokerRoleValues,
        case_sensitive: false,
      },
      required: false,
      matching_keywords: "Broker 5 Role",
    },
    {
      _visibility: [DealStatusEnum.open, DealStatusEnum.lost],
      column_name: "commission_percent_est_5",
      display_label: "Broker 5 Split (Estimated)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 5 Split (Estimated)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_percent_5",
      display_label: "Broker 5 Split (Actual)",
      type: "regex",
      validators: {
        expression: regexExpressions.percent,
      },
      required: false,
      matching_keywords: "Broker 5 Split (Actual)",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "submarket_str",
      display_label: "Submarket",
      type: "text",
      required: false,
      matching_keywords: "Submarket",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "hire_date",
      display_label: "Hire Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: false,
      matching_keywords: "Hire Date",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "notes_plaintext",
      display_label: "Notes",
      type: "text",
      required: false,
      matching_keywords: "Notes",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "address_line_1",
      display_label: "Address Line 1",
      type: "text",
      required: false,
      matching_keywords: "Address",
    },
    // {
    //   column_name: 'address_line_2',
    //   display_label: 'Address Line 2',
    //   type: 'text',
    //   required: false
    // },
    // {
    //   column_name: 'address_state',
    //   display_label: 'State',
    //   type: 'text',
    //   required: false
    // },
    // {
    //   column_name: 'address_city',
    //   display_label: 'City',
    //   type: 'text',
    //   required: false
    // },
    // {
    //   column_name: 'address_postal_code',
    //   display_label: 'Postal Code',
    //   type: 'text',
    //   required: false
    // },
    // {
    //   column_name: 'address_country',
    //   display_label: 'Country',
    //   type: 'text',
    //   required: false
    // },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "outside_broker_name",
      display_label: "Outside Broker",
      type: "text",
      required: false,
      matching_keywords: "Outside Broker",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "counterparty_broker_company_str",
      display_label: "Outside Brokerage",
      type: "text",
      required: false,
      matching_keywords: "Outside Brokerage",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "shared_deal",
      display_label: "Shared Deal?",
      type: "boolean",
      required: false,
      matching_keywords: "Shared Deal?",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "shared_broker_company_str",
      display_label: "Shared Brokerage",
      type: "text",
      required: false,
      matching_keywords: "Shared Brokerage (Shared Deals only)",
    },
    {
      _visibility: [
        DealStatusEnum.open,
        DealStatusEnum.closed,
        DealStatusEnum.lost,
      ],
      column_name: "shared_broker_name",
      display_label: "Shared Broker",
      type: "text",
      required: false,
      matching_keywords: "Shared Broker (Shared Deals only)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "lease_expiration",
      display_label: "Lease Expiration Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: false,
      matching_keywords: "Lease Expiration Date (Leases only)",
    },
    // {
    //   column_name: 'relocation_date_est',
    //   display_label: 'Estimated Relocation Date',
    //   type: 'date',
    //   validators: {
    //     format: dateFormats
    //   },
    //   required: false
    // },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "relocation_date",
      display_label: "Actual Relocation Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: false,
      matching_keywords: "Actual Relocation Date (Tenant Rep)",
    },
    // {
    //   column_name: 'move_in_date_est',
    //   display_label: 'Estimated Move in Date',
    //   type: 'date',
    //   validators: {
    //     format: dateFormats
    //   },
    //   required: false
    // },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "move_in_date",
      display_label: "Actual Move In Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: false,
      matching_keywords: "Actual Move In Date (LLA)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_due_date_amount_first",
      display_label: "1st Half Commission Amount",
      type: "regex",
      validators: {
        expression: regexExpressions.money,
      },
      required: false,
      matching_keywords: "1st Half Commission Amount (Leases only)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_due_date_first",
      display_label: "1st Half Commission Due Date",
      type: "date",
      validators: {
        format: dateFormats,
      },
      required: false,
      matching_keywords: "1st Half Commission Due Date (Leases only)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_due_date_paid_first",
      display_label: "1st Half Paid?",
      type: "boolean",
      required: false,
      matching_keywords: "1st Half Paid?",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_due_date_amount_second",
      display_label: "2nd Half Commission Amount",
      type: "regex",
      validators: {
        expression: regexExpressions.money,
      },
      required: false,
      matching_keywords: "2nd Half Commission Amount (Leases only)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_due_date_second",
      display_label: "2nd Half Commission Due Date",
      type: "date",
      required: false,
      matching_keywords: "2nd Half Commission Due Date (Leases only)",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "commission_due_date_paid_second",
      display_label: "2nd Half Paid?",
      type: "boolean",
      required: false,
      matching_keywords: "2nd Half Paid?",
    },
    {
      _visibility: [DealStatusEnum.closed],
      column_name: "status",
      display_label: "Deal Status",
      type: "list",
      validators: {
        values: dealStatusValues,
        case_sensitive: false,
      },
      required: true,
      matching_keywords: "Deal Status*",
      default_value: !!selectedDealStatus
        ? dealStatusDisplay[selectedDealStatus]
        : "Closed",
    },
    {
      _visibility: [DealStatusEnum.open],
      column_name: "status",
      display_label: "Deal Status",
      type: "list",
      validators: {
        values: dealStatusValues,
        case_sensitive: false,
      },
      required: true,
      matching_keywords: "Deal Status*",
      default_value: "Open",
    },
    {
      _visibility: [DealStatusEnum.lost],
      column_name: "status",
      display_label: "Deal Status",
      type: "list",
      validators: {
        values: dealStatusValues,
        case_sensitive: false,
      },
      required: true,
      matching_keywords: "Deal Status*",
      default_value: "Lost",
    },
  ];
};
