import { Grid } from "@mui/material";
import { get } from "lodash";

import { componentMap, detailComponentMap } from "constants/form";
import { useIsFieldRequired } from "contexts/YupRequiredFieldsContext";
import {
  EditableFormObjectMetadata,
  isEditableFormFieldMetadata,
} from "types/formMetadata";

export const getDynamicFieldComponent =
  (metadata: EditableFormObjectMetadata) =>
  ({ fieldName, columnWidth }: { fieldName: string; columnWidth: number }) => {
    const field = get(metadata, fieldName);
    const required = useIsFieldRequired(fieldName);
    if (!isEditableFormFieldMetadata(field)) return null;

    const { component, displayName } = field;

    const FieldComponent = componentMap[component as keyof typeof componentMap];

    if (!FieldComponent) return null;

    return (
      <Grid item xs={12} sm={columnWidth}>
        <FieldComponent
          fieldName={fieldName}
          displayName={displayName}
          required={required}
        />
      </Grid>
    );
  };

export const getDynamicDetailFieldComponent =
  (metadata: EditableFormObjectMetadata) =>
  ({
    record,
    fieldName,
    columnWidth,
  }: {
    record: any;
    fieldName: string;
    columnWidth: number;
  }) => {
    const field = get(metadata, fieldName);
    if (!isEditableFormFieldMetadata(field)) return null;

    const { detail_component, displayName } = field;
    if (!detail_component) return null;

    const DetailFieldComponent = detailComponentMap[detail_component];
    if (!DetailFieldComponent) return null;

    return (
      <Grid item xs={12} sm={columnWidth}>
        <DetailFieldComponent
          record={record}
          fieldName={fieldName}
          label={displayName ?? ""}
        />
      </Grid>
    );
  };
