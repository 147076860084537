import { isNil } from "lodash";
import { NavigateFunction, SetURLSearchParams } from "react-router-dom";

import { clearEditingRecordStack } from "store/reducers/record";
import { RecordIdentifier } from "types/record";

export const handlePreview = (
  id: number | string,
  type: RecordIdentifier,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) => {
  searchParams.set("preview", id.toString());
  searchParams.set("type", type);
  setSearchParams(searchParams);
};

export const handleDealFormNavigate = (
  navigate: NavigateFunction,
  id: number | undefined,
  incomingChanges: object = {}
) => {
  clearEditingRecordStack();
  navigate(isNil(id) ? "/deals" : `/deals/${id}`, { state: incomingChanges });
};

export const handleDealFormNavigateV2 = (
  navigate: NavigateFunction,
  id: number | string | undefined,
  incomingChanges: object = {},
  replace: boolean = false,
  isEditing?: boolean,
  sectionIndex?: number
) => {
  clearEditingRecordStack();
  const url = isNil(id) ? "/deals_v2" : `/deals_v2/${id}`;

  const searchParams = new URLSearchParams();
  if (isEditing !== undefined) {
    searchParams.set("edit", String(isEditing));
  }
  if (sectionIndex !== undefined) {
    searchParams.set("section", String(sectionIndex));
  }

  navigate(`${url}?${searchParams.toString()}`, {
    state: incomingChanges,
    replace: replace,
  });
};

export const handleVoucherFormNavigate = (
  navigate: NavigateFunction,
  id: number | undefined,
  incomingChanges: object = {},
  isEditing?: boolean
) => {
  const url = isNil(id) ? "/back-office/vouchers" : `/deals_v2/${id}`;

  const searchParams = new URLSearchParams();
  if (isEditing !== undefined) {
    searchParams.set("isEditing", String(isEditing));
  }

  navigate(`${url}?${searchParams.toString()}`, {
    state: incomingChanges,
  });
};
