import { Fragment } from "react";

import DealClosedFieldsFormPage from "components/form/forms/deal/DealClosedFieldsForm";
import DealLostFieldsFormPage from "components/form/forms/deal/DealLostFieldsForm";
import { DeleteContactBulkForm } from "components/form/forms/DeleteContactBulkForm";
import { EditContactBulkForm } from "components/form/forms/EditContactBulkForm";
import ReportFormPage from "components/form/forms/ReportForm";
import { ShareContactBulkForm } from "components/form/forms/ShareContactBulkForm";
import { ShareContactForm } from "components/form/forms/ShareContactForm";
import PropertyFormPage from "components/form_v2/editable/property/PropertyForm";
import LedgerTransactionFormPage from "components/form_v2/ledger_transaction/LedgerTransactionForm";
import {
  FormInstance,
  useFormDialogs,
  usePopForm,
} from "contexts/FormDialogsContext";

const formComponentMap = {
  ShareContactForm: ShareContactForm,
  ShareContactBulkForm: ShareContactBulkForm,
  EditContactBulkForm: EditContactBulkForm,
  DeleteContactBulkForm: DeleteContactBulkForm,
  DealClosedFieldsForm: DealClosedFieldsFormPage,
  DealLostFieldsForm: DealLostFieldsFormPage,
  PropertyForm: PropertyFormPage,
  ReportForm: ReportFormPage,
  LedgerTransactionForm: LedgerTransactionFormPage,
};

export const FormDialogsStack = () => {
  const forms = useFormDialogs();

  if (forms.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {forms.map((form, index) => (
        <FormDialog key={index} form={form} />
      ))}
    </Fragment>
  );
};

const FormDialog = ({ form }: { form: FormInstance }) => {
  const popForm = usePopForm();

  const { id, changes, successCallback = () => {}, meta, identifier } = form;
  const FormComponent = formComponentMap[identifier];

  return (
    <FormComponent
      id={id}
      changes={changes}
      successCallback={successCallback}
      handleClose={popForm}
      meta={meta}
    />
  );
};
