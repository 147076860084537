import { get } from "lodash";

import { formatDateForDisplay } from "components/form/standard/utils/formatting";
import { FieldDetail } from "components/form_v2/detail/FieldDetail";

export const DateFieldDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  const displayValue = formatDateForDisplay(get(record, fieldName));

  return <FieldDetail label={label} displayValue={displayValue} />;
};
