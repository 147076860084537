import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";

interface AddEditModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  item: string | null;
  mode: "create-folder" | "rename-folder" | "rename-file";
  initialName?: string;
}

const titleMap = {
  "create-folder": "Create New Folder",
  "rename-folder": "Rename Folder",
  "rename-file": "Rename File",
};

const AddEditModal: React.FC<AddEditModalProps> = ({
  open,
  onClose,
  onSubmit,
  item,
  mode,
  initialName = "",
}) => {
  const [name, setName] = useState(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const handleClose = () => {
    setName(initialName);
    onClose();
  };

  const handleSave = () => {
    if (name.trim()) {
      onSubmit(name);
      handleClose();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleSave();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{titleMap[mode]}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button type="submit" style={{ display: "none" }} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditModal;
