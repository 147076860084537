import { get } from "lodash";

import { FieldDetail } from "components/form_v2/detail/FieldDetail";
import { DEAL_TYPE_DISPLAY_NAMES } from "types/api/deal/enum";

export const DealTypeFieldDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  const displayValue = get(DEAL_TYPE_DISPLAY_NAMES, record.deal_type);

  return <FieldDetail label={label} displayValue={displayValue} />;
};
