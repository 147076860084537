import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Button, Grid, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { useFormikContext } from "formik";
import { size } from "lodash";
import { useCallback } from "react";

import config from "config";
import { usePopForm } from "contexts/FormDialogsContext";
import { useCheckLicense } from "hooks/useCheckLicense";
import { Product } from "types/license";
import { FormSubmitAction } from "types/standardForm";

export function DealClosedFieldsFormDialogActions({
  setSubmitAction,
}: {
  setSubmitAction: React.Dispatch<
    React.SetStateAction<"save" | "save_and_create_voucher">
  >;
}) {
  const { submitCount, errors, isSubmitting, handleSubmit } =
    useFormikContext();
  const popForm = usePopForm();
  const isBackOffice =
    useCheckLicense([Product.back_office]) && config.features.back_office;

  return (
    <DialogActions>
      <Grid container style={{ display: "flex" }} alignItems="center">
        <Grid item xs sx={{ flexGrow: 1 }}></Grid>
        <Grid item>
          {submitCount > 0 && size(errors) ? (
            <Alert color="error" icon={<InfoCircleFilled />}>
              Please correct one or more errors on the form.
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            alignItems="center"
            sx={{ mr: 1 }}
          >
            <Button color="secondary" onClick={popForm} size={"large"}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              size={"large"}
              variant="contained"
              disabled={isSubmitting}
            >
              Save
            </Button>
            {isBackOffice && (
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setSubmitAction("save_and_create_voucher");
                  handleSubmit();
                }}
                size={"large"}
                variant="contained"
                disabled={isSubmitting}
              >
                Save & Create Voucher
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </DialogActions>
  );
}
