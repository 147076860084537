import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornmentProps, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { parseISO } from "date-fns";
import { useField } from "formik";
import { useState } from "react";

import { FieldComponentProps } from "types/standardForm";

import { CreoneField } from "./basic/creone_field";

interface DatePickerProps extends FieldComponentProps {
  onBlur?: () => void;
}

export function DatePicker(props: DatePickerProps) {
  const [field, , helpers] = useField(props.fieldName);
  const [isCloseIconHovered, setCloseIconHovered] = useState(false);

  const handleBlur = () => {
    helpers.setTouched(true);
    if (typeof props.onBlur === "function") props.onBlur();
  };

  const clearDate = () => {
    helpers.setValue(null);
  };

  const handleMouseEnterCloseIcon = () => {
    setCloseIconHovered(true);
  };

  const handleMouseLeaveCloseIcon = () => {
    setCloseIconHovered(false);
  };

  // Allows calendar to be selected in one click
  const handleMouseDownIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const value =
    typeof field.value === "object" ? field.value : parseISO(field.value);

  return (
    <CreoneField {...props}>
      <DesktopDatePicker
        openTo="day"
        views={["year", "month", "day"]}
        format="M/d/yy"
        {...field}
        value={value}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        sx={{ width: "100%" }}
        slots={{
          textField: TextField,
          inputAdornment: ({ position, children }: InputAdornmentProps) => {
            return (
              <>
                {field.value && isCloseIconHovered && (
                  <IconButton
                    edge="end"
                    onClick={clearDate}
                    onMouseDown={handleMouseDownIcon}
                    size={"small"}
                  >
                    <ClearIcon fontSize={"small"} />
                  </IconButton>
                )}
                {!!children && children}
              </>
            );
          },
        }}
        disabled={props.disabled}
        slotProps={{
          textField: {
            onBlur: handleBlur,
            size: "medium",
            onMouseEnter: handleMouseEnterCloseIcon,
            onMouseLeave: handleMouseLeaveCloseIcon,
          },
        }}
      />
    </CreoneField>
  );
}
