import { Grid } from "@mui/material";
import _ from "lodash";
import React from "react";

import { SelectOption } from "components/form/SelectOption";
import { TextFieldTwoDecimal } from "components/form/TextFieldWholeNumber";
import { useEditing } from "contexts/EditingContext";
import {
  SIZE_TYPE_OPTIONS,
  sizeTypeDisplay,
} from "pages/deal/constants/deal_enums";
import { formatWholeNumber } from "pages/deal/utils/reporting";

export const PropertySizeField = () => {
  const { isEditing } = useEditing();

  return (
    <Grid container spacing={2}>
      <Grid item xs={isEditing ? 6 : undefined}>
        <TextFieldTwoDecimal
          fieldName={"size"}
          displayName={"Size"}
          formatForDisplay={formatWholeNumber}
        />
      </Grid>
      <Grid item xs={isEditing ? 6 : undefined}>
        <SelectOption
          fieldName={"size_type"}
          displayName={""}
          options={SIZE_TYPE_OPTIONS}
          formatForDisplay={(x) => _.get(sizeTypeDisplay, x, "")}
        />
      </Grid>
    </Grid>
  );
};
