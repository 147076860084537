import { get } from "lodash";

import { FieldDetail } from "components/form_v2/detail/FieldDetail";
import { formatCurrency } from "pages/deal/utils/reporting";

export const CurrencyFieldDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  const displayValue = formatCurrency(get(record, fieldName));

  return <FieldDetail label={label} displayValue={displayValue} />;
};
