import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Grid, IconButton, Stack } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import _ from "lodash";
import { Fragment, useMemo } from "react";

import { FieldLabel } from "components/form/basic/creone_field";
import { DatePicker } from "components/form/DatePicker";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { emptyKeyDateUpdate, KeyDatesUpdate } from "types/keyDate";
import { FieldComponentProps } from "types/standardForm";

const KeyDatesArrayComponent = (
  props: FieldComponentProps & { showLabel?: boolean }
) => {
  const { setFieldValue, values } = useFormikContext();
  const keyDatesRows: KeyDatesUpdate[] = _.get(values, props.fieldName, []);

  const handleRemove = (idx: number) => {
    // Mark row for deletion (regardless of whether it has been created or not)
    setFieldValue(`${props.fieldName}.${idx}.is_delete`, true);
  };

  const nonDeletedRows = useMemo(
    () => _.filter(keyDatesRows, (x) => !x["is_delete"]),
    [keyDatesRows]
  );
  const lastNonDeletedRowIndex = useMemo(
    () => _.findLastIndex(keyDatesRows, (x) => !x["is_delete"]),
    [keyDatesRows]
  );

  return (
    <FieldArray name={props.fieldName}>
      {({ push }) => (
        <Grid
          container
          columnSpacing={2}
          rowSpacing={1}
          alignItems={"flex-start"}
          sx={{ pt: 1 }}
        >
          <Grid item xs={5}>
            <FieldLabel
              required={false}
              disabled={false}
              displayName={"Date Type"}
              fieldName={"Date Type"}
            />
          </Grid>
          <Grid item xs={3}>
            <FieldLabel
              required={false}
              disabled={false}
              displayName={"Date"}
              fieldName={"Date"}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          {keyDatesRows.map((row, index) => (
            <Fragment>
              {!row["is_delete"] && (
                <Fragment>
                  <Grid item xs={5}>
                    <DealOptionAutocompleteNew
                      fieldName={`${props.fieldName}.${index}.date_type`}
                      showLabel={false}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DatePicker
                      fieldName={`${props.fieldName}.${index}.date`}
                      showLabel={false}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Stack direction={"row"} sx={{ pt: 0.75 }}>
                      {nonDeletedRows.length > 1 && (
                        <IconButton onClick={() => handleRemove(index)}>
                          <RemoveCircleIcon />
                        </IconButton>
                      )}
                      {index === lastNonDeletedRowIndex && (
                        <IconButton
                          disabled={nonDeletedRows.length >= 10}
                          onClick={() => {
                            push(emptyKeyDateUpdate);
                          }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          ))}
        </Grid>
      )}
    </FieldArray>
  );
};

export default KeyDatesArrayComponent;
