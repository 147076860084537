import { Address, Deal } from "types/api/deal/deal";

import { Company } from "./company";
import { Contact } from "./contact";
import { BaseTableV2 } from "./schemaAddOns";

// Enum for role
export enum PartyRoleEnum {
  BILL_TO = "bill_to",
  OWNER = "owner",
  TENANT = "tenant",
  BUYER = "buyer",
  SELLER = "seller",
  OTHER = "other",
}

export interface TransactionPartyUpdate extends Address {
  role: PartyRoleEnum;

  contact_id?: number | null;
  company_id?: number | null;

  first_name?: string | null;
  last_name?: string | null;
  email_address?: string | null;
}

export interface TransactionPartyBase
  extends BaseTableV2,
    TransactionPartyUpdate {
  deal_id: number;
}

export interface TransactionParty extends TransactionPartyBase {
  deal: Deal;
  contact?: Contact | null;
  company?: Company | null;
}

export interface TransactionPartyInCreate extends TransactionPartyUpdate {}

export interface TransactionPartyInUpdate extends TransactionPartyUpdate {
  id?: string | null;
}
