import { useMemo } from "react";
import { useQuery } from "react-query";

import { SelectOption } from "components/form/SelectOption";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { DEAL_TYPE_OPTIONS } from "pages/deal/constants/deal_enums";
import { getBaseQueryOptionsDealService } from "pages/deal/utils/api";
import { parseDealStageDict } from "pages/deal/utils/deal";
import { DealStage } from "types/deal";
import {
  AdditionalChangeHandlerType,
  FieldComponentProps,
} from "types/standardForm";

export function DealTypeSelectOption(props: FieldComponentProps) {
  // When deal type changes, update the status
  const queryOptions = useMemo(
    () => getBaseQueryOptionsDealService<Record<number, DealStage>>("/stage/"),
    []
  );
  const { data: dealStages = [] } =
    useQuery<Record<number, DealStage>>(queryOptions);

  const updateStageOnDealTypeChange: AdditionalChangeHandlerType = (
    value,
    setFieldValue
  ) => {
    const dealStagesForDealType = parseDealStageDict(dealStages, value);
    // Set deal stage to the first stage option
    setFieldValue(DealMetadata.stage.fieldName, dealStagesForDealType[0]);
  };

  // Destructure additionalChangeHandler from props to avoid it being spread to SelectOption
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { additionalChangeHandler, ...restProps } = props;

  return (
    <SelectOption
      {...restProps}
      options={DEAL_TYPE_OPTIONS}
      additionalChangeHandler={updateStageOnDealTypeChange}
    />
  );
}
