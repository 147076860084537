import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik, FormikConfig } from "formik";
import { pick } from "lodash";
import React, { useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";

import { FormDialogActions } from "components/form/standard/FormDialogActions";
import StandardField from "components/form/standard/StandardField";
import {
  getDealFormInitialValues,
  getDealFormSchema,
  loadDealFormData,
  prepareDealFormDataForSubmit,
} from "constants/deal/deal_form";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { useDealOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupDescriptionProvider } from "contexts/YupDescriptionContext";
import useAuth from "hooks/useAuth";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { DealExtended } from "types/api/deal/dealExtended";
import { DealLostFieldsFormikValues } from "types/form/dealForm";
import { dealService } from "utils/axios";
import { openNotification } from "utils/notistack";

interface DealLostFieldsFormProps {
  id: string;
  changes?: Partial<DealLostFieldsFormikValues>;
  handleClose: () => void;
}

const DealLostFieldsForm = ({
  id,
  changes,
  handleClose,
}: DealLostFieldsFormProps) => {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const queryClient = useQueryClient();

  const { data: orgPreferences } = useDealOrgPreferences();
  const { data: record } = useQuery<DealExtended>({
    queryKey: `/${id}`,
    queryFn: () =>
      dealService.get(`/${id}`).then((response) => {
        return response.data.data;
      }),
  });

  const initialValuesWithData = useMemo(
    () => ({
      ...getDealFormInitialValues(user),
      ...(record ? loadDealFormData(record, usersDisplay) : {}),
      ...changes,
    }),
    [changes, record, user, usersDisplay]
  );

  const validationSchema = useMemo(() => {
    const dealFormSchema = getDealFormSchema(orgPreferences);
    return Yup.object().shape(
      pick(dealFormSchema, ["lost_date", "lost_reason", "status"])
    );
  }, [orgPreferences]);

  const onSubmit: FormikConfig<DealLostFieldsFormikValues>["onSubmit"] =
    useMemo(
      () =>
        async (values: DealLostFieldsFormikValues, { setSubmitting }) => {
          try {
            const preparedValues = prepareDealFormDataForSubmit(values);
            const fieldsToSubmit = pick(preparedValues, [
              "lost_date",
              "lost_reason_name",
              "status",
            ]);

            await dealService.put(`/${id}`, fieldsToSubmit);
            openNotification(`Deal updated.`, "success");
            // Invalidate all queries (supports legacy functionality)
            queryClient.invalidateQueries();
            // TODO: Migrate to targeted query invalidation (forms, tables, charts, etc)
            // await queryClient.invalidateQueries(getDealQueryKey(id));
            handleClose();
          } catch (error) {
            dispatch(openErrorNotification("Error updating deal."));
          } finally {
            setSubmitting(false);
          }
        },
      [id, queryClient]
    );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik<DealLostFieldsFormikValues>
        initialValues={initialValuesWithData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ values }) => (
          <YupDescriptionProvider
            objectSchema={validationSchema}
            values={values}
          >
            <Form>
              <Dialog
                open={true}
                scroll={"paper"}
                fullWidth={true}
                maxWidth={"sm"}
              >
                <Grid container justifyContent="space-between">
                  <DialogTitle>Lost Deal</DialogTitle>
                </Grid>
                <DialogContent dividers>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={12} sm={6}>
                      <StandardField
                        field={DealMetadata.lost_reason}
                        touch={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StandardField field={DealMetadata.lost_date} />
                    </Grid>
                  </Grid>
                </DialogContent>
                <FormDialogActions />
              </Dialog>
            </Form>
          </YupDescriptionProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

const DealLostFieldsFormPage = ({
  id,
  handleClose,
  changes,
}: {
  id?: string;
  handleClose: () => void;
  changes?: Partial<DealLostFieldsFormikValues>;
}) => {
  return id ? (
    <DealLostFieldsForm id={id} handleClose={handleClose} changes={changes} />
  ) : null;
};

export default DealLostFieldsFormPage;
