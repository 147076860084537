import { EditNoteOutlined } from "@mui/icons-material";
import { Box, Stack, Theme, Typography } from "@mui/material";
import _ from "lodash";
import { CellProps } from "react-table";

import IconButton from "components/@extended/IconButton";
import { StandaloneDealStatusChip } from "components/form_v2/deal/DealStatusChip";
import { isWeightedEnabledOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { AggFooter } from "pages/deal/components/AggFooter";
import { AggFooterSize } from "pages/deal/components/AggFooterSize";
import { LightTooltip } from "pages/deal/components/InfoTooltip";
import {
  dealStatusDisplay,
  dealTypeDisplay,
  sizeTypeDisplay,
} from "pages/deal/constants/deal_enums";
import { mapCommissionsToNames } from "pages/deal/utils/deal";
import {
  colorRaisinBlack,
  formatCurrencyWholeNumber,
  formatDateString,
  formatPercentage,
  formatWholeNumber,
  getLeaseExpirationColor,
} from "pages/deal/utils/reporting";
import { startEditingRecord } from "store/reducers/record";
import { DealStatusEnum } from "types/api/deal/enum";
import { OrgPreferencesReadExtended } from "types/api/deal/preferences";
import { Deal, DealExtended, DealType } from "types/deal";
import { FormIdentifier } from "types/record";
import { SelectColumnFilter } from "utils/react-table";

export const defaultColumnOrder = [
  "name",
  "brokers",
  "deal_type",
  "asset_type_id",
  "contact_id",
  "contact_telephone1",
  "contact_telephone2",
  "contact_email_address",
  "property_tenant_name",
  "client_industry_id",
  "source_type_id",
  "counterparty_broker_company_id",
  "outside_broker_id",
  "shared_broker_company_id",
  "shared_broker_id",
  "submarket",
  "hire_date",
  "close_date_effective",
  "transaction_value_effective",
  "commission_effective_unweighted",
  "commission_effective",
  "commission_effective_split",
  "size_effective",
  "status",
  "stage",
  "probability",
  "lease_expiration",
  "notes_plaintext",
];

export const getHiddenDealColumns = (visibleColumns: string[]) => {
  return _.difference(defaultColumnOrder, visibleColumns);
};

function denoteEstimated(row: any) {
  return _.get(row, "original.status") === DealStatusEnum.closed ? "" : "*";
}

export function DealTableColumns(
  usersDisplay: Record<number, string> | null = null,
  dealOrgPreferences: OrgPreferencesReadExtended | null,
  theme: Theme
) {
  const enableDealStageProbability =
    isWeightedEnabledOrgPreferences(dealOrgPreferences);

  return [
    {
      Header: "Deal Name",
      accessor: "name",
      id: "name",
      Footer: (info: any) => {
        const { rows } = info;
        return (
          <Typography variant="subtitle1">
            <Stack color={colorRaisinBlack}>
              <Stack
                direction="row"
                spacing={1.5}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant={"caption"}>count</Typography>
                <div>{rows.length}</div>
              </Stack>
            </Stack>
          </Typography>
        );
      },
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 200 } : {}}>{value}</Box>,
    },
    {
      Header: "Broker(s)",
      accessor: (row: Deal) => mapCommissionsToNames(usersDisplay, row),
      id: "brokers",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 200 } : {}}>{value}</Box>,
    },
    {
      Header: "Deal Type",
      accessor: (row: Deal) => _.get(dealTypeDisplay, row.deal_type, null),
      Filter: (column: any) => SelectColumnFilter(column, dealTypeDisplay),
      filter: "includes",
      id: "deal_type",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Asset Type",
      accessor: (row: DealExtended) => row?.asset_type?.value ?? "",
      id: "asset_type_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Primary Contact",
      accessor: (row: DealExtended) => row?.contact?.full_name,
      id: "contact_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Phone Number",
      accessor: (row: DealExtended) => row?.contact?.telephone1,
      id: "contact_telephone1",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Mobile Phone",
      accessor: (row: DealExtended) => row?.contact?.telephone2,
      id: "contact_telephone2",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Email",
      accessor: (row: DealExtended) => row?.contact?.email_address,
      id: "contact_email_address",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Property / Tenant Name",
      accessor: (row: Deal) =>
        row?.deal_type === DealType.tenant_representation
          ? row?.tenant_name?.value
          : row?.property_name?.value,
      id: "property_tenant_name",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Client Industry",
      accessor: (row: DealExtended) => row?.client_industry?.value,
      id: "client_industry_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Deal Source",
      accessor: (row: DealExtended) => row?.source_type?.value ?? "",
      id: "source_type_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Outside Brokerage",
      accessor: (row: DealExtended) => row?.counterparty_broker_company?.value,
      id: "counterparty_broker_company_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Outside Broker",
      accessor: (row: DealExtended) => row?.outside_broker?.full_name,
      id: "outside_broker_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Shared Brokerage",
      accessor: (row: DealExtended) => row?.shared_broker_company?.value,
      id: "shared_broker_company_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Shared Broker",
      accessor: (row: DealExtended) => row?.shared_broker?.full_name,
      id: "shared_broker_id",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Submarket",
      accessor: (row: DealExtended) => row?.submarket?.value,
      id: "submarket",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Hire Date",
      accessor: "hire_date",
      id: "hire_date",
      className: "cell-right",
      disableFilters: true,
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>
          {formatDateString(value)}
        </Box>
      ),
    },
    {
      Header: "Close Date",
      accessor: "close_date_effective",
      id: "close_date_effective",
      Cell: ({ value, row, observeMinWidth = true }: any) => (
        <Box
          sx={observeMinWidth ? { minWidth: 100 } : {}}
        >{`${formatDateString(value)}${denoteEstimated(row)}`}</Box>
      ),
      className: "cell-right",
      disableFilters: true,
    },
    {
      Header: "Transaction Value",
      accessor: "transaction_value_effective",
      id: "transaction_value_effective",
      Cell: ({
        value,
        row,
        observeMinWidth = true,
      }: {
        value: number | undefined;
        row: any;
        observeMinWidth?: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 190 } : {}}>
          {formatCurrencyWholeNumber(value)}
          {denoteEstimated(row)}
        </Box>
      ),
      className: "cell-right",
      disableFilters: true,
      Footer: (info: any) => (
        <AggFooter
          sum={info?.state?.stats?.sum_transaction_value}
          average={info?.state?.stats?.avg_transaction_value}
          decimalScale={2}
          prefix={"$"}
        />
      ),
    },
    {
      Header: enableDealStageProbability
        ? "Deal Commission (Weighted)"
        : "Deal Commission",
      accessor: (row: DealExtended) => row?.commission_effective,
      id: "commission_effective",
      Cell: ({
        value,
        row,
        observeMinWidth = true,
      }: {
        value: number | undefined;
        row: any;
        observeMinWidth: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 180 } : {}}>
          {formatCurrencyWholeNumber(value)}
          {denoteEstimated(row)}
        </Box>
      ),
      className: "cell-right",
      disableFilters: true,
      Footer: (info: any) => (
        <AggFooter
          sum={info?.state?.stats?.sum_commission}
          average={info?.state?.stats?.avg_commission}
          decimalScale={2}
          prefix={"$"}
        />
      ),
    },
    {
      Header: enableDealStageProbability
        ? "Deal Commission (Unweighted)"
        : "Deal Commission",
      accessor: (row: DealExtended) => row?.commission_effective_unweighted,
      id: "commission_effective_unweighted",
      Cell: ({
        value,
        row,
        observeMinWidth = true,
      }: {
        value: number | undefined;
        row: any;
        observeMinWidth: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 180 } : {}}>
          {formatCurrencyWholeNumber(value)}
          {denoteEstimated(row)}
        </Box>
      ),
      className: "cell-right",
      disableFilters: true,
      Footer: (info: any) => (
        <AggFooter
          sum={info?.state?.stats?.sum_commission_unweighted}
          average={info?.state?.stats?.avg_commission_unweighted}
          decimalScale={2}
          prefix={"$"}
        />
      ),
    },
    {
      Header: "Commission Split",
      accessor: (row: DealExtended) => row?.commission_effective_filter_aware,
      id: "commission_effective_split",
      Cell: ({
        value,
        row,
        observeMinWidth = true,
      }: {
        value: number | undefined;
        row: any;
        observeMinWidth: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 180 } : {}}>
          {formatCurrencyWholeNumber(value)}
          {denoteEstimated(row)}
        </Box>
      ),
      className: "cell-right",
      disableFilters: true,
      Footer: (info: any) => (
        <AggFooter
          sum={info?.state?.stats?.sum_commission_split}
          average={info?.state?.stats?.avg_commission_split}
          decimalScale={2}
          prefix={"$"}
        />
      ),
    },
    {
      Header: "Size",
      accessor: "size_effective",
      id: "size_effective",
      Cell: ({
        value,
        row,
        observeMinWidth = true,
      }: {
        value: number | undefined;
        row: any;
        observeMinWidth: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>
          {`${formatWholeNumber(value)} ${_.get(sizeTypeDisplay, _.get(row, "original.size_type"), "")}`}
          {denoteEstimated(row)}
        </Box>
      ),
      className: "cell-right",
      Footer: (info: any) => (
        <AggFooterSize size_dict={info?.state?.stats?.size_dict} />
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      id: "status",
      Filter: (column: any) => SelectColumnFilter(column, dealStatusDisplay),
      filter: "includes",
      Cell: ({ value, row }: CellProps<Deal>) => (
        <StandaloneDealStatusChip value={value} recordId={row.original.id!} />
      ),
      onClick: () => null,
    },
    {
      Header: "Stage",
      accessor: (row: DealExtended) => row?.stage?.value ?? "",
      id: "stage",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: string;
        observeMinWidth?: boolean;
      }) => <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>{value}</Box>,
    },
    {
      Header: "Probability",
      accessor: (row: DealExtended) =>
        (enableDealStageProbability
          ? row?.probability_override || row?.stage?.probability || 0
          : 1) * 100, // Percentage multiplied to achieve correct sorting order
      id: "probability",
      Cell: ({
        value,
        observeMinWidth = true,
      }: {
        value: number;
        row: any;
        observeMinWidth: boolean;
      }) => (
        <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>
          {formatPercentage(value / 100)}
        </Box>
      ),
      className: "cell-right",
      disableFilters: true,
    },
    {
      Header: "Lease Expiration",
      accessor: "lease_expiration",
      id: "lease_expiration",
      Cell: ({ value, observeMinWidth = true }: any) => {
        const iconColor = getLeaseExpirationColor(value, theme);
        return (
          <Box sx={observeMinWidth ? { minWidth: 150 } : {}}>
            <Typography
              color={iconColor}
              fontWeight={iconColor ? 600 : undefined}
            >
              {formatDateString(value)}
            </Typography>
          </Box>
        );
      },
      disableFilters: true,
    },
    {
      Header: "Notes",
      accessor: "notes_plaintext",
      id: "notes_plaintext",
      disableSortBy: true,
      Cell: ({ row }: CellProps<Deal>) => (
        <Stack direction={"row"} justifyContent={"center"}>
          <LightTooltip title={`Edit Notes`}>
            <IconButton
              onClick={() =>
                startEditingRecord(
                  row.original.id!,
                  FormIdentifier.DealNotesForm
                )
              }
            >
              <EditNoteOutlined></EditNoteOutlined>
            </IconButton>
          </LightTooltip>
        </Stack>
      ),
      onClick: () => null,
    },
  ];
}
