import axios from "axios";

import config from "config";

export const axiosServices = axios.create({
  baseURL: import.meta.env.BASE_URL,
});

export const dealService = axios.create({
  baseURL: `${config.apiBaseUrl}/deal/api/v1`,
  withCredentials: true,
  paramsSerializer: { indexes: null },
});

export const coreService = axios.create({
  baseURL: `${config.apiBaseUrl}/core/api/v1`,
  withCredentials: true,
  paramsSerializer: { indexes: null },
});

export const axiosUserServices = axios.create({
  baseURL: `${config.apiBaseUrl}/user/api/v1`,
  withCredentials: true,
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject((error.response && error.response.data) || "Wrong Services")
);

export default axiosServices;
