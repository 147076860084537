import { detailComponentMap } from "constants/form";
import { FieldIconDataType } from "pages/settings/team/sections/dynamic_form/constants/formField";
import { OrgPreferencesRead } from "types/api/deal/preferences";
import { AccessTokenRead } from "types/api/user_management/access_token";

export interface FormFieldMetadata {
  /** Yup schema used in the Formik object for data validation */
  _schema: any | ((orgPreferences: OrgPreferencesRead) => any);
  /** Label displayed above the input field */
  displayName: string;
  /** Field name in Formik object */
  fieldName: string;
  /** Initial field value when created */
  initialValue: any | ((user: AccessTokenRead) => any);
  /** Transforms the form value before submission */
  submitHandler?: (value: any) => any;
  /** Transforms the API value when loading from backend */
  loadHandler?: (
    value: any,
    userOptionsDictionary: Record<string, string>
  ) => any;
  /** Field name used when submitting to the backend */
  submitFieldName?: string;
  /** If `true`, this field is not submitted to the backend */
  virtual?: boolean;
  /** Component name used to render the field */
  component?: string;
  /** Formats the form value for display */
  formatForDisplay?: (value: any) => string;
}

export type FormMetadata = FormFieldMetadata | FormObjectMetadata;
export interface FormObjectMetadata {
  [key: string]: FormMetadata;
}

export interface EditableFormFieldMetadata extends FormFieldMetadata {
  /** Component name used to render the field detail value */
  detail_component?: keyof typeof detailComponentMap;
  /** Icon type for the field in form builder */
  data_type?: FieldIconDataType;
  /** If `true`, the field can be moved in the form layout editor */
  formCanEditLayout?: boolean;
  /** If `true`, the field can be removed in the form layout editor */
  formCanRemove?: boolean;
}

export type EditableFormMetadata =
  | EditableFormFieldMetadata
  | EditableFormObjectMetadata;
export interface EditableFormObjectMetadata {
  [key: string]: EditableFormMetadata;
}

export interface FormBuilderFieldMetadata extends EditableFormFieldMetadata {
  /** Icon type for the field in form builder */
  data_type: FieldIconDataType;
}

/** Generic type guard for FormFieldMetadata-like structures */
function hasSchema(item: any): item is { _schema: any } {
  return item && "_schema" in item && item._schema !== undefined;
}

/** Type guard: Checks if the item is FormFieldMetadata */
export function isFormFieldMetadata(
  item: FormMetadata
): item is FormFieldMetadata {
  return hasSchema(item);
}

/** Type guard: Checks if the item is EditableFormFieldMetadata */
export function isEditableFormFieldMetadata(
  item: EditableFormMetadata
): item is EditableFormFieldMetadata {
  return hasSchema(item);
}

/** Type guard: Checks if the item is meant to appear on the form builder */
export function isFormBuilderFieldMetadata(
  item: EditableFormMetadata
): item is FormBuilderFieldMetadata {
  return item && "data_type" in item && item.data_type !== undefined;
}
