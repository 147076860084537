import { FormikConfig } from "formik";
import { get, merge, reduce } from "lodash";
import * as Yup from "yup";
import { ObjectSchema, Schema } from "yup";

import { OrgPreferencesReadExtended } from "types/api/deal/preferences";
import { AccessTokenRead } from "types/api/user_management/access_token";
import {
  FormMetadata,
  FormObjectMetadata,
  isFormFieldMetadata,
} from "types/formMetadata";

// Function to derive Yup schema from metadata recursively
export const deriveYupSchemaFromMetadataV3 = (
  metadata: FormObjectMetadata,
  orgPreferences?: OrgPreferencesReadExtended | null
): ObjectSchema<any> => {
  const buildSchema = (metadataItem: FormMetadata): Schema => {
    if (isFormFieldMetadata(metadataItem)) {
      const _schema = metadataItem._schema;
      // Base case: FormFieldMetadata
      return typeof _schema === "function" ? _schema(orgPreferences) : _schema;
    } else {
      // Recursive case: FormObjectMetadata
      return Yup.object().shape(
        reduce(
          metadataItem as FormObjectMetadata,
          (acc, value, key) => ({
            ...acc,
            [key]: buildSchema(value),
          }),
          {}
        )
      );
    }
  };

  return buildSchema(metadata) as ObjectSchema<any>;
};

// Custom hook to generate initial values based on metadata recursively
export const getInitialValuesV3 = <
  R extends Record<string, any>,
  IC extends Record<string, any>,
>(
  metadata: FormObjectMetadata,
  record: R | undefined,
  changes: IC,
  usersDisplay: Record<number, string>,
  user: AccessTokenRead
) => {
  const getInitialValuesRecursive = (metadataItem: FormMetadata): any => {
    if (isFormFieldMetadata(metadataItem)) {
      // Base case: FormFieldMetadata
      const initialValue =
        typeof metadataItem.initialValue === "function"
          ? metadataItem.initialValue(user)
          : metadataItem.initialValue;
      return initialValue;
    } else {
      // Recursive case: FormObjectMetadata
      return reduce(
        metadataItem as FormObjectMetadata,
        (acc, value, key) => ({
          ...acc,
          [key]: getInitialValuesRecursive(value),
        }),
        {}
      );
    }
  };

  const getUiRecordObjRecursive = (
    metadataItem: FormMetadata,
    path: string[] = []
  ): any => {
    if (isFormFieldMetadata(metadataItem)) {
      // Base case: FieldMetadataV3
      const { loadHandler = (x: any) => x } = metadataItem;
      const fullPath = path.join(".");
      const fieldValue = loadHandler(get(record, fullPath), usersDisplay);
      return fieldValue;
    } else {
      // Recursive case: FormObjectMetadata
      return reduce(
        metadataItem as FormObjectMetadata,
        (acc, value, key) => ({
          ...acc,
          [key]: getUiRecordObjRecursive(value, [...path, key]),
        }),
        {}
      );
    }
  };

  // Create a new record with default/baseline values for fields
  const newRecord = getInitialValuesRecursive(metadata);
  if (record) {
    const uiRecordObj = getUiRecordObjRecursive(metadata);
    // Merge actual record values over top of defaults/baselines
    return merge({}, newRecord, uiRecordObj, changes);
  } else {
    return merge({}, newRecord, changes);
  }
};

// Generate an onSubmit handler that will transform the form values as needed for the API recursively
export function getOnSubmitV3(
  metadata: FormObjectMetadata,
  submitData: (record: any) => Promise<unknown>
): FormikConfig<any>["onSubmit"] {
  const getSubmitDataRecursive = (
    metadataItem: FormMetadata,
    values: any,
    path: string[] = []
  ): any => {
    // Recursive case: FormObjectMetadata
    return reduce(
      metadataItem as FormObjectMetadata,
      (acc, value, key) => {
        if (isFormFieldMetadata(value)) {
          // Base case: FieldMetadataV3
          const {
            virtual: isVirtual,
            submitFieldName,
            fieldName,
            submitHandler = (x: any) => x,
          } = value;
          // Skip fields flagged as virtual
          if (isVirtual) return acc;

          const fullPath = [...path, key].join(".");
          const fieldValue = submitHandler(get(values, fullPath));
          const fieldKey = submitFieldName || fieldName;
          return { ...acc, [fieldKey]: fieldValue };
        } else {
          return {
            ...acc,
            [key]: getSubmitDataRecursive(value, values, [...path, key]),
          };
        }
      },
      {}
    );
  };

  return async (values, { setSubmitting }) => {
    try {
      const newRecord = getSubmitDataRecursive(metadata, values);
      await submitData(newRecord);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };
}
