import { Box, Typography } from "@mui/material";
import { useMemo } from "react";

import useAuth from "hooks/useAuth";
import { getMenuItems } from "menu-items";

import NavGroup from "./NavGroup";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { user } = useAuth();

  const navigation = useMemo(() => getMenuItems(user), [user]);

  const navGroups = useMemo(() => {
    return navigation.items.map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        case "hidden":
          return null;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Fix - Navigation Group
            </Typography>
          );
      }
    });
  }, [navigation]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      {navGroups}
    </Box>
  );
};

export default Navigation;
