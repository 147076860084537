import { get } from "lodash";

import { FieldDetail } from "components/form_v2/detail/FieldDetail";
import { formatAddressForDisplay } from "utils/address";

export const AddressFieldDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  const displayValue = formatAddressForDisplay(get(record, fieldName));

  return <FieldDetail label={label} displayValue={displayValue} />;
};
