import { FormInstance } from "contexts/FormDialogsContext";
import { dispatch } from "store";
import {
  openErrorNotification,
  openSuccessNotification,
} from "store/reducers/common";
import { LedgerTransactionCreate } from "types/api/deal/ledger_transaction";
import { coreService } from "utils/axios";
import { handleRecordActionResponseV5 } from "utils/record";

export async function createLedgerTransactionAsync(
  ledger_transaction: LedgerTransactionCreate,
  pushForm: (form: FormInstance) => void
) {
  try {
    const response = await coreService.post(
      `/ledger_transaction`,
      ledger_transaction
    );
    handleRecordActionResponseV5(response, pushForm);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error creating transaction."));
    throw error;
  }
}

export async function updateLedgerTransactionAsync(
  id: number | string,
  ledger_transaction: Partial<LedgerTransactionCreate>,
  pushForm: (form: FormInstance) => void
) {
  try {
    const response = await coreService.put(
      `/ledger_transaction/${id}`,
      ledger_transaction
    );
    handleRecordActionResponseV5(response, pushForm);
    return response.data;
  } catch (error) {
    dispatch(openErrorNotification("Error updating transaction."));
    throw error;
  }
}

export async function deleteLedgerTransactionAsync(id: number | string) {
  try {
    await coreService.delete(`/ledger_transaction/${id}`);
    dispatch(openSuccessNotification("Transaction deleted."));
  } catch (error) {
    dispatch(openErrorNotification("Error deleting transaction."));
    throw error;
  }
}
