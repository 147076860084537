import { BaseTable } from "types/api/deal/schemaAddOns";

import { Deal } from "./deal";

export enum BrokerRoleEnum {
  EXECUTING_AND_SOURCING = "executing_and_sourcing",
  SOURCING = "sourcing",
  EXECUTING = "executing",
  NONE = "none",
}

export interface CommissionBase {
  user_id: number;
  deal_id?: number;
  estimate_percent?: number;
  actual_percent?: number;
  broker_role?: BrokerRoleEnum; // Assuming broker_role is optional as its Python default is None
}

// Extends BaseTable with CommissionBase fields
export interface Commission extends BaseTable, CommissionBase {
  deal?: Deal;
}

export interface CommissionRead extends CommissionBase {
  // Matches CommissionBase, as no additional properties are defined in the Python model
}
