import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import {
  Add,
  CloudUploadOutlined,
  Delete,
  Download,
  DragIndicator,
  Edit,
  FolderOpenTwoTone,
  MoreHoriz,
  UploadFile,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useField } from "formik";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { FoldersList } from "components/form_v2/deal/attachments/FoldersList";
import { ConfirmDeleteDialog } from "components/form_v2/editable/components/ConfirmDeleteDialog";
import MainCard from "components/MainCard";
import {
  getDealAttachmentsQueryKey,
  useDealAttachmentsQuery,
  useDealFoldersQuery,
} from "queries/useDealAttachment";
import {
  openErrorNotification,
  openSuccessNotification,
} from "store/reducers/common";
import { DealAttachmentRead } from "types/api/deal/deal_attachment";
import { coreService } from "utils/axios";
import { getIconForContentType } from "utils/file";

import AddEditModal from "./AddEditModal";

// Styled components
const UploadSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "50%",
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 44,
  width: 44,
}));

const UploadArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isDragOver",
})<{ isDragOver: boolean }>(({ theme, isDragOver }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  border: "1px dashed grey",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: 150,
  borderRadius: theme.spacing(1),
  backgroundColor: "white",
  ...(isDragOver && {
    backgroundColor: blue[50],
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
  }),
}));

export function formatBytes(bytes: number): string {
  if (bytes === 0) return "0 Bytes";
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log2(bytes) / 10);
  const value = bytes / Math.pow(1024, i);
  return `${i > 2 ? value.toFixed(2) : value.toFixed(0)} ${sizes[i]}`;
}

const DealDocumentsSection = ({
  dealId,
  readOnly = false,
}: {
  dealId: number;
  readOnly?: boolean;
}) => {
  const MAX_FILE_SIZE_MB = 500;
  const queryClient = useQueryClient();

  // State management
  const [isDragOver, setIsDragOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [modalMode, setModalMode] = useState<
    "create-folder" | "rename-folder" | "rename-file"
  >("create-folder");
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    name: string;
  } | null>(null);

  // Query hooks
  const {
    data: folders = [],
    isLoading: foldersLoading,
    refetch: refetchFolders,
  } = useDealFoldersQuery(dealId);
  const {
    data: attachments = [],
    isLoading: attachmentsLoading,
    refetch: refetchAttachments,
  } = useDealAttachmentsQuery(dealId);

  // Mutation hooks
  const uploadMutation = useMutation(
    async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);
      return coreService.post(`/deal_attachment/${dealId}`, formData);
    },
    {
      onSuccess: (data, variables, context) => {
        openSuccessNotification(`File ${variables.name} uploaded successfully`);
      },
      onError: (error, variables, context) => {
        console.error(error);
        openErrorNotification(`Failed to upload ${variables.name}`);
      },
    }
  );

  const updateFolderMutation = useMutation(
    async ({
      attachmentId,
      folderId,
    }: {
      attachmentId: string;
      folderId: string | null;
    }) => {
      return coreService.put(`/deal_attachment/${attachmentId}`, {
        folder_id: folderId,
      });
    },
    {
      onSuccess: () => {
        openSuccessNotification("File moved successfully");
        // Invalidate or refetch attachments to see updated folder
        queryClient.invalidateQueries(getDealAttachmentsQueryKey(dealId));
      },
      onError: () => {
        openErrorNotification("Failed to move file");
      },
    }
  );

  const updateFileNameMutation = useMutation(
    async ({
      attachmentId,
      newName,
    }: {
      attachmentId: string;
      newName: string;
    }) => {
      return coreService.put(`/deal_attachment/${attachmentId}`, {
        file_name: newName,
      });
    },
    {
      onSuccess: () => {
        openSuccessNotification("File renamed successfully");
        queryClient.invalidateQueries(getDealAttachmentsQueryKey(dealId));
      },
      onError: () => {
        openErrorNotification("Failed to rename file");
      },
    }
  );

  const updateFolderNameMutation = useMutation(
    async ({ folderId, newName }: { folderId: string; newName: string }) => {
      return coreService.put(`/deal_attachment_folder/${folderId}`, {
        name: newName,
      });
    },
    {
      onSuccess: () => {
        openSuccessNotification("File renamed successfully");
        refetchFolders();
        queryClient.invalidateQueries(getDealAttachmentsQueryKey(dealId));
      },
      onError: () => {
        openErrorNotification("Failed to rename file");
      },
    }
  );

  const createFolderMutation = useMutation(
    async ({ dealId, folderName }: { dealId: string; folderName: string }) => {
      return coreService.post(`deal_attachment_folder`, {
        deal_id: dealId,
        name: folderName,
      });
    },
    {
      onSuccess: () => {
        openSuccessNotification("Folder created successfully");
        refetchFolders();
        queryClient.invalidateQueries(getDealAttachmentsQueryKey(dealId));
      },
      onError: () => {
        openErrorNotification("Failed to create folder");
      },
    }
  );

  // Helper functions
  const filterValidFiles = (files: File[]) =>
    files.filter((file) => file.size <= MAX_FILE_SIZE_MB * 1024 * 1024);

  const handleUpload = async (filesToUpload: File[]) => {
    const validFiles = filterValidFiles(filesToUpload);
    if (!validFiles.length) return;

    setUploading(true);
    try {
      await Promise.all(
        validFiles.map((file) => uploadMutation.mutateAsync(file))
      );
      refetchAttachments();
    } finally {
      setUploading(false);
    }
  };

  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return;
    await handleUpload(Array.from(e.target.files));
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => setIsDragOver(false);

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragOver(false);
    await handleUpload(Array.from(event.dataTransfer.files));
  };

  const handleDragStart = () => setIsDragging(true);

  const handleDragEnd = (result: DropResult) => {
    setIsDragging(false);
    if (
      !result.destination ||
      result.source.droppableId === result.destination.droppableId
    )
      return;

    updateFolderMutation.mutate({
      attachmentId: result.draggableId,
      folderId:
        result.destination.droppableId === "All Files"
          ? null
          : result.destination.droppableId,
    });
  };

  const handleFileDelete = async (attachment: DealAttachmentRead) => {
    try {
      await coreService.delete(`/deal_attachment/${attachment.id}`);
      openSuccessNotification("File deleted successfully");
      setDeleteModalOpen(false);
      refetchAttachments();
    } catch (error) {
      console.error(error);
      openErrorNotification("Failed to delete file");
    }
  };

  const handleFolderDelete = async (folder_id: string) => {
    try {
      await coreService.delete(`/deal_attachment_folder/${folder_id}`);
      openSuccessNotification("Folder deleted successfully");
      refetchFolders();
    } catch (error) {
      console.error(error);
      openErrorNotification("Failed to delete folder");
    }
  };

  const handleDownload = async () => {
    try {
      if (!selectedItem) {
        openErrorNotification("No file selected for download");
        return;
      }
      const res = await coreService.get(`/deal_attachment/${selectedItem.id}`);
      window.open(res.data.url, "_blank");
    } catch (error) {
      console.error(error);
      openErrorNotification("Failed to download file");
    }
  };

  const handleFileNameUpdate = (
    attachmentId: string | null,
    newName: string
  ) => {
    if (!newName.trim())
      return openErrorNotification("File name cannot be empty");
    if (attachmentId) updateFileNameMutation.mutate({ attachmentId, newName });
  };

  const handleCreateFolder = (dealId: string | null, folderName: string) => {
    if (!folderName.trim())
      return openErrorNotification("Folder name cannot be empty");
    if (dealId) createFolderMutation.mutate({ dealId, folderName });
  };

  const handleFolderNameUpdate = (folderId: string | null, newName: string) => {
    if (!newName.trim())
      return openErrorNotification("File name cannot be empty");
    if (folderId) updateFolderNameMutation.mutate({ folderId, newName });
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    const selectedAttachment = attachments.find((att) => att.id === id);

    if (selectedAttachment) {
      setSelectedItem({
        id: selectedAttachment.id,
        name: selectedAttachment.file_name,
      });
    } else {
      setSelectedItem(null);
    }

    setAnchorEl(event.currentTarget);
  };

  const handleFileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFolderClick = (folderId: string | null) =>
    setSelectedFolderId(folderId);

  const handleOpenModal = (
    mode: "create-folder" | "rename-folder" | "rename-file",
    item?: { id: string; name: string }
  ) => {
    setModalMode(mode);
    setModalOpen(true);
  };

  const handleOpenDeleteModal = (item?: { id: string; name: string }) => {
    setDeleteModalOpen(true);
  };

  const handleModalSubmit = (newName: string) => {
    if (modalMode === "create-folder") {
      handleCreateFolder(dealId.toString(), newName);
    } else if (modalMode === "rename-folder") {
      handleFolderNameUpdate(selectedItem?.id || null, newName);
    } else if (modalMode === "rename-file") {
      handleFileNameUpdate(selectedItem?.id || null, newName);
    }
    setModalOpen(false);
  };

  const filteredAttachments = selectedFolderId
    ? attachments.filter((att) => att.folder_id === selectedFolderId)
    : attachments;

  return (
    <>
      {!readOnly && (
        <UploadSection>
          <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
            <IconWrapper>
              <UploadFile sx={{ color: "#2289DF" }} />
            </IconWrapper>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5" component="h2" sx={{ fontSize: "18px" }}>
                Upload Files
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "14px", color: "#333" }}
              >
                Example docs: Closing docs, disclaimers, etc.
              </Typography>
            </Box>
          </Box>
          <UploadArea
            isDragOver={isDragOver}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {uploading ? (
              <Box
                sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}
              >
                <CircularProgress size={20} />
                <Typography variant="caption">Uploading...</Typography>
              </Box>
            ) : !isDragOver ? (
              <>
                <UploadFile sx={{ color: "#2289DF" }} />
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <Button
                    component="label"
                    variant="text"
                    color="primary"
                    sx={{
                      textTransform: "none",
                      padding: 0,
                      textDecoration: "underline",
                    }}
                  >
                    Click to upload
                    <input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </Button>
                  &nbsp;or drag and drop
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ mt: 1 }}
                >
                  (max. {MAX_FILE_SIZE_MB}MB)
                </Typography>
              </>
            ) : (
              <>
                <CloudUploadOutlined sx={{ color: "#2289DF" }} />
                <Typography variant="body1" sx={{ mt: 1, fontWeight: 500 }}>
                  Drop your files here
                </Typography>
              </>
            )}
          </UploadArea>
        </UploadSection>
      )}

      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Box sx={{ mt: 3, px: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h4">Folders</Typography>
            {!readOnly && (
              <Tooltip title="Add Folder" placement="right">
                <IconButton
                  onClick={() => handleOpenModal("create-folder")}
                  color="primary"
                >
                  <Add fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
              gap: 2,
            }}
          >
            <FoldersList
              folders={folders}
              attachments={attachments}
              loading={foldersLoading}
              handleFolderClick={handleFolderClick}
              handleFolderDelete={handleFolderDelete}
              handleOpenModal={handleOpenModal}
              selectedFolderId={selectedFolderId}
              setSelectedItem={setSelectedItem}
              isDragging={isDragging}
              readOnly={readOnly}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 3, px: 2 }}>
          <Typography variant="h4">Files</Typography>
          <Typography variant="body2" color="textSecondary">
            Drag uploaded files into folders above
          </Typography>
          <Box sx={{ mt: 2 }}>
            {attachmentsLoading ? (
              <CircularProgress />
            ) : filteredAttachments.length === 0 ? (
              <Typography variant="body2" color="textSecondary">
                No files available
              </Typography>
            ) : (
              <Droppable
                droppableId="files"
                isDropDisabled
                direction="horizontal"
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(280px, 1fr))",
                      gap: 2,
                    }}
                  >
                    {filteredAttachments.map((attachment, index) => (
                      <Draggable
                        key={attachment.id}
                        draggableId={attachment.id}
                        index={index}
                        isDragDisabled={readOnly}
                      >
                        {(provided, snapshot) => (
                          <MainCard
                            sx={{
                              height: 86,
                              opacity: snapshot.isDragging ? 0.9 : 1,
                              borderColor: snapshot.isDragging
                                ? "primary.main"
                                : "grey.300",
                              borderWidth: snapshot.isDragging ? 2 : 1,
                              borderStyle: "solid",
                            }}
                            contentSX={{ px: 2, py: 2 }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box sx={{ display: "flex" }}>
                                {!readOnly && (
                                  <Box
                                    sx={{
                                      height: "48px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <DragIndicator
                                      sx={{
                                        color: `grey.500`,
                                        mr: 1,
                                        fontSize: 10,
                                      }}
                                    />
                                  </Box>
                                )}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: 0.25,
                                    flexShrink: 0,
                                  }}
                                >
                                  {getIconForContentType(
                                    attachment.content_type,
                                    30
                                  )}
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    sx={{ fontSize: 9 }}
                                  >
                                    {formatBytes(attachment.file_size || 0)}
                                  </Typography>
                                </Box>
                                <Box sx={{ ml: 2, height: 44 }}>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      sm: { width: "100px" },
                                      md: { width: "140px" },
                                    }}
                                  >
                                    {attachment.file_name}
                                  </Typography>
                                  {attachment.folder_id && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                      }}
                                    >
                                      <FolderOpenTwoTone
                                        sx={{
                                          fontSize: "12px",
                                          opacity: "0.5",
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        sx={{
                                          display: "inline-block",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "100px",
                                        }}
                                      >
                                        /
                                        {folders.find(
                                          (folder) =>
                                            folder.id === attachment.folder_id
                                        )?.name || ""}
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box sx={{ ml: 1 }}>
                                {!readOnly ? (
                                  <Box sx={{ height: 48 }}>
                                    <IconButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleMenuClick(event, attachment.id);
                                      }}
                                    >
                                      <MoreHoriz fontSize="small" />
                                    </IconButton>
                                  </Box>
                                ) : (
                                  <Box sx={{ height: 48 }}>
                                    <IconButton
                                      onClick={() => handleDownload()}
                                    >
                                      <Download fontSize="small" />
                                    </IconButton>
                                  </Box>
                                )}
                                <Menu
                                  anchorEl={anchorEl}
                                  open={!!anchorEl}
                                  onClose={handleFileMenuClose}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      handleOpenModal("rename-file", {
                                        id: attachment.id,
                                        name: attachment.file_name,
                                      });
                                      handleFileMenuClose();
                                    }}
                                  >
                                    <Edit fontSize="small" sx={{ mr: 1 }} />
                                    Rename
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleDownload();
                                      handleFileMenuClose();
                                    }}
                                  >
                                    <Download fontSize="small" sx={{ mr: 1 }} />
                                    Download
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleOpenDeleteModal({
                                        id: attachment.id,
                                        name: attachment.file_name,
                                      });
                                      handleFileMenuClose();
                                    }}
                                  >
                                    <Delete fontSize="small" sx={{ mr: 1 }} />
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </Box>
                          </MainCard>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            )}
          </Box>
        </Box>
      </DragDropContext>
      <ConfirmDeleteDialog
        entity="file"
        isOpen={deleteModalOpen}
        handleDeleteClose={() => setDeleteModalOpen(false)}
        handleDeleteField={() =>
          handleFileDelete(selectedItem as unknown as DealAttachmentRead)
        }
      />
      <AddEditModal
        open={modalOpen}
        mode={modalMode}
        item={selectedItem?.id || null}
        onClose={() => setModalOpen(false)}
        onSubmit={handleModalSubmit}
        initialName={
          attachments.find((att) => att.id === selectedItem?.id)?.file_name ||
          selectedItem?.name ||
          ""
        }
      />
    </>
  );
};

export const DealDocumentsField = () => {
  const [idField] = useField("id");
  return <DealDocumentsSection dealId={idField.value} />;
};

export const DealDocumentsDetail = ({
  record,
  fieldName,
  label,
}: {
  record: any;
  fieldName: string;
  label: string;
}) => {
  return <DealDocumentsSection dealId={record.id} readOnly={true} />;
};
