import * as Yup from "yup";

import { dateSubmitHandler } from "pages/deal/utils/deal_form";
import { FormObjectMetadata } from "types/formMetadata";

export const LedgerTransactionMetadata: FormObjectMetadata = {
  description: {
    fieldName: "description",
    displayName: "Description",
    _schema: Yup.string(),
    initialValue: "",
  },
  effective_date: {
    fieldName: "effective_date",
    displayName: "Effective Date",
    _schema: Yup.date(),
    initialValue: null,
    submitHandler: dateSubmitHandler,
  },
  reference_id: {
    fieldName: "reference_id",
    displayName: "Reference ID",
    _schema: Yup.string(),
    initialValue: "",
  },
};
