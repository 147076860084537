import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { startCase } from "lodash";

import { SimpleButton } from "components/SimpleButton";

export const ConfirmDeleteDialog = ({
  entity,
  isOpen,
  handleDeleteClose,
  handleDeleteField,
}: {
  entity: string;
  isOpen: boolean;
  handleDeleteClose: () => void;
  handleDeleteField: () => void;
}) => (
  <Dialog
    open={isOpen}
    onClose={handleDeleteClose}
    maxWidth="xs"
    sx={{ "& .MuiDialog-paper": { padding: "16px" } }}
  >
    <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
      Delete {startCase(entity)}
    </DialogTitle>
    <DialogContent>
      <Typography sx={{ textAlign: "center", marginBottom: "16px" }}>
        Are you sure you want to delete this {entity.toLowerCase()}?
      </Typography>
    </DialogContent>
    <Stack spacing={2} direction="row" justifyContent="center">
      <SimpleButton
        variant="contained"
        color="error"
        onClick={() => {
          handleDeleteField();
        }}
        sx={{ minWidth: "100px" }}
      >
        Delete
      </SimpleButton>
      <SimpleButton
        variant="outlined"
        onClick={handleDeleteClose}
        sx={{ minWidth: "100px" }}
      >
        Cancel
      </SimpleButton>
    </Stack>
  </Dialog>
);
