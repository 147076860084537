import { Grid } from "@mui/material";
import React from "react";

import { SelectOption } from "components/form/SelectOption";
import { TextFieldWholeNumber } from "components/form/TextFieldWholeNumber";
import { LOT_SIZE_TYPE_OPTIONS } from "constants/property";

export const PropertyLotSizeField = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextFieldWholeNumber
          fieldName={"lot_size"}
          displayName={"Lot Size"}
          decimalScale={4}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectOption
          fieldName={"lot_size_type"}
          displayName={""}
          options={LOT_SIZE_TYPE_OPTIONS}
        />
      </Grid>
    </Grid>
  );
};
