import { useFormikContext } from "formik";
import { get, isNil } from "lodash";

import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { SizeTypeEnum } from "types/api/deal/enum";
import { FieldComponentProps } from "types/standardForm";

export const DealAssetTypeField = (props: FieldComponentProps) => {
  const { setFieldValue, values } = useFormikContext();

  const additionalChangeHandler = async (value: string | null) => {
    if (!value) return;
    // If no values have been entered for Size (est or actual), autofill the Size Type field
    const textValue = value;
    if (isNil(get(values, "size_est")) && isNil(get(values, "size"))) {
      if (["Land"].includes(textValue)) {
        await setFieldValue("size_type", SizeTypeEnum.acres);
      } else if (
        ["Multifamily", "Senior / Assisted Living"].includes(textValue)
      ) {
        await setFieldValue("size_type", SizeTypeEnum.units);
      } else {
        await setFieldValue("size_type", SizeTypeEnum.square_feet);
      }
    }
  };
  return (
    <DealOptionAutocompleteNew
      {...props}
      additionalChangeHandler={additionalChangeHandler}
    />
  );
};
