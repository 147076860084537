import { Clear } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import { find, get, sumBy } from "lodash";
import React from "react";

import { DatePicker } from "components/form/DatePicker";
import { DETAIL_TYPE_ITEMS_MAP } from "components/form/forms/voucher/TransactionDetailsField";
import { SelectOption } from "components/form/SelectOption";
import { formatDateForDisplay } from "components/form/standard/utils/formatting";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldPercent } from "components/form/TextFieldPercent";
import { TextFieldWholeNumber } from "components/form/TextFieldWholeNumber";
import { DEAL_TYPE_OPTIONS } from "pages/deal/constants/deal_enums";
import {
  formatCurrency,
  formatPercentage,
  formatWholeNumber,
} from "pages/deal/utils/reporting";
import { DealExtended } from "types/api/deal/dealExtended";
import {
  DetailTypeEnum,
  TransactionDetailRead,
} from "types/api/deal/transaction_detail";

const LeaseTable = ({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailRead[];
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Months</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>Rent/Month</TableCell>
          <TableCell>Total Rent</TableCell>
          <TableCell>Commission %</TableCell>
          <TableCell>Commission Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactionDetails.map((detail: TransactionDetailRead, index: any) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>

            {/*Type Field */}
            <TableCell>
              {get(DETAIL_TYPE_ITEMS_MAP, detail.detail_type, "--")}
            </TableCell>

            {/* Months (Quantity) */}
            <TableCell>{formatWholeNumber(detail.quantity)}</TableCell>

            {/* Start Date */}
            <TableCell>{formatDateForDisplay(detail.date_start)}</TableCell>

            {/* End Date */}
            <TableCell>{formatDateForDisplay(detail.date_end)}</TableCell>

            {/* Rent/Month */}
            <TableCell>{formatCurrency(detail.total_per_quantity)}</TableCell>

            {/* Total Rent */}
            <TableCell>{formatCurrency(detail.total_amount)}</TableCell>

            {/* Commission % */}
            <TableCell>{formatPercentage(detail.commission_percent)}</TableCell>

            {/* Commission Amount */}
            <TableCell>{formatCurrency(detail.commission_amount)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      {/* Footer for totals */}
      <TableFooter>
        <TableRow>
          <TableCell colSpan={8} align="right">
            <strong>Totals:</strong>
          </TableCell>
          <TableCell align="right">
            <Typography fontWeight={600}>
              {formatCurrency(sumBy(transactionDetails, "commission_amount"))}
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const SaleTable = ({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailRead[];
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Sale Price</TableCell>
          <TableCell>$/SF</TableCell>
          <TableCell>Commission %</TableCell>
          <TableCell>Commission Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactionDetails.map((detail: TransactionDetailRead, index: any) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>

            {/*Type Field */}
            <TableCell>{detail.detail_type}</TableCell>

            {/* Sale Price */}
            <TableCell>{formatCurrency(detail.total_per_quantity)}</TableCell>

            {/* Commission % */}
            <TableCell>{formatPercentage(detail.commission_percent)}</TableCell>

            {/* Commission Amount */}
            <TableCell>{formatCurrency(detail.commission_amount)}</TableCell>
          </TableRow>
        ))}
      </TableBody>

      {/* Footer for totals */}
      <TableFooter>
        <TableRow>
          <TableCell colSpan={4} align="right">
            <strong>Totals:</strong>
          </TableCell>
          <TableCell align="right">
            <Typography fontWeight={600}>
              {formatCurrency(sumBy(transactionDetails, "commission_amount"))}
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const OtherTable = ({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailRead[];
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Commission Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactionDetails.map((detail: TransactionDetailRead, index: any) => (
          <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>

            {/*Type Field */}
            <TableCell>{detail.detail_type}</TableCell>

            {/* Commission Amount */}
            <TableCell>{formatCurrency(detail.commission_amount)}</TableCell>
          </TableRow>
        ))}
      </TableBody>

      {/* Footer for totals */}
      <TableFooter>
        <TableRow>
          <TableCell colSpan={2} align="right">
            <strong>Totals:</strong>
          </TableCell>
          <TableCell align="right">
            <Typography fontWeight={600}>
              {formatCurrency(sumBy(transactionDetails, "commission_amount"))}
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const TransactionDetailsDetail = ({
  record,
  fieldName,
  label,
}: {
  record: DealExtended;
  fieldName: string;
  label: string;
}) => {
  const dealCategory = get(
    find(DEAL_TYPE_OPTIONS, { key: record.deal_type }),
    "category"
  );

  if (!dealCategory) return "Select a deal type";

  return (
    <>
      <TableContainer>
        {(() => {
          switch (dealCategory) {
            case "lease":
              return (
                <LeaseTable transactionDetails={record.transaction_details} />
              );
            case "sale":
              return (
                <SaleTable transactionDetails={record.transaction_details} />
              );
            case "other":
              return (
                <OtherTable transactionDetails={record.transaction_details} />
              );
          }
        })()}
      </TableContainer>
    </>
  );
};

export default TransactionDetailsDetail;
