import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import { BASE_LEDGER_TRANSACTION_FILTERS } from "constants/filters/ledgerTransactionFilters";
import { parseParamsAsFilters } from "pages/deal/utils/deal";
import { dispatch } from "store";
import { SortOrderEnum } from "types/navigation/common";
import {
  LedgerTransactionFilter,
  LedgerTransactionNavigation,
} from "types/navigation/ledgerTransactionNavigation";
import { RecordIdentifier } from "types/record";
import { captureUIEvent } from "utils/eventCapture";

const initialState: {
  initial: LedgerTransactionFilter;
  published: LedgerTransactionFilter;
  pending: Partial<LedgerTransactionFilter>;
  navigation: LedgerTransactionNavigation;
} = {
  initial: {
    ...BASE_LEDGER_TRANSACTION_FILTERS,
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  },
  published: {
    ...BASE_LEDGER_TRANSACTION_FILTERS,
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  },
  pending: {},
  navigation: {
    hiddenFilters: [],
    disabledFilters: [],
  },
};

const ledgerTransactionNavigation = createSlice({
  name: "ledgerTransactionNavigation",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.pending = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.pending = {};
    },
    setPublishedFilters(state, action) {
      state.published = action.payload;
      state.pending = {};
    },
    setInitialAndPublishedFilters(state, action) {
      state.initial = action.payload;
      state.published = action.payload;
      state.pending = {};
    },
    applyFilters(state) {
      captureUIEvent("filters_applied", {
        recordType: RecordIdentifier.LedgerTransaction,
        filters: { ...state.published, ...state.pending },
      });

      state.published = { ...state.published, ...state.pending };
      state.pending = {};
    },
    resetFilters(state) {
      captureUIEvent("filters_reset", {
        recordType: RecordIdentifier.LedgerTransaction,
      });

      state.published = state.initial;
      state.pending = {};
    },
    setNavigation(state, action) {
      state.navigation = action.payload;
    },
    setSort(state, action) {
      const { sort, sort_order } = action.payload;

      if (
        !isEqual(state.published.sort, sort) ||
        !isEqual(state.published.sort_order, sort_order)
      ) {
        state.published = { ...state.published, ...action.payload };
      }
    },
  },
});

export default ledgerTransactionNavigation.reducer;

export const setInitialLedgerTransactionFilters = (
  filters: LedgerTransactionFilter
) => dispatch(ledgerTransactionNavigation.actions.setInitialFilters(filters));
export const setPublishedLedgerTransactionFilters = (
  filters: LedgerTransactionFilter
) => dispatch(ledgerTransactionNavigation.actions.setPublishedFilters(filters));
export const setInitialAndPublishedLedgerTransactionFilters = (
  filters: LedgerTransactionFilter
) =>
  dispatch(
    ledgerTransactionNavigation.actions.setInitialAndPublishedFilters(filters)
  );
export const setLedgerTransactionFilters = (
  filters: Partial<LedgerTransactionFilter>
) => dispatch(ledgerTransactionNavigation.actions.setFilters(filters));
export const applyLedgerTransactionFilters = () =>
  dispatch(ledgerTransactionNavigation.actions.applyFilters());
export const resetLedgerTransactionFilters = () =>
  dispatch(ledgerTransactionNavigation.actions.resetFilters());

export const setLedgerTransactionNavigation = (
  navigation: LedgerTransactionNavigation
) => dispatch(ledgerTransactionNavigation.actions.setNavigation(navigation));
export const setLedgerTransactionSort = (
  sort: string[],
  sort_order: string[]
) =>
  dispatch(ledgerTransactionNavigation.actions.setSort({ sort, sort_order }));

export function setInitialLedgerTransactionFilterState(
  initialFilters: LedgerTransactionFilter,
  searchParams: URLSearchParams
) {
  const { queryFilters, queryFiltersExist } = parseParamsAsFilters(
    BASE_LEDGER_TRANSACTION_FILTERS,
    searchParams
  );
  if (queryFiltersExist) {
    setInitialLedgerTransactionFilters(initialFilters);
    setPublishedLedgerTransactionFilters({
      ...initialFilters,
      ...queryFilters,
    });
  } else {
    setInitialAndPublishedLedgerTransactionFilters(initialFilters);
  }
}
