import { Place } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import config from "config";
import { checkLicense } from "hooks/useCheckLicense";
import back_office from "menu-items/back_office";
import prospects from "menu-items/prospects";
import { AccessTokenRead } from "types/api/user_management/access_token";
import { Product } from "types/license";
import { NavItemType } from "types/menu";

import deal_view from "./deal_view";

// ==============================|| MENU ITEMS ||============================== //

export const getMenuItems: (user: AccessTokenRead) => {
  items: NavItemType[];
} = (user) => {
  const isProspects = checkLicense(user, [Product.prospects]);
  const isDVPro = checkLicense(user, [Product.deal_view_pro]);
  const isBackOffice =
    config.features.back_office && checkLicense(user, [Product.back_office]);

  let items = [];

  // Prospects menu items
  if (isProspects) items.push(prospects);
  // Deal View menu items (can be different depending on if they have Prospects or not)
  if (isDVPro) {
    if (isProspects) {
      items.push(deal_view);
    } else {
      // Insert the Properties nav item under children if they do not have a Prospects license
      items.push({
        ...deal_view,
        children: deal_view.children && [
          ...deal_view.children,
          {
            id: "properties",
            title: <FormattedMessage id="properties" />,
            type: "item",
            url: "/properties/overview",
            icon: Place,
          },
        ],
      });
    }
  }

  if (isBackOffice) items.push(back_office);

  return { items };
};
