import { AutoFixHigh } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form, Formik, FormikConfig, useFormikContext } from "formik";
import _, { pick } from "lodash";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";

import IconButton from "components/@extended/IconButton";
import { StandardGridField } from "components/form/deal/DealFormSection";
import { DealClosedFieldsFormDialogActions } from "components/form/standard/DealClosedFieldsFormDialogActions";
import DynamicVisibilitySection from "components/form/standard/DynamicVisibilitySection";
import StandardField from "components/form/standard/StandardField";
import {
  getDealFormInitialValues,
  getDealFormSchema,
  loadDealFormData,
  prepareDealFormDataForSubmit,
} from "constants/deal/deal_form";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { useDealOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { EditingProvider } from "contexts/EditingContext";
import { useReplaceForm } from "contexts/FormDialogsContext";
import { useUsersDisplay } from "contexts/UserDisplayContext";
import { YupDescriptionProvider } from "contexts/YupDescriptionContext";
import useAuth from "hooks/useAuth";
import { dispatch } from "store";
import { openErrorNotification } from "store/reducers/common";
import { DealExtended } from "types/api/deal/dealExtended";
import { DealStatusEnum } from "types/api/deal/enum";
import { DealClosedFieldsFormikValues } from "types/form/dealForm";
import { dealService } from "utils/axios";
import { openNotification } from "utils/notistack";

const DealClosedFormHeader = () => {
  const [showButton, setShowButton] = useState(true);
  const { setValues } = useFormikContext();

  const handleAutoFill = () => {
    setValues((values: any) => {
      const updatedValues = {
        transaction_value: _.get(
          values,
          DealMetadata.transaction_value_est.fieldName
        ),
        commission: _.get(values, DealMetadata.commission_est.fieldName),
        size: _.get(values, DealMetadata.size_est.fieldName),
        commission_split: _.get(
          values,
          DealMetadata.commission_split.fieldName
        ).map((commission: any) => ({
          ...commission,
          actual_percent: commission.estimate_percent,
        })),
      };
      return { ...values, ...updatedValues };
    });
    setShowButton(false);
  };

  return (
    <Grid container justifyContent="space-between">
      <DialogTitle>Close Deal</DialogTitle>
      {showButton && (
        <Button
          size="small"
          onClick={handleAutoFill}
          sx={{ textTransform: "none", pr: 2 }}
        >
          <IconButton>
            <AutoFixHigh />
          </IconButton>
          Auto-fill from estimates
        </Button>
      )}
    </Grid>
  );
};

interface DealClosedFieldsFormProps {
  id: string;
  changes?: Partial<DealClosedFieldsFormikValues>;
  handleClose: () => void;
}

const DealClosedFieldsForm = ({
  id,
  changes,
  handleClose,
}: DealClosedFieldsFormProps) => {
  const { user } = useAuth();
  const usersDisplay = useUsersDisplay();
  const replaceForm = useReplaceForm();
  const queryClient = useQueryClient();

  const { data: orgPreferences } = useDealOrgPreferences();

  const { data: record } = useQuery<DealExtended>({
    queryKey: `/${id}`,
    queryFn: () =>
      dealService.get(`/${id}`).then((response) => {
        return response.data.data;
      }),
  });

  const [submitAction, setSubmitAction] = useState<
    "save" | "save_and_create_voucher"
  >("save");

  const initialValuesWithData = useMemo(
    () => ({
      ...getDealFormInitialValues(user),
      ...(record ? loadDealFormData(record, usersDisplay) : {}),
      ...changes,
    }),
    [changes, record, user, usersDisplay]
  );

  const validationSchema = useMemo(() => {
    const dealFormSchema = getDealFormSchema(orgPreferences);
    return Yup.object().shape(
      pick(dealFormSchema, [
        "close_date",
        "commission",
        "commission_payments",
        "commission_split",
        "lease_expiration",
        "size",
        "size_type",
        "transaction_value",
        "status",
        "commission_est",
        "transaction_value_est",
        "size_est",
        "close_date_est",
        "commission_payments_percent_toggle",
        "commission_split_percent_toggle",
      ])
    );
  }, [orgPreferences]);

  const onSubmit: FormikConfig<DealClosedFieldsFormikValues>["onSubmit"] =
    useMemo(
      () =>
        async (values: DealClosedFieldsFormikValues, { setSubmitting }) => {
          try {
            const preparedValues = prepareDealFormDataForSubmit(values);
            const fieldsToSubmit = pick(preparedValues, [
              "close_date",
              "commission",
              "commission_payments",
              "commission_payments_percent_toggle",
              "commission_split",
              "commission_split_percent_toggle",
              "lease_expiration",
              "size",
              "size_type",
              "transaction_value",
              "status",
            ]);
            await dealService.put(`/${id}`, fieldsToSubmit);
            openNotification(`Deal updated.`, "success");
            // Invalidate all queries (supports legacy functionality)
            queryClient.invalidateQueries();
            // TODO: Migrate to targeted query invalidation (forms, tables, charts, etc)
            // await queryClient.invalidateQueries(getDealQueryKey(id));
            handleClose();
          } catch (error) {
            dispatch(openErrorNotification("Error updating deal."));
          } finally {
            setSubmitting(false);
          }
        },
      [handleClose, id, queryClient, replaceForm, submitAction]
    );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik<DealClosedFieldsFormikValues>
        initialValues={initialValuesWithData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ values }) => (
          <YupDescriptionProvider
            objectSchema={validationSchema}
            values={values}
          >
            <Form>
              <Dialog open={true} scroll={"paper"} maxWidth={"lg"}>
                <DealClosedFormHeader />
                <DialogContent dividers>
                  <Grid container columnSpacing={5} rowSpacing={4} columns={24}>
                    <EditingProvider>
                      <Grid item xs={24} sm={6}>
                        <StandardField field={DealMetadata.close_date_est} />
                      </Grid>
                      <Grid item xs={24} sm={6}>
                        <StandardField
                          field={DealMetadata.transaction_value_est}
                        />
                      </Grid>
                      <Grid item xs={24} sm={6}>
                        <StandardField field={DealMetadata.commission_est} />
                      </Grid>
                      <Grid item xs={24} sm={3}>
                        <StandardField field={DealMetadata.size_est} />
                      </Grid>
                      <Grid item xs={24} sm={3}>
                        <StandardField field={DealMetadata.size_type} />
                      </Grid>
                    </EditingProvider>
                    <DynamicVisibilitySection
                      visibilityFunction={(x) =>
                        _.get(x, "status") === DealStatusEnum.closed
                      }
                    >
                      <Grid item xs={24} sm={6}>
                        <StandardField field={DealMetadata.close_date} />
                      </Grid>
                      <Grid item xs={24} sm={6}>
                        <StandardField field={DealMetadata.transaction_value} />
                      </Grid>
                      <Grid item xs={24} sm={6}>
                        <StandardField field={DealMetadata.commission} />
                      </Grid>
                      <StandardGridField
                        item
                        xs={24}
                        sm={3}
                        field={DealMetadata.size}
                      />
                      <StandardGridField
                        item
                        xs={24}
                        sm={3}
                        field={DealMetadata.size_type}
                      />
                      <StandardGridField
                        item
                        xs={24}
                        sm={6}
                        field={DealMetadata.lease_expiration}
                      />
                    </DynamicVisibilitySection>
                  </Grid>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                    <StandardGridField
                      item
                      xs={12}
                      field={DealMetadata.commission_split}
                      estimateReadOnly
                    />
                    <StandardGridField
                      item
                      xs={12}
                      field={DealMetadata.commission_payments}
                    />
                  </Grid>
                </DialogContent>
                <DealClosedFieldsFormDialogActions
                  setSubmitAction={setSubmitAction}
                />
              </Dialog>
            </Form>
          </YupDescriptionProvider>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

const DealClosedFieldsFormPage = ({
  id,
  handleClose,
  changes,
}: {
  id?: string;
  handleClose: () => void;
  changes?: Partial<DealClosedFieldsFormikValues>;
}) => {
  return id ? (
    <DealClosedFieldsForm id={id} handleClose={handleClose} changes={changes} />
  ) : null;
};

export default DealClosedFieldsFormPage;
