import { lazy, ReactNode } from "react";
import { Navigate } from "react-router";

import Loadable from "components/Loadable";
import config from "config";
import { DealOrgPreferencesProvider } from "contexts/DealOrgPreferencesContext";
import { DealUserPreferencesProvider } from "contexts/DealUserPreferencesContext";
import { UserDisplayProvider } from "contexts/UserDisplayContext";
import MainLayout from "layout/MainLayout";
import { DealViewFormPage } from "pages/deal/sections/DealFormPage";
import NotFound from "pages/NotFound";
import { BaseRoute } from "types/license";
import AuthGuard from "utils/route-guard/AuthGuard";
import SubscriptionGuard from "utils/route-guard/SubscriptionGuard";

const DealTabs = Loadable(lazy(() => import("pages/deal/DealTabs")));
const OnboardingFlow = Loadable(
  lazy(() => import("pages/onboarding/OnboardingFlow"))
);

const DealDashboardOverview = Loadable(
  lazy(() => import("pages/deal/tabs/Dashboard"))
);
const DealTabCommissionInsights = Loadable(
  lazy(() => import("pages/deal/tabs/CommissionInsights"))
);
const DealTabBrokerInsights = Loadable(
  lazy(() => import("pages/deal/tabs/BrokerInsights"))
);
const DealTabBusinessInsights = Loadable(
  lazy(() => import("pages/deal/tabs/BusinessInsights"))
);
const DealTabComparisons = Loadable(
  lazy(() => import("pages/deal/tabs/Comparisons"))
);

// Deal Overview
const DealOverviewTabs = Loadable(
  lazy(() => import("pages/deal/tabs/Overview"))
);
const DealOverviewList = Loadable(
  lazy(() => import("pages/deal/sections/OverviewList"))
);
const DealOverviewBoard = Loadable(
  lazy(() => import("pages/deal/sections/OverviewBoard"))
);
const DealFormPageV2 = Loadable(
  lazy(() => import("components/form_v2/editable/deal/DealForm"))
);

const UploadCSV = Loadable(lazy(() => import("pages/data_import/UploadCSV")));
const PropertyImportPage = Loadable(
  lazy(() => import("pages/data_import/PropertyImportPage"))
);
const PropertyImportPageOld = Loadable(
  lazy(() => import("pages/data_import/PropertyImportPage_Old"))
);
const NotificationAdmin = Loadable(
  lazy(() => import("pages/admin/NotificationAdmin"))
);
const DeveloperSettings = Loadable(
  lazy(() => import("pages/admin/DeveloperSettings"))
);

// Prospects
const ProspectsTabs = Loadable(
  lazy(() => import("pages/prospects/ProspectsTabs"))
);
const ProspectsDashboard = Loadable(
  lazy(() => import("pages/prospects/ProspectsDashboard"))
);
const ContactsOverview = Loadable(
  lazy(() => import("pages/prospects/contacts/ContactsOverview"))
);
const ProspectsOverview = Loadable(
  lazy(() => import("pages/prospects/prospects/ProspectsOverview"))
);
const PropertyOverview = Loadable(
  lazy(() => import("pages/properties/PropertyOverview"))
);
const ProspectInsights = Loadable(
  lazy(() => import("pages/prospects/prospects/ProspectInsights"))
);

//Back office
const BackOfficeTabs = Loadable(
  lazy(() => import("pages/back-office/BackOfficeTabs"))
);
const BackOfficeUIKit = Loadable(
  lazy(() => import("pages/back-office/vouchers/overview/VouchersOverview"))
);
const BackOfficeVouchersOverview = Loadable(
  lazy(() => import("pages/back-office/vouchers/overviewV2/VouchersOverview"))
);
const VoucherFormPage = Loadable(
  lazy(() => import("components/form_v2/editable/deal/VoucherForm"))
);
const LedgerTransactionOverview = Loadable(
  lazy(
    () =>
      import("pages/back-office/accounting/overview/LedgerTransactionOverview")
  )
);
const BackOfficeInvoicesOverview = Loadable(
  lazy(() => import("pages/back-office/invoices/overview/InvoicesOverview"))
);
const BackOfficeReportsOverview = Loadable(
  lazy(() => import("pages/back-office/reports/overview/ReportsOverview"))
);

// render - account settings
const AccountSettings = Loadable(
  lazy(() => import("pages/settings/account/account"))
);
const AccountTabBilling = Loadable(
  lazy(() => import("pages/settings/account/sections/TabBilling"))
);
// render - team settings
const TeamSettings = Loadable(lazy(() => import("pages/settings/team/team")));
const TeamTabRole = Loadable(
  lazy(() => import("pages/settings/team/sections/TabRole"))
);
const TabTeamView = Loadable(
  lazy(() => import("pages/settings/team/sections/TabTeamsView"))
);
const TabStagesView = Loadable(
  lazy(() => import("pages/settings/team/sections/TabStagesView"))
);
const TabDataManagement = Loadable(
  lazy(() => import("pages/settings/team/sections/TabDataManagement"))
);
const TabFeatures = Loadable(
  lazy(() => import("pages/settings/team/sections/TabFeatures"))
);
const TabAppCustomization = Loadable(
  lazy(() => import("pages/settings/team/sections/TabAppCustomization"))
);
const TabFormTemplate = Loadable(
  lazy(() => import("pages/settings/team/sections/TabFormTemplate"))
);
const TabFormTemplatesList = Loadable(
  lazy(() => import("pages/settings/team/sections/TabFormTemplatesList"))
);
const TabDealFields = Loadable(
  lazy(() => import("pages/settings/team/sections/TabDealFields"))
);
const TeamTabUsers = Loadable(
  lazy(() => import("pages/settings/team/sections/TabUsers"))
);
// render - user settings
const UserSettings = Loadable(lazy(() => import("pages/settings/user/user")));
const UserTabProfile = Loadable(
  lazy(() => import("pages/settings/user/tabs/TabProfile"))
);
const UserTabPassword = Loadable(
  lazy(() => import("pages/settings/user/tabs/TabPassword"))
);
const UserTabSettings = Loadable(
  lazy(() => import("pages/settings/user/tabs/TabSettings"))
);
const UserTabIntegrations = Loadable(
  lazy(() => import("pages/settings/user/tabs/TabIntegrations"))
);
const UserTabEmailIntegrationsSection = Loadable(
  lazy(
    () =>
      import(
        "pages/settings/user/tabs/IntegrationsContent/views/EmailIntegrationsView"
      )
  )
);
const UserTabEmailLoggingSection = Loadable(
  lazy(
    () =>
      import(
        "pages/settings/user/tabs/IntegrationsContent/views/EmailLoggingView"
      )
  )
);
const UserTabContactsIntegrationsSection = Loadable(
  lazy(
    () =>
      import(
        "pages/settings/user/tabs/IntegrationsContent/views/ContactsIntegrationsView"
      )
  )
);

// ==============================|| MAIN ROUTING ||============================== //
const AuthGuardWithPreferences = ({ children }: { children: ReactNode }) => (
  <AuthGuard>
    <DealOrgPreferencesProvider>
      <DealUserPreferencesProvider>
        <UserDisplayProvider>{children}</UserDisplayProvider>
      </DealUserPreferencesProvider>
    </DealOrgPreferencesProvider>
  </AuthGuard>
);

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "setup",
      element: (
        <AuthGuardWithPreferences>
          <OnboardingFlow />
        </AuthGuardWithPreferences>
      ),
    },
    {
      path: "/",
      element: (
        <AuthGuardWithPreferences>
          <MainLayout />
        </AuthGuardWithPreferences>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <SubscriptionGuard>
              <DealDashboardOverview />
            </SubscriptionGuard>
          ),
        },
        {
          path: "upload_csv",
          element: <UploadCSV />,
        },
        {
          path: "notification_admin",
          element: <NotificationAdmin />,
        },
        { path: "deals/:id?", element: <DealViewFormPage /> },
        { path: "deals_v2/:id?", element: <DealFormPageV2 /> },
        {
          path: "properties/overview",
          element: <PropertyOverview />,
        },
        {
          path: BaseRoute.deals,
          element: (
            <SubscriptionGuard>
              <DealTabs />
            </SubscriptionGuard>
          ),
          children: [
            {
              path: "overview",
              element: <DealOverviewTabs />,
              children: [
                {
                  path: "list",
                  element: <DealOverviewList />,
                },
                { path: "board", element: <DealOverviewBoard /> },
              ],
            },
            {
              path: "insights",
              children: [
                {
                  path: "commission",
                  element: <DealTabCommissionInsights />,
                },
                {
                  path: "business",
                  element: <DealTabBusinessInsights />,
                },
                {
                  path: "broker",
                  element: <DealTabBrokerInsights />,
                },
              ],
            },
            {
              path: "comparisons",
              children: [
                {
                  path: "goals",
                  element: <DealTabComparisons />,
                },
                {
                  path: "leaderboard",
                  element: <DealTabComparisons />,
                },
              ],
            },
          ],
        },
        {
          path: BaseRoute.prospects,
          element: (
            <SubscriptionGuard>
              <ProspectsTabs />
            </SubscriptionGuard>
          ),
          children: [
            {
              path: "dashboard",
              element: <ProspectsDashboard />,
            },
            {
              path: "contacts",
              element: <ContactsOverview />,
            },
            {
              path: "overview",
              element: <ProspectsOverview />,
            },
            {
              path: "insights",
              element: <ProspectInsights />,
            },
          ],
        },
        {
          path: BaseRoute.back_office,
          element: config.features.back_office ? (
            <SubscriptionGuard>
              <BackOfficeTabs />
            </SubscriptionGuard>
          ) : (
            <Navigate to="/" replace />
          ),
          children: [
            {
              index: true,
              element: <Navigate to="/back-office/vouchers/overview" replace />,
            },
            { path: "vouchers/:id?", element: <VoucherFormPage /> },
            {
              path: "vouchers",
              children: [
                {
                  path: "overview",
                  element: <BackOfficeVouchersOverview />,
                },
              ],
            },
            {
              path: "invoices",
              children: [
                {
                  path: "overview",
                  element: <BackOfficeInvoicesOverview />,
                },
              ],
            },
            {
              path: "accounting",
              children: [
                {
                  path: "overview",
                  element: <LedgerTransactionOverview />,
                },
              ],
            },
            {
              path: "reports",
              children: [
                {
                  path: "overview",
                  element: <BackOfficeReportsOverview />,
                },
              ],
            },
            {
              path: "uikit",
              element: <BackOfficeUIKit />,
            },
          ],
        },

        {
          path: "settings",
          children: [
            {
              path: "account",
              element: <AccountSettings />,
              children: [
                {
                  path: "billing",
                  element: <AccountTabBilling />,
                },
              ],
            },
            {
              path: "team",
              element: <TeamSettings />,
              children: [
                {
                  path: "role",
                  element: <TeamTabRole />,
                },
                {
                  path: "users",
                  element: <TeamTabUsers />,
                },
                {
                  path: "team",
                  element: <TabTeamView />,
                },
                {
                  path: "customize",
                  children: [
                    {
                      path: "form-templates/:form-template",
                      element: <TabFormTemplate />,
                    },
                  ],
                },
                {
                  path: "customize",
                  element: <TabAppCustomization />,
                  children: [
                    {
                      index: true, // This will match the /customize path
                      element: <Navigate to="form-templates" replace />,
                    },
                    {
                      path: "deal-features",
                      element: <TabFeatures />,
                    },
                    {
                      path: "deal-fields",
                      element: <TabDealFields />,
                    },
                    {
                      path: "deal-stages",
                      element: <TabStagesView />,
                    },

                    {
                      path: "form-templates",
                      element: <TabFormTemplatesList />,
                    },
                  ],
                },
                {
                  path: "data",
                  element: <TabDataManagement />,
                },
              ],
            },
            {
              path: "user",
              element: <UserSettings />,
              children: [
                {
                  path: "profile",
                  element: <UserTabProfile />,
                },
                {
                  path: "password",
                  element: <UserTabPassword />,
                },
                {
                  path: "settings",
                  element: <UserTabSettings />,
                },
                {
                  path: "integrations",
                  element: <UserTabIntegrations />,
                  children: [
                    {
                      path: "",
                      element: config.features.contact_sync ? (
                        <Navigate
                          to="/settings/user/integrations/contacts-integrations"
                          replace
                        />
                      ) : (
                        <Navigate
                          to="/settings/user/integrations/email-integrations"
                          replace
                        />
                      ),
                    },
                    {
                      path: "email-integrations",
                      element: <UserTabEmailIntegrationsSection />,
                    },
                    {
                      path: "email-logging",
                      element: <UserTabEmailLoggingSection />,
                    },
                    {
                      path: "contacts-integrations",
                      element: config.features.contact_sync ? (
                        <UserTabContactsIntegrationsSection />
                      ) : (
                        <Navigate
                          to="/settings/user/integrations/email-integrations"
                          replace
                        />
                      ),
                    },
                  ],
                },
                {
                  path: "import",
                  element: <PropertyImportPage />,
                },
                {
                  path: "import_old",
                  element: <PropertyImportPageOld />,
                },
              ],
            },
          ],
        },
        {
          path: "secret-developer-page",
          element: <DeveloperSettings />,
        },
      ],
    },
    { path: "*", element: <NotFound /> },
  ],
};

export default MainRoutes;
