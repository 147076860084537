import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import _, { isEqual } from "lodash";
import reduce from "lodash/reduce";
import * as Yup from "yup";

import config from "config";
import { parseDealStage } from "pages/deal/utils/deal_form";
import { CommissionRead } from "types/api/deal/commissions";
import { DealExtended } from "types/api/deal/dealExtended";
import { DealStatusEnum } from "types/api/deal/enum";
import { SimpleOption } from "types/api/deal/form";
import { Keyed } from "types/common";
import { Deal, DealStage, DealType } from "types/deal";
import { OutstandingPaymentRow } from "types/dealReporting";
import {
  BaseFilter,
  filterEmpty,
  SortOrderEnum,
} from "types/navigation/common";

dayjs.extend(minMax);

export const getSchemaFromMetadata = (metadata: { [key: string]: any }) => {
  return Yup.object(
    reduce(
      metadata,
      (accumulator: { [key: string]: any }, value, key) => {
        if ("_schema" in value) {
          // Base case
          accumulator[key] = value._schema;
        } else {
          // Recurse
          accumulator[key] = getSchemaFromMetadata(value);
        }
        return accumulator;
      },
      {}
    )
  );
};

export const getUserFullName = (user: any) =>
  user?.last_name?.length
    ? `${_.get(user, "first_name")} ${_.get(user, "last_name")}`
    : _.get(user, "first_name");

export function getCommaSeparatedOptionLabels(
  options: SimpleOption[],
  value: (string | number | null)[]
) {
  return _.join(
    _.map(
      _.filter(options, (x) => _.includes(value, x.key)),
      "label"
    ),
    ", "
  );
}

export const parseDealStageDictForFilter = (
  stageDict: Keyed<DealStage> | null,
  dealType?: string
) => {
  const specificStages = _.pickBy(stageDict, ["deal_type", dealType]);
  const ordered = _.map(_.sortBy(specificStages, "order"), "id");
  const display = _.mapValues(specificStages, (x) => `${x.value}`);
  const probability = _.mapValues(specificStages, (x) => x.probability);

  return { ordered, display, probability };
};

export const parseDealStageDict = (
  stageDict: Keyed<DealStage> | null,
  dealType: DealType
) => {
  return _.sortBy(
    _.map(_.pickBy(stageDict, { deal_type: dealType }), parseDealStage),
    "order"
  );
};

export function parseDateRangeAsStrV2(
  dateRange: dayjs.Dayjs[]
): string[] | null {
  return !!dateRange
    ? [
        dateRange[0].format(config.dateFormat),
        dateRange[1].format(config.dateFormat),
      ]
    : null;
}

export const parseDateRangeAsDayjsV2 = (
  dateRange: string[] | null
): dayjs.Dayjs[] | null => {
  if (!dateRange || !dateRange.length || isEqual(dateRange, filterEmpty)) {
    return null;
  }

  return [dayjs(dateRange[0]).startOf("day"), dayjs(dateRange[1]).endOf("day")];
};

export function parseFiltersAsParamsObject<T extends BaseFilter>(
  filters: T
): URLSearchParams {
  let queryFilters = { ...filters };

  const populatedFilters = _.pickBy(
    queryFilters,
    (val) => val && !isEqual(val, filterEmpty)
  );
  // Prepare as URLSearchParams object
  // See for details: https://stackoverflow.com/questions/38797509/passing-array-into-urlsearchparams-while-consuming-http-call-for-get-request
  const params = new URLSearchParams();

  _.map(populatedFilters, (value, key) => {
    if (_.isArray(value)) {
      _.map(value, (x) => params.append(key, _.toString(x)));
    } else {
      params.append(key, _.toString(value));
    }
  });

  return params;
}

// TODO: Remove once state is moved to URL parameters
const parseIdFilterParam = (x: string[]) =>
  _.map(x, (y) => (_.isNaN(_.parseInt(y)) ? y : _.parseInt(y)));
const passThrough = (x: string[]) => x;

const urlFilterPreProcessingMap = {
  asset_type_id: parseIdFilterParam,
  broker: parseIdFilterParam,
  contact_id: parseIdFilterParam,
  date_range: passThrough,
  deal_type: passThrough,
  broker_role: passThrough,
  property_name_id: parseIdFilterParam,
  stage_id: parseIdFilterParam,
  shared_deal: passThrough,
  sort: passThrough,
  sort_order: passThrough,
  status: passThrough,
  tenant_name_id: parseIdFilterParam,
  date_range_type: passThrough,
  has_outstanding_commission: passThrough,
  source_type_id: parseIdFilterParam,
  client_industry_id: parseIdFilterParam,
  submarket_id: parseIdFilterParam,
};

export function parseParamsAsFilters(
  baseFilters: Record<string, any>,
  searchParams: URLSearchParams
): { queryFilters: any; queryFiltersExist: boolean } {
  let queryFiltersExist = false;

  const queryFilters = _.mapValues(baseFilters, (val, key) => {
    let filterVal = val;
    if (!_.isNil(searchParams.get(key))) {
      queryFiltersExist = true;
      filterVal = searchParams.getAll(key);

      // Process each filter value
      // TODO: Move filter state up to url params, remove reliance on redux for filters
      if (_.isFunction(_.get(urlFilterPreProcessingMap, key))) {
        filterVal = _.invoke(urlFilterPreProcessingMap, key, filterVal);
      }
    }
    return filterVal;
  });

  return { queryFilters, queryFiltersExist };
}

export const openDealViewProDialog = () => {};

export const showDevLicenseToggle = (userId: number | string): boolean => {
  return (
    config.devLicenseToggle || `${userId}` === `${config.devLicenseUserId}`
  );
};

export const getChartRangeFilter = (
  selectedYear: number,
  selectedMonth: number,
  dateRange: string[] | null,
  isCumulative: boolean = false
): string[] | null => {
  // Determine time range that the bar corresponds to, accounting for
  // the potential that the filtered date range starts or ends mid-month
  const dayjsRange = parseDateRangeAsDayjsV2(dateRange);

  const monthStart = dayjs(`${selectedYear}-${selectedMonth}-01`).startOf(
    "month"
  );
  const monthEnd = dayjs(`${selectedYear}-${selectedMonth}-01`).endOf("month");

  let startDate = monthStart;
  let endDate = monthEnd;

  if (dayjsRange && dayjsRange.length) {
    startDate = isCumulative
      ? dayjsRange[0]
      : (dayjs.max(monthStart, dayjsRange[0]) as dayjs.Dayjs);
    endDate = dayjs.min(monthEnd, dayjsRange[1]) as dayjs.Dayjs;
  }

  return parseDateRangeAsStrV2([startDate, endDate]);
};

export function convertSortFromReactTable(sortBy: any) {
  const sort = sortBy?.id;
  const sort_order = sortBy?.desc ? SortOrderEnum.desc : SortOrderEnum.asc;
  return { sort: [sort], sort_order: [sort_order] };
}

export const pluralizeWord = (
  value: number | null | undefined,
  singularWord: string,
  pluralWord: string
) => {
  return value === 1 ? singularWord : pluralWord;
};

export const getActionText = (count: number | null | undefined) => {
  if (count) {
    return `View All (${count})`;
  } else {
    return "View All";
  }
};

export function mapCommissionToList(
  row: Deal | Partial<DealExtended> | OutstandingPaymentRow,
  usersDisplay: Record<number, string> | null
) {
  return _.chain(row?.commissions ?? [])
    .map((x: CommissionRead) => ({
      ...x,
      full_name: x.user_id && usersDisplay ? usersDisplay[x.user_id] : null,
    }))

    .orderBy((y) => {
      return (
        (_.get(row, "status", DealStatusEnum.closed) ===
          DealStatusEnum.closed &&
          //@ts-ignore
          y.actual_percent) ||
        //@ts-ignore
        y.estimate_percent ||
        0
      );
    }, "desc")
    .map("full_name")
    .filter(_.identity)
    .value();
}

export const mapCommissionsToNames = (
  usersDisplay: Record<number, string> | null,
  row: Deal | DealExtended | OutstandingPaymentRow
) => {
  return _.join(mapCommissionToList(row, usersDisplay), ", ");
};

export const formatRelativeDate = (date: Date | string | null | undefined) => {
  if (_.isNil(date)) {
    // Drop out early if empty
    return "Unknown";
  }

  const now = dayjs();
  const inputDate = dayjs(date);

  // Check for today, yesterday, and tomorrow
  if (inputDate.isSame(now, "day")) {
    return "Today";
  } else if (inputDate.isSame(now.subtract(1, "day"), "day")) {
    return "Yesterday";
  } else if (inputDate.isSame(now.add(1, "day"), "day")) {
    return "Tomorrow";
  }

  // Check if the date is within the current week (ending on Saturday)
  const startOfWeek = now.startOf("week");
  const endOfWeek = now.endOf("week").day(6); // Set the end of the week to Saturday

  if (inputDate.isAfter(startOfWeek) && inputDate.isBefore(endOfWeek)) {
    return inputDate.format("dddd");
  }

  // Original logic for this year or other years
  const thisYear = now.year();
  const dateYear = inputDate.year();

  return thisYear === dateYear
    ? inputDate.format("MMM D")
    : inputDate.format("MMM D, YYYY");
};
