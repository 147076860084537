import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldArray, Form, Formik } from "formik";
import React, { useMemo } from "react";
import * as Yup from "yup";

import { DatePicker } from "components/form/DatePicker";
import { SelectOption } from "components/form/SelectOption";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldString } from "components/form/TextFieldString";
import LogoIcon from "components/logo/LogoIcon";
import MainCard from "components/MainCard";
import ScrollTop from "components/ScrollTop";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { DealOptionAutocompleteNew } from "pages/deal/components/PlaintextAutocomplete";
import { DEAL_TYPE_OPTIONS } from "pages/deal/constants/deal_enums";
import { RootState, useSelector } from "store";
import { setDealQuickAddData } from "store/reducers/onboardingData";
import { DealStatusEnum } from "types/api/deal/enum";
import { DealQuickAddRecord, OnboardingStepProps } from "types/onboarding";

import { StepActions } from "../components/StepActions";

export const emptyDealQuickAddRow = {
  deal_type: null,
  name: null,
  asset_type: null,
  close_date_est: null,
  transaction_value_est: null,
  commission_est: null,
  status: DealStatusEnum.open,
};

export const DealQuickAddMetadata = {
  status: DealMetadata.status,
  asset_type: DealMetadata.asset_type,
  close_date_est: DealMetadata.close_date_est,
  commission_est: DealMetadata.commission_est,
  deal_type: DealMetadata.deal_type,
  name: DealMetadata.name,
  transaction_value_est: DealMetadata.transaction_value_est,
};

export const DealImportStep = ({
  handleNext,
  handleBack,
  setErrorIndex,
}: OnboardingStepProps) => {
  const { dealQuickAddData } = useSelector(
    (state: RootState) => state.onboardingData
  );

  // Define form schema
  const validationSchema = useMemo(
    () =>
      Yup.object({
        dealData: Yup.array().of(
          Yup.object().shape(
            {
              deal_type: Yup.string()
                .nullable()
                .when(["name"], {
                  is: (name: string) => !!name,
                  then: (schema) => schema.required("Deal Type is required."),
                  otherwise: (schema) => schema,
                }),
              name: Yup.string()
                .nullable()
                .max(255)
                .when(["deal_type"], {
                  is: (deal_type: string) => !!deal_type,
                  then: (schema) => schema.required("Deal Name is required."),
                  otherwise: (schema) => schema,
                }),
              asset_type: Yup.string().nullable(),
              close_date_est: Yup.date()
                .nullable()
                .typeError(`Estimated Close Date must be a date.`),
              transaction_value_est: Yup.number()
                .min(0, "Estimated Transaction Value must be positive.")
                .nullable()
                .typeError(`Estimated Transaction Value must be a number.`),
              commission_est: Yup.number()
                .nullable()
                .min(0, "Estimated Commission must be positive.")
                .typeError(`A number is expected for Estimated Commission.`),
            },
            [["deal_type", "name"]]
          )
        ),
      }),
    []
  );

  const saveProgressAndHandleBack = (
    values: { dealData: DealQuickAddRecord[] },
    isValid: boolean
  ) => {
    // If form is currently valid, save progress when they click Back
    if (isValid) setDealQuickAddData(values.dealData);
    // Navigate backwards
    handleBack();
  };

  return (
    <ScrollTop>
      <Formik
        initialValues={{ dealData: dealQuickAddData }}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          setSubmitting(true);
          setDealQuickAddData(values.dealData);
          setSubmitting(false);
          handleNext();
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleBlur,
          handleChange,
          touched,
          errors,
          isValid,
          isSubmitting,
          handleSubmit,
        }) => (
          <Box
            sx={{
              minHeight: { xs: "calc(100vh - 32px)", sm: "unset" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <LogoIcon />
              <Typography variant="h2">
                Add a few of your{" "}
                <Typography variant="h2" component={"span"} color={"green"}>
                  open deals
                </Typography>
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  pt: 1,
                  display: "block",
                  opacity: 0.5,
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                OneSource is exponentially more valuable with more data. Get
                started by entering a few of your open deals.
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Form>
                  <FieldArray name="dealData">
                    {({ push, remove }) => (
                      <Grid container spacing={4} sx={{ mt: 0 }}>
                        {values.dealData.length > 0 &&
                          values.dealData.map((dealQuickAddRecord, index) => {
                            return (
                              <>
                                <Grid
                                  container
                                  item
                                  alignItems="flex-start"
                                  spacing={1}
                                  key={index}
                                  sx={{ mb: 4, ml: { xs: 0, lg: 4 } }}
                                >
                                  <Grid item xs={12} lg={11}>
                                    <MainCard
                                      sx={{
                                        borderColor: "transparent",
                                        overflow: "visible",
                                      }}
                                      contentSX={{ p: { xs: 0, lg: 1 } }}
                                    >
                                      <Box
                                        sx={{
                                          position: {
                                            xs: "unset",
                                            lg: "absolute",
                                          },
                                          mb: 3,
                                          left: "-40px",
                                          height: 32,
                                          width: 32,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "50%",
                                          border:
                                            "2px solid rgba(36, 149, 243, 0.20)",
                                          color: "#2495F3",
                                          background: "#DAE7F2",
                                          fontWeight: 700,
                                        }}
                                      >
                                        {index + 1}
                                      </Box>
                                      <Grid
                                        container
                                        columnSpacing={{ xs: 0, sm: 5 }}
                                        rowSpacing={4}
                                        sx={{ pl: 0 }}
                                      >
                                        <Grid item xs={12} sm={6}>
                                          <SelectOption
                                            options={DEAL_TYPE_OPTIONS}
                                            fieldName={`dealData.${index}.deal_type`}
                                            displayName={
                                              DealMetadata.deal_type.displayName
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <TextFieldString
                                            fieldName={`dealData.${index}.name`}
                                            displayName={
                                              DealMetadata.name.displayName
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <DealOptionAutocompleteNew
                                            fieldName={`dealData.${index}.asset_type`}
                                            displayName={
                                              DealMetadata.asset_type
                                                .displayName
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <DatePicker
                                            fieldName={`dealData.${index}.close_date_est`}
                                            displayName={
                                              DealMetadata.close_date_est
                                                .displayName
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <TextFieldCurrency
                                            fieldName={`dealData.${index}.transaction_value_est`}
                                            displayName={
                                              DealMetadata.transaction_value_est
                                                .displayName
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <TextFieldCurrency
                                            fieldName={`dealData.${index}.commission_est`}
                                            displayName={
                                              DealMetadata.commission_est
                                                .displayName
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </MainCard>
                                  </Grid>
                                  <Grid item xs={12} sm={1}>
                                    <Stack
                                      direction={"row"}
                                      sx={{ mt: { xs: 0, lg: 17 } }}
                                    >
                                      {values.dealData.length > 1 && (
                                        <IconButton
                                          onClick={() => remove(index)}
                                        >
                                          <RemoveCircleIcon color="primary" />
                                        </IconButton>
                                      )}
                                      {index + 1 === values.dealData.length && (
                                        <IconButton
                                          onClick={() => {
                                            push(emptyDealQuickAddRow);
                                          }}
                                        >
                                          <AddCircleIcon color="primary" />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  </Grid>
                                </Grid>
                                {index < values.dealData.length - 1 && (
                                  <Divider sx={{ width: "100%" }} />
                                )}
                              </>
                            );
                          })}
                      </Grid>
                    )}
                  </FieldArray>
                </Form>
              </LocalizationProvider>
            </Box>
            <div>
              <StepActions
                handleBack={() => saveProgressAndHandleBack(values, isValid)}
                handleNext={handleSubmit}
              />
            </div>
          </Box>
        )}
      </Formik>
    </ScrollTop>
  );
};
