import {
  Apartment,
  Description,
  EmailOutlined,
  HandshakeOutlined,
  Person,
  Place,
  Settings,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import _ from "lodash";
import React, { useMemo } from "react";

import { CreoneField } from "components/form/basic/creone_field";
import config from "config";
import { PERMISSIONS_DESCRIPTION_MAP } from "constants/permission";
import { useCheckLicense } from "hooks/useCheckLicense";
import { Product } from "types/license";
import { FieldComponentProps } from "types/standardForm";

function RolePermissions(props: FieldComponentProps) {
  const theme = useTheme();
  const hasDealViewLicense = useCheckLicense([
    Product.deal_view,
    Product.deal_view_pro,
  ]);
  const hasProspectsLicense = useCheckLicense([Product.prospects]);
  const hasBackOfficeLicense = useCheckLicense([Product.back_office]);

  const { fieldName } = props;

  const { getFieldProps, setFieldValue } = useFormikContext();

  const { value: permissions } = getFieldProps(props.fieldName);

  const RecordActionsPermissions = useMemo(
    () =>
      ({
        title,
        property,
        recordName,
        showNone = false,
      }: {
        title: string;
        property: "viewer" | "editor" | "deleter";
        recordName: "deal" | "contact" | "company" | "voucher" | "property";
        showNone?: boolean;
      }) => {
        return (
          <Stack direction={"row"} alignItems="baseline" spacing={1}>
            <Typography fontWeight={600} width={250}>
              {title}
            </Typography>
            <FormControl variant="standard">
              <Select
                id={`select-${property}-permissions`}
                value={_.get(permissions, [recordName, property])}
                onChange={(event) =>
                  setFieldValue(fieldName, {
                    ...permissions,
                    [recordName]: {
                      ...permissions[recordName],
                      [property]: event.target.value,
                    },
                  })
                }
                disableUnderline
                sx={{ minWidth: 120 }}
              >
                <MenuItem value={"all"}>
                  {PERMISSIONS_DESCRIPTION_MAP[recordName]["all"]}
                </MenuItem>
                <MenuItem value={"team"}>
                  {PERMISSIONS_DESCRIPTION_MAP[recordName]["team"]}
                </MenuItem>
                <MenuItem value={"individual"}>
                  {PERMISSIONS_DESCRIPTION_MAP[recordName]["individual"]}
                </MenuItem>
                {showNone && (
                  <MenuItem value={"none"}>
                    {PERMISSIONS_DESCRIPTION_MAP[recordName]["none"]}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Stack>
        );
      },
    [fieldName, permissions, setFieldValue]
  );

  const RecordPermissionsList = useMemo(
    () =>
      ({
        recordName,
      }: {
        recordName: "deal" | "contact" | "company" | "voucher" | "property";
      }) => {
        return (
          <>
            <Grid item xs={4}>
              <RecordActionsPermissions
                title={"View"}
                property={"viewer"}
                recordName={recordName}
              />
            </Grid>
            <Grid item xs={4}>
              <RecordActionsPermissions
                title={"Edit"}
                property={"editor"}
                recordName={recordName}
                showNone
              />
            </Grid>
            <Grid item xs={4}>
              <RecordActionsPermissions
                title={"Delete"}
                property={"deleter"}
                recordName={recordName}
                showNone
              />
            </Grid>
          </>
        );
      },
    [RecordActionsPermissions]
  );

  return (
    <CreoneField {...props}>
      <Box
        sx={{
          "& .MuiAccordion-root": {
            borderColor: theme.palette.divider,
            "& .MuiAccordionSummary-root": {
              bgcolor: "transparent",
              flexDirection: "row",
              "&:focus-visible": {
                bgcolor: "primary.lighter",
              },
            },
            "& .MuiAccordionDetails-root": {
              borderColor: theme.palette.divider,
            },
            "& .Mui-expanded": {
              color: theme.palette.primary.main,
            },
          },
        }}
      >
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Settings />
              <Typography variant="h6">Organization</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={4}>
              <Grid item xs={4}>
                <Stack direction={"row"} alignItems="center" spacing={1}>
                  <Typography fontWeight={600} width={250}>
                    Modify Organization Settings
                  </Typography>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Switch
                      checked={_.get(permissions, "organization.settings")}
                      onChange={(event) =>
                        setFieldValue(fieldName, {
                          ...permissions,
                          organization: {
                            ...permissions["organization"],
                            settings: event.target.checked,
                          },
                        })
                      }
                    ></Switch>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={"row"} alignItems="center" spacing={1}>
                  <Typography fontWeight={600} width={250}>
                    Export Data
                  </Typography>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Switch
                      checked={_.get(permissions, "organization.export")}
                      onChange={(event) =>
                        setFieldValue(fieldName, {
                          ...permissions,
                          organization: {
                            ...permissions["organization"],
                            export: event.target.checked,
                          },
                        })
                      }
                    ></Switch>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {hasProspectsLicense && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Apartment />
                <Typography variant="h6">Companies</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} columns={4}>
                <RecordPermissionsList recordName={"company"} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Person />
              <Typography variant="h6">Contacts</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={4}>
              <RecordPermissionsList recordName={"contact"} />
            </Grid>
          </AccordionDetails>
        </Accordion>
        {hasDealViewLicense && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Stack direction="row" spacing={1.5} alignItems="center">
                <HandshakeOutlined />
                <Typography variant="h6">Deals</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} columns={4}>
                <RecordPermissionsList recordName={"deal"} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {hasProspectsLicense && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Stack direction="row" spacing={1.5} alignItems="center">
                <EmailOutlined />
                <Typography variant="h6">Emails</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} columns={4}>
                <Grid item xs={4}>
                  <Stack direction={"row"} alignItems="baseline" spacing={1}>
                    <Typography fontWeight={600} width={250}>
                      View
                    </Typography>
                    <FormControl variant="standard">
                      <Select
                        id={`select-email-viewer-permissions`}
                        value={_.get(permissions, ["email", "viewer"])}
                        onChange={(event) =>
                          setFieldValue(fieldName, {
                            ...permissions,
                            email: {
                              ...permissions["email"],
                              ["viewer"]: event.target.value,
                            },
                          })
                        }
                        disableUnderline
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value={"all"}>All emails</MenuItem>
                        <MenuItem value={"none"}>No emails</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction={"row"} alignItems="baseline" spacing={1}>
                    <Typography fontWeight={600} width={250}>
                      Delete
                    </Typography>
                    <FormControl variant="standard">
                      <Select
                        id={`select-email-deleter-permissions`}
                        value={_.get(permissions, ["email", "deleter"])}
                        onChange={(event) =>
                          setFieldValue(fieldName, {
                            ...permissions,
                            email: {
                              ...permissions["email"],
                              ["deleter"]: event.target.value,
                            },
                          })
                        }
                        disableUnderline
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value={"all"}>All emails</MenuItem>
                        <MenuItem value={"individual"}>
                          Emails they logged
                        </MenuItem>
                        <MenuItem value={"none"}>No emails</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {hasBackOfficeLicense && config.features.back_office && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Description />
                <Typography variant="h6">Invoices</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} columns={4}>
                <Grid item xs={4}>
                  <Stack direction={"row"} alignItems="baseline" spacing={1}>
                    <Typography fontWeight={600} width={250}>
                      View
                    </Typography>
                    <FormControl variant="standard">
                      <Select
                        id={`select-invoice-viewer-permissions`}
                        value={_.get(permissions, ["invoice", "viewer"])}
                        onChange={(event) =>
                          setFieldValue(fieldName, {
                            ...permissions,
                            invoice: {
                              ...permissions["invoice"],
                              ["viewer"]: event.target.value,
                            },
                          })
                        }
                        disableUnderline
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value={"all"}>All invoices</MenuItem>
                        <MenuItem value={"none"}>No invoices</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction={"row"} alignItems="baseline" spacing={1}>
                    <Typography fontWeight={600} width={250}>
                      Edit
                    </Typography>
                    <FormControl variant="standard">
                      <Select
                        id={`select-invoice-editor-permissions`}
                        value={_.get(permissions, ["invoice", "editor"])}
                        onChange={(event) =>
                          setFieldValue(fieldName, {
                            ...permissions,
                            invoice: {
                              ...permissions["invoice"],
                              ["editor"]: event.target.value,
                            },
                          })
                        }
                        disableUnderline
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value={"all"}>All invoices</MenuItem>
                        <MenuItem value={"none"}>No invoices</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack direction={"row"} alignItems="baseline" spacing={1}>
                    <Typography fontWeight={600} width={250}>
                      Delete
                    </Typography>
                    <FormControl variant="standard">
                      <Select
                        id={`select-invoice-deleter-permissions`}
                        value={_.get(permissions, ["invoice", "deleter"])}
                        onChange={(event) =>
                          setFieldValue(fieldName, {
                            ...permissions,
                            invoice: {
                              ...permissions["invoice"],
                              ["deleter"]: event.target.value,
                            },
                          })
                        }
                        disableUnderline
                        sx={{ minWidth: 120 }}
                      >
                        <MenuItem value={"all"}>All invoices</MenuItem>
                        <MenuItem value={"none"}>No invoices</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Place />
              <Typography variant="h6">Properties</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1} columns={4}>
              <RecordPermissionsList recordName={"property"} />
            </Grid>
          </AccordionDetails>
        </Accordion>
        {hasBackOfficeLicense && config.features.back_office && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Description />
                <Typography variant="h6">Vouchers</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} columns={4}>
                <RecordPermissionsList recordName={"voucher"} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </CreoneField>
  );
}

export default RolePermissions;
