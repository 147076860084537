import { useFormikContext } from "formik";
import { get } from "lodash";

import { PropertyLookupField } from "pages/deal/components/LookupField";
import { parseDealOptionAsString } from "pages/deal/utils/deal_form";
import { LookupItem } from "types/api/deal/form";
import { PropertyExtended } from "types/api/deal/property";
import { FieldComponentProps } from "types/standardForm";
import { dealService } from "utils/axios";

export const DealPropertyLookupField = (props: FieldComponentProps) => {
  const { setFieldValue, values } = useFormikContext();

  const additionalChangeHandler = async (value: LookupItem | null) => {
    // Drop out early if no value
    if (!value || !value.key) return;

    try {
      const { data: property } = await dealService.get<PropertyExtended>(
        `/property/${value.key}`
      );

      if (!property) return;

      // Define fields to check and set dynamically
      const propertyFieldValues: Record<string, any> = {
        submarket: parseDealOptionAsString(property.submarket),
        asset_type: parseDealOptionAsString(property.asset_type),
        address_line_1: property.address_line_1,
        address_line_2: property.address_line_2,
        address_city: property.address_city,
        address_state: property.address_state,
        address_postal_code: property.address_postal_code,
        address_country: property.address_country,
      };

      // Set only if values[field] is empty
      Object.entries(propertyFieldValues).forEach(([formField, fieldValue]) => {
        if (!get(values, formField)) {
          setFieldValue(formField, fieldValue);
        }
      });
    } catch (error) {
      console.error("Error fetching property details:", error);
    }
  };
  return (
    <PropertyLookupField
      {...props}
      additionalChangeHandler={additionalChangeHandler}
    />
  );
};
