import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { baseDealFilters } from "pages/deal/constants/dealFilters";
import { parseParamsAsFilters } from "pages/deal/utils/deal";
import { dispatch } from "store";
import { DealStatusEnum } from "types/api/deal/enum";
import { SortOrderEnum } from "types/navigation/common";
import { DealFilter, DealNavigation } from "types/navigation/dealNavigation";
import { RecordIdentifier } from "types/record";
import { captureUIEvent } from "utils/eventCapture";

const initialState: {
  initial: DealFilter;
  published: DealFilter;
  pending: Partial<DealFilter>;
  navigation: DealNavigation;
} = {
  initial: {
    ...baseDealFilters,
    status: [DealStatusEnum.open],
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  },
  published: {
    ...baseDealFilters,
    status: [DealStatusEnum.open],
    sort: ["name"],
    sort_order: [SortOrderEnum.asc],
  },
  pending: {},
  navigation: {
    hiddenFilters: [],
    disabledFilters: [],
    year: null,
  },
};

const dealNavigation = createSlice({
  name: "dealNavigation",
  initialState,
  reducers: {
    setFilters(state, action) {
      state.pending = action.payload;
    },
    setInitialFilters(state, action) {
      state.initial = action.payload;
      state.pending = {};
    },
    setPublishedFilters(state, action) {
      state.published = action.payload;
      state.pending = {};
    },
    setInitialAndPublishedFilters(state, action) {
      state.initial = action.payload;
      state.published = action.payload;
      state.pending = {};
    },
    applyFilters(state) {
      captureUIEvent("filters_applied", {
        recordType: RecordIdentifier.Deal,
        filters: { ...state.published, ...state.pending },
      });

      state.published = { ...state.published, ...state.pending };
      state.pending = {};
    },
    resetFilters(state) {
      captureUIEvent("filters_reset", {
        recordType: RecordIdentifier.Deal,
      });

      state.published = state.initial;
      state.pending = {};
    },
    setNavigation(state, action) {
      state.navigation = action.payload;
    },
    setSort(state, action) {
      const { sort, sort_order } = action.payload;

      if (
        !_.isEqual(state.published.sort, sort) ||
        !_.isEqual(state.published.sort_order, sort_order)
      ) {
        state.published = { ...state.published, ...action.payload };
      }
    },
  },
});

export default dealNavigation.reducer;

export const setInitialDealFilters = (filters: DealFilter) =>
  dispatch(dealNavigation.actions.setInitialFilters(filters));
export const setPublishedDealFilters = (filters: DealFilter) =>
  dispatch(dealNavigation.actions.setPublishedFilters(filters));
export const setInitialAndPublishedDealFilters = (filters: DealFilter) =>
  dispatch(dealNavigation.actions.setInitialAndPublishedFilters(filters));
export const setDealFilters = (filters: Partial<DealFilter>) =>
  dispatch(dealNavigation.actions.setFilters(filters));
export const applyDealFilters = () =>
  dispatch(dealNavigation.actions.applyFilters());
export const resetDealFilters = () =>
  dispatch(dealNavigation.actions.resetFilters());

export const setDealNavigation = (navigation: DealNavigation) =>
  dispatch(dealNavigation.actions.setNavigation(navigation));
export const setDealSort = (sort: string[], sort_order: string[]) =>
  dispatch(dealNavigation.actions.setSort({ sort, sort_order }));

export function setInitialDealFilterState(
  initialFilters: DealFilter,
  searchParams: URLSearchParams
) {
  const { queryFilters, queryFiltersExist } = parseParamsAsFilters(
    baseDealFilters,
    searchParams
  );
  if (queryFiltersExist) {
    setInitialDealFilters(initialFilters);
    setPublishedDealFilters({ ...initialFilters, ...queryFilters });
  } else {
    setInitialAndPublishedDealFilters(initialFilters);
  }
}
